<script>
import {freezeScroll, isMobileResolution, unfreezeScroll} from "@/helpers/helpers";

export default {
  name: "drop-down-maker",
  props: {
    options: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      open: false,
      isMobile: isMobileResolution(),
      makerLogos: [
        {
          make: 'audi',
          img: "maker-logos/audi.png"
        },
        {
          make: 'bmw',
          img: "maker-logos/bmw.png"
        },
        {
          make: 'chevrolet',
          img: "maker-logos/chevrolet.png"
        },
        {
          make: 'ford',
          img: "maker-logos/ford.png"
        },
        {
          make: 'honda',
          img: "maker-logos/honda.png"
        },
        {
          make: 'kia',
          img: "maker-logos/kia.png"
        },
        {
          make: 'lexus',
          img: "maker-logos/lexus.png"
        },
        {
          make: 'mercedes-benz',
          img: "maker-logos/mercedes.png"
        },
        {
          make: 'mitsubishi',
          img: "maker-logos/mitsubishi.png"
        },
        {
          make: 'nissan',
          img: "maker-logos/nissan.png"
        },
        {
          make: 'toyota',
          img: "maker-logos/toyota.png"
        },
        {
          make: 'volkswagen',
          img: "maker-logos/vag.png"
        },
      ],
      makers: [],
      selected: [],
      search: "",
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
    }
  },
  async mounted() {
    this.makers = this.options;
  },
  watch:{
    selected(){
      this.$emit('update',this.selected);
    },
    search() {
      this.makers = this.options.filter((value)=>{
        return value.toLowerCase().includes(this.search.toLowerCase());
      })
    }
  },
  methods: {
    startSwipe(event) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    trackSwipe(event) {
      this.endX = event.touches[0].clientX;
      this.endY = event.touches[0].clientY;
    },
    endSwipe() {
      const deltaX = this.endX - this.startX;
      const deltaY = this.endY - this.startY;

      if (Math.abs(deltaY) > Math.abs(deltaX)) {
        if (deltaY > 0) {
          this.open = false;
          unfreezeScroll()
        } else {
          this.open = false;
          unfreezeScroll()
        }
      }

      // Сбрасываем координаты
      this.startX = 0;
      this.startY = 0;
      this.endX = 0;
      this.endY = 0;
    },
    selectByImage(key) {
      const index = this.selected.indexOf(key);
      if (index !== -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(key);
      }
      this.$emit('update',this.selected);
    },
    clearMaker() {
      this.selected = [];
      this.$emit('update',this.selected);
    },
    capitalCase(inputString) {
      let words = inputString.split(' ');
      let outputWords = words.map(word => word.charAt(0) + word.slice(1).toLowerCase());
      return outputWords.join(' ');
    },
    openModal() {
      this.open = true;
      if (this.isMobile) {
        freezeScroll();
      }
    },
    closeModal() {
      if (this.isMobile) {
        unfreezeScroll();
      } else {
        this.open = false
      }
    }
  }
}
</script>

<template>
  <div class="maker-dropdown lg:relative flex items-center xlg:w-3/12 xs:w-full" id="maker-dropdown"
       v-click-outside="closeModal">
    <div @click="openModal" class="maker-dropdown-header flex items-center cursor-pointer">
      <div class="flex mr-2 items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_781_21129)">
            <path
                d="M19 20H5V21C5 21.2652 4.89464 21.5196 4.70711 21.7071C4.51957 21.8946 4.26522 22 4 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V11L4.48 5.212C4.63432 4.852 4.89096 4.54524 5.21805 4.32978C5.54515 4.11432 5.92832 3.99965 6.32 4H17.68C18.0713 4.00004 18.4541 4.1149 18.7808 4.33033C19.1075 4.54577 19.3638 4.85231 19.518 5.212L22 11V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H20C19.7348 22 19.4804 21.8946 19.2929 21.7071C19.1054 21.5196 19 21.2652 19 21V20ZM20 13H4V18H20V13ZM4.176 11H19.824L17.681 6H6.32L4.177 11H4.176ZM6.5 17C6.10218 17 5.72064 16.842 5.43934 16.5607C5.15804 16.2794 5 15.8978 5 15.5C5 15.1022 5.15804 14.7206 5.43934 14.4393C5.72064 14.158 6.10218 14 6.5 14C6.89782 14 7.27936 14.158 7.56066 14.4393C7.84196 14.7206 8 15.1022 8 15.5C8 15.8978 7.84196 16.2794 7.56066 16.5607C7.27936 16.842 6.89782 17 6.5 17ZM17.5 17C17.1022 17 16.7206 16.842 16.4393 16.5607C16.158 16.2794 16 15.8978 16 15.5C16 15.1022 16.158 14.7206 16.4393 14.4393C16.7206 14.158 17.1022 14 17.5 14C17.8978 14 18.2794 14.158 18.5607 14.4393C18.842 14.7206 19 15.1022 19 15.5C19 15.8978 18.842 16.2794 18.5607 16.5607C18.2794 16.842 17.8978 17 17.5 17Z"
                fill="#383838"></path>
          </g>
          <defs>
            <clipPath id="clip0_781_21129">
              <rect width="24" height="24" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="flex items-center selectedOptions">
        <div v-if="selected.length>0" class="flex relative">
          <div class="w-11/12 overflow-hidden">
            {{ capitalCase(selected.join(',')) }}
          </div>
          <div class="absolute right-0 top-[6px]" @click="clearMaker">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.43236 5.08623L11.3824 0.13623L12.7964 1.55023L7.84636 6.50023L12.7964 11.4502L11.3824 12.8642L6.43236 7.91423L1.48236 12.8642L0.0683594 11.4502L5.01836 6.50023L0.0683594 1.55023L1.48236 0.13623L6.43236 5.08623Z" fill="black"/>
            </svg>
          </div>
        </div>
        <p v-else>{{ $t('mainFilter.maker') }}</p>
      </div>
    </div>
    <teleport to="#modal" :disabled="!isMobile" v-if="open">
      <div class="maker-list-wrapper">
        <div @touchstart="startSwipe"
             @touchmove="trackSwipe"
             @touchend="endSwipe" id="swiper" v-if="isMobile" class="mobile flex justify-center items-center h-[40px]">
          <svg width="107" height="3" viewBox="0 0 107 3" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <rect width="107" height="3" rx="1.5" fill="#B6B6B6"></rect>
          </svg>
        </div>
        <div class="maker-list pl-8 pr-8 pt-2">
          <div class="mb-3">
            <input type="text" id="first_name"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                   placeholder="Search" v-model="search">
          </div>
          <div class="maker-list">
            <div class="flex items-center" v-for="(maker, key) in makers" :key="key">
              <div class="form-checkbox mb-2 option" :class="{
            selected : selected.includes(maker)
          }">
                <input type="checkbox" class="mr-5" :id="maker" :value="maker" v-model="selected">
                <label class="maker-option capitalize" :for="maker">{{ capitalCase(maker.replaceAll('-', ' ')) }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

    </teleport>
  </div>
</template>

<style scoped>
.images-pre-selection > div.selected, .images-pre-selection > div:hover {
  border: 1px solid #256dff
}

.images-pre-selection > div {
  cursor: pointer;
  transition: .5s;
  width: calc(25% - 10px);
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 10px hsla(0, 0%, 40%, .15)
}

.form-checkbox {
  display: flex;
  justify-content: left;
  align-items: center
}

.option:hover .maker-option {
  transition: .5s;
  color: var(--Blue, #256dff)
}

.option.selected .maker-option {
  color: var(--Blue, #256dff)
}

.apply-btn {
  border-radius: 0 0 4px 4px;
  background: #fff
}

.maker-list-wrapper {
  border-radius: 4px;
  background: #fafafa;
  width: 100%;
  z-index: 9999
}

.maker-option {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
}

.maker-list {
  overflow-y: scroll;
  height: 100vh
}

@media screen and (min-width: 320px) {
  .maker-list-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    transform: matrix()
  }
}

@media screen and (min-width: 1000px) {
  .maker-dropdown {
    border-top: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9
  }

  .maker-dropdown-header {
    border-right: 1px solid #c1c1c1
  }

  .maker-list-wrapper {
    position: absolute;
    top: 70px
  }

  .maker-list {
    height: 300px
  }
}

.maker-dropdown {
  height: 61px;
  border-radius: 4px 0 0 4px
}

.maker-dropdown-header {
  color: #383838;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  width: -webkit-fill-available;
  height: 50px;
  padding: 0 16px
}
</style>
