export function isMobileResolution() {
    const mobileWidthThreshold = 768; // Пример порогового значения, можно настроить

    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    return screenWidth < mobileWidthThreshold;
}

export function freezeScroll() {
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    window.onscroll = function () {
        window.scrollTo(scrollX, scrollY);
    };
}

export function unfreezeScroll() {
    window.onscroll = null;
}
