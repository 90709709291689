<script>

import Paginate from "vuejs-paginate/src/components/Paginate.vue";
import CarBlock from "@/components/car-block.vue";

export default {
  components: {CarBlock, Paginate},
  props: {
    cars: {
      required: true,
      type: Object
    }
  },
  mounted() {
    this.currentPage = this.cars.current_page
  },
  data() {
    return {
      currentPage: 1,
      prevIcon: '<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<path d="M11.3273 12.5007L16.2773 7.55072L14.8633 6.13672L8.49934 12.5007L14.8633 18.8647L16.2773 17.4507L11.3273 12.5007Z" fill="black"/>\n' +
          '</svg>',
      nextIcon: '<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<g clip-path="url(#clip0_1548_17252)">\n' +
          '<path d="M13.6727 12.5007L8.72266 7.55072L10.1367 6.13672L16.5007 12.5007L10.1367 18.8647L8.72266 17.4507L13.6727 12.5007Z" fill="black"/>\n' +
          '</g>\n' +
          '<defs>\n' +
          '<clipPath id="clip0_1548_17252">\n' +
          '<rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>\n' +
          '</clipPath>\n' +
          '</defs>\n' +
          '</svg>\n',
      separateIcon: '<svg width="2" height="21" viewBox="0 0 2 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '<rect x="0.5" width="1" height="21" fill="#797979"/>\n' +
          '</svg>'
    }
  },
  methods: {
    getResults(page) {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
      this.$emit('pagination', {page: page})
    }
  }
}
</script>

<template>
  <div class="result max-w-[1600px] mx-auto xs:px-2 2xlg:px-0">
    <div class="py-5 grid xs:grid-cols-1 mb:grid-cols-2 lg:grid-cols-3 xlg:grid-cols-4 gap-4" >
      <car-block :car="car" v-for="(car,index) in cars.data" :key="index"></car-block>
    </div>
    <div class="navigation flex justify-center py-5">
      <paginate
          v-model="currentPage"
          :clickHandler="getResults"
          :prevText="prevIcon"
          :margin-pages="2"
          :breakViewContent="separateIcon"
          :container-class="'inline-flex -space-x-px text-sm'"
          :page-class="'pagination-link px-2'"
          :nextText="nextIcon"
          :next-class="'flex items-center'"
          :prev-class="'flex items-center'"
          :page-count="cars.last_page"
      >
        <template v-slot:breakViewContent>
          <svg width="2" height="21" viewBox="0 0 2 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="1" height="21" fill="#797979"/>
          </svg>
        </template>
      </paginate>
    </div>
  </div>
</template>

<style scoped>

</style>