<script>
import {client} from "@/axios/client";
import CatalogueFilters from "@/components/catalogue-filters.vue";
import CatalogueResult from "@/components/catalogue-result.vue";
import CatalogueResultSkeleton from "@/components/skeleton/catalogue-result-skeleton.vue";
import {useMeta} from "vue-meta";

export default {
  name: "catalogue-page",
  components: {CatalogueResultSkeleton, CatalogueResult, CatalogueFilters},
  setup() {
    useMeta({
      title: 'Catalogue Page',
      description: 'კატალოგის ნახვა',
      SITE_NAME: '4dealer',
    })
  },
  data() {
    return {
      makers: [],
      models: [],
      years: [],
      fuelType: [],
      price: [],
      transmission: [],
      typeBody: [],
      locations: [],
      engCapacity: [],
      damage: [],
      filters: [],
      odometr: [],
      lotId: "",
      filterKey: 0,
      data: {
        data : []
      },
      localeCatalogue: window.localStorage.getItem('catalogue-type') ?? false,
      loader: false,
      special: "",
      page: 1,
    }
  },
  watch: {
    '$route.query': function () {
      this.makers = this.$route?.query?.makers ?? [];
      this.models = this.$route?.query?.models ?? [];
      this.years = this.$route?.query?.years ?? [];

      this.price = this.$route?.query?.price ?? [];
      this.fuelType = this.$route?.query?.fuelType ?? [];
      this.transmission = this.$route?.query?.transmission ?? [];
      this.typeBody = this.$route?.query?.categories ?? [];
      this.locations = this.$route?.query?.locations ?? [];
      this.engCapacity = this.$route?.query?.engCapacity ?? [];

      this.damage = this.$route?.query?.damage ?? [];
      this.odometr = this.$route?.query?.odometr ?? [];
      this.lotId = this.$route?.query?.lotId ?? "";

      this.special = this.$route?.query?.special ?? "";
      this.filterKey++;
      this.getCars({page: 0});
    }
  },
  mounted() {
    this.getFilters();
    this.makers = this.$route?.query?.makers ?? [];
    this.models = this.$route?.query?.models ?? [];
    this.years = this.$route?.query?.years ?? [];

    this.typeBody = this.$route?.query?.categories ?? [];
    this.price = this.$route?.query?.price ?? [];
    this.fuelType = this.$route?.query?.fuelType ?? [];

    this.transmission = this.$route?.query?.transmission ?? [];
    this.locations = this.$route?.query?.locations ?? [];
    this.engCapacity = this.$route?.query?.engCapacity ?? [];
    this.damage = this.$route?.query?.damage ?? [];
    this.odometr = this.$route?.query?.odometr ?? [];
    this.lotId = this.$route?.query?.lotId ?? "";

    this.special = this.$route?.query?.special ?? "";

    this.getCars({page: this.page});
  },
  methods: {
    async getFilters(){
      this.filters = [];
      const {data} = await client.get('/api/search-settings');
      this.filters = data
      this.filterKey++;

    },
    async getCars({page}) {

      this.data = {
        data : []
      }
      const params = new URLSearchParams();

      if (typeof this.makers !== "string") {
        this.makers.map((value, key) => key === 0 ? params.set('makers[]', value) : params.append('makers[]', value))
      } else {
        params.set('makers[]', this.makers)
      }

      if (this.special !== "") {
        params.set('special', this.special)
      }

      if (typeof this.models !== "string") {
        this.models.map((value, key) => key === 0 ? params.set('models[]', value) : params.append('models[]', value))
      } else {
        params.set('models[]', this.models)
      }

      if (typeof this.typeBody !== "string") {
        this.typeBody.map((value, key) => key === 0 ? params.set('typeBody[]', value) : params.append('typeBody[]', value))
      } else {
        params.set('typeBody[]', this.typeBody)
      }

      if (typeof this.fuelType !== "string") {
        this.fuelType.map((value, key) => key === 0 ? params.set('fuelType[]', value) : params.append('fuelType[]', value))
      } else {
        params.set('fuelType[]', this.fuelType)
      }

      if (typeof this.transmission !== "string") {
        this.transmission.map((value, key) => key === 0 ? params.set('transmission[]', value) : params.append('transmission[]', value))
      } else {
        params.set('transmission[]', this.transmission)
      }

      if (typeof this.locations !== "string") {
        this.locations.map((value, key) => key === 0 ? params.set('locations[]', value) : params.append('locations[]', value))
      } else {
        params.set('locations[]', this.locations)
      }

      if (typeof this.damage !== "string") {
        this.damage.map((value, key) => key === 0 ? params.set('damage[]', value) : params.append('damage[]', value))
      } else {
        params.set('damage[]', this.damage)
      }

      if (this.lotId !== "") {
        params.set('lotId', this.lotId)
      }

      if (typeof this.engCapacity !== "string") {
        this.engCapacity.map((value, key) => key === 0 ? params.set('engCapacity[]', value) : params.append('engCapacity[]', value))
      } else {
        params.set('engCapacity[]', this.engCapacity)
      }

      if (typeof this.years !== "string") {
        this.years.map((value, key) => key === 0 ? params.set('year[]', value) : params.append('year[]', value))
      } else {
        params.set('year[]', this.years)
      }

      if (typeof this.odometr !== "string") {
        this.odometr.map((value, key) => key === 0 ? params.set('odometr[]', value) : params.append('odometr[]', value))
      } else {
        params.set('odometr[]', this.odometr)
      }

      if (typeof this.price !== "string") {
        this.price.map((value, key) => key === 0 ? params.set('price[]', value) : params.append('price[]', value))
      } else {
        params.set('price[]', this.price)
      }
      this.localeCatalogue = window.localStorage.getItem('catalogue-type') === 'true';

      params.set('localeCatalogue', this.localeCatalogue ? 'yes' : 'no')

      params.set('page', page)

      const {data} = await client.get('/api/catalogue?' + params)
      this.data = data.cars;
    }
  }
}
</script>

<template>
  <div class="page">
    <catalogue-filters @catalogueType="getCars({page: this.page})" :Prefilters="filters" :lotIdSelected="lotId" :key="filterKey" :selectedOvj="{
      makers : this.makers,
      models : this.models,
      years : this.years,

      typeBody : this.typeBody,
      price :  this.price,
      fuelType : this.fuelType,

      transmission : this.transmission,
      locations : this.locations,
      engCapacity : this.engCapacity,

      damage : this.damage,
      odometr : this.odometr
    }"></catalogue-filters>

    <catalogue-result @pagination="getCars" v-if="!loader && data?.data.length > 0" :cars="data"></catalogue-result>
    <div class="empty-catalogue h-screen w-full flex items-center justify-center text-2xl" v-else>
      Car not found! Try to change filters
    </div>
    <catalogue-result-skeleton v-if="loader"></catalogue-result-skeleton>

  </div>
</template>

<style scoped>


</style>