<script>
import {defineComponent} from 'vue'
import {VueTelInput} from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import emitter from "@/eventBus/mitt";
import {client} from "@/axios/client";

export default defineComponent({
  name: "ProfileComponent",
  components: {VueTelInput},
  data() {
    return {
      user: JSON.parse(window.sessionStorage?.user ?? '{}'),
      phone: null,
      fieldsPassword: {
        current: "password",
        new: "password",
        repeat: "password",
      },
      phone_number: "",
      country: {},
    }
  },
  methods: {
    countryChanged(country) {
      this.country = country;
    },
    changeNumber(number) {
      console.log(number);
      if (typeof number === 'string') {
        this.phone = number
      }
    },
    async updateProfile() {
      if(this.user.new_password === this.user.repeat_password){
        const {data} = await client.put('api/account/',{...this.user, phone_number : this.phone})
        this.user = data.user;
        window.sessionStorage.user = JSON.stringify(data.user);
        this.$swal({
          toast: true,
          icon: "success",
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          title : 'Profile was updated!'
        });
      }else{
        alert('password wrong')
      }
    },
    logout(){
      this.$router.push({ name : 'main'})
      emitter.emit('logout')
    }
  }
})
</script>

<template>
  <div id="profile" class="px-5">
    <div class="group-input max-w-[350px] mt-4">
      <div>
        <label for="name">{{ $t('profile.personalData.firstName') }}</label>
      </div>
      <div>
        <input class="w-full h-[50px]" type="text" name="name" :placeholder="$t('profile.personalData.firstName')" id="name"
               v-model="user.first_name">
      </div>
    </div>
    <div class="group-input max-w-[350px] mt-4">
      <div>
        <label for="sir-name">{{ $t('profile.personalData.lastName') }}</label>
      </div>
      <div>
        <input class="w-full h-[50px]" type="text" name="last_name" :placeholder="$t('profile.personalData.lastName')" id="sir-name"
               v-model="user.last_name">
      </div>
    </div>
    <div class="group-input max-w-[350px] mt-4">
      <div>
        <label for="location">{{ $t('profile.personalData.location') }}</label>
      </div>
      <div>
        <input class="w-full h-[50px]" type="text" name="location" :placeholder="$t('profile.personalData.locationPlaceholder')" id="location"
               v-model="user.location">
      </div>
    </div>
    <div class="group-input  mt-4 border-none mr-5">
      <div>
        <label for="email">Phone</label>
      </div>
      <div class="min-w-[450px] flex items-center w-full">
        <vue-tel-input
            class="w-full max-w-[350px] mr-5"
            autoformat
            mode="national"
            :inputOptions="{placeholder:$t('dreamCar.phoneNumber')}"
            :value="user.phone_number"
            :placeholder="'Enter a phone number'"
            @input="changeNumber"
            v-on:country-changed="countryChanged"
        >
        </vue-tel-input>
        <div class="blue-text cursor-pointer" v-if="!user.sms_verified">
          verify
        </div>
      </div>

    </div>
    <div class="group-input max-w-[350px] mt-4">
      <div>
        <label for="email">{{ $t('profile.personalData.email') }}</label>
      </div>
      <div>
        <input class="w-full h-[50px]" type="text" name="email" placeholder="adam@info.com" id="email"
               v-model="user.email">
      </div>
    </div>
    <div class="password-block my-5">
      <div class="header">
        Change password
      </div>
      <div class="group-input max-w-[350px] mt-4">
        <div>
          <label for="current-password">{{ $t('profile.personalData.changePassword') }}</label>
        </div>
        <div class="relative">
          <input class="w-full h-[50px]" :type="fieldsPassword.current" name="email" placeholder="Current password"
                 id="current-password"
                 v-model="user.password">
          <div class="eye-password" @click="fieldsPassword.current = fieldsPassword.current === 'password' ? 'text' : 'password'">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1413_5430)">
                <path
                    d="M8.79412 14.9995L7.252 14.5858L7.88051 12.2387C6.93972 11.8917 6.06536 11.3861 5.29541 10.7437L3.576 12.4639L2.44596 11.3339L4.16617 9.61448C3.19512 8.45151 2.54266 7.05634 2.27266 5.56552L3.84433 5.27882C4.45047 8.63458 7.38617 11.1806 10.9168 11.1806C14.4467 11.1806 17.3832 8.63458 17.9893 5.27882L19.561 5.56472C19.2914 7.05574 18.6392 8.4512 17.6683 9.61448L19.3877 11.3339L18.2577 12.4639L16.5383 10.7437C15.7683 11.3861 14.8939 11.8917 13.9531 12.2387L14.5817 14.5866L13.0395 14.9995L12.4102 12.6516C11.4218 12.821 10.4118 12.821 9.42343 12.6516L8.79412 14.9995Z"
                    fill="#6B7280"></path>
              </g>
              <defs>
                <clipPath id="clip0_1413_5430">
                  <rect width="20" height="20" fill="white" transform="translate(20.5) rotate(90)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <div class="group-input max-w-[350px] mt-4">
        <div>
          <label for="new-password">{{ $t('profile.personalData.new-password') }}</label>
        </div>
        <div class="relative">
          <input class="w-full h-[50px]" :type="fieldsPassword.new" name="email" :placeholder="$t('profile.personalData.new-password')"
                 id="new-password"
                 v-model="user.new_password">
          <div class="eye-password" @click="fieldsPassword.new = fieldsPassword.new === 'password' ? 'text' : 'password'">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1413_5430)">
                <path
                    d="M8.79412 14.9995L7.252 14.5858L7.88051 12.2387C6.93972 11.8917 6.06536 11.3861 5.29541 10.7437L3.576 12.4639L2.44596 11.3339L4.16617 9.61448C3.19512 8.45151 2.54266 7.05634 2.27266 5.56552L3.84433 5.27882C4.45047 8.63458 7.38617 11.1806 10.9168 11.1806C14.4467 11.1806 17.3832 8.63458 17.9893 5.27882L19.561 5.56472C19.2914 7.05574 18.6392 8.4512 17.6683 9.61448L19.3877 11.3339L18.2577 12.4639L16.5383 10.7437C15.7683 11.3861 14.8939 11.8917 13.9531 12.2387L14.5817 14.5866L13.0395 14.9995L12.4102 12.6516C11.4218 12.821 10.4118 12.821 9.42343 12.6516L8.79412 14.9995Z"
                    fill="#6B7280"></path>
              </g>
              <defs>
                <clipPath id="clip0_1413_5430">
                  <rect width="20" height="20" fill="white" transform="translate(20.5) rotate(90)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
      <div class="group-input max-w-[350px] mt-4">
        <div>
          <label for="repeat-password">{{ $t('profile.personalData.repeatPassword') }}</label>
        </div>
        <div class="relative">
          <input class="w-full h-[50px]" :type="fieldsPassword.repeat" name="email" :placeholder="$t('profile.personalData.repeatPassword')"
                 id="repeat-password"
                 v-model="user.repeat_password">
          <div class="eye-password" @click="fieldsPassword.repeat = fieldsPassword.repeat === 'password' ? 'text' : 'password'">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1413_5430)">
                <path
                    d="M8.79412 14.9995L7.252 14.5858L7.88051 12.2387C6.93972 11.8917 6.06536 11.3861 5.29541 10.7437L3.576 12.4639L2.44596 11.3339L4.16617 9.61448C3.19512 8.45151 2.54266 7.05634 2.27266 5.56552L3.84433 5.27882C4.45047 8.63458 7.38617 11.1806 10.9168 11.1806C14.4467 11.1806 17.3832 8.63458 17.9893 5.27882L19.561 5.56472C19.2914 7.05574 18.6392 8.4512 17.6683 9.61448L19.3877 11.3339L18.2577 12.4639L16.5383 10.7437C15.7683 11.3861 14.8939 11.8917 13.9531 12.2387L14.5817 14.5866L13.0395 14.9995L12.4102 12.6516C11.4218 12.821 10.4118 12.821 9.42343 12.6516L8.79412 14.9995Z"
                    fill="#6B7280"></path>
              </g>
              <defs>
                <clipPath id="clip0_1413_5430">
                  <rect width="20" height="20" fill="white" transform="translate(20.5) rotate(90)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="action-block">
      <div>
        <button class="btn btn-blue btn-font" @click="updateProfile">
          {{ $t('profile.personalData.btn') }}
        </button>
      </div>

      <div class="flex my-5 cursor-pointer" @click="logout">
        <div class="mr-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V6H18V4H6V20H18V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z" fill="#256DFF"/>
          </svg>
        </div>
        <div class="blue-text">
          {{ $t('profile.personalData.log-out') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.header {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
}
</style>