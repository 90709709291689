<script>
export default {

}
</script>

<template>
  <div  class="core-value" id="core-value">
    <div
         class="core-value-wrapper mb:flex justify-between items-center mr-auto ml-auto 2xlg:pl-0 xs:py-10 mb:py-0">
      <div  class="mb:w-8/12 xs:w-full xs:px-5">
        <div  class="core-value-header mb-5">{{ $t('coreValue.header') }}</div>
        <div  class="core-value-text mb-2">{{ $t('coreValue.mainText1') }}
        </div>
        <div  class="core-value-text mb-8">{{ $t('coreValue.mainText2') }}
        </div>
        <div  class="">
          <button  class="btn btn-blue btn-font xs:w-full">{{ $t('coreValue.btn') }}</button>
        </div>
      </div>
      <div  class="core-value-img w-4/12"></div>
    </div><!----></div>
</template>

<style scoped>
.core-value-img {
  height: 608px;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  background-image: url('../assets/core-value.png');
}
</style>
