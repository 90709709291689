<script>
import {client} from "@/axios/client";

export default {
  name: "latest-articles-component",
  data() {
    return {
      articles: []
    }
  },
  mounted() {
    this.getArticles()
  },
  methods: {
    async getArticles() {
      const {data} = await client.get('api/latest-articles');
      this.articles = data
    }
  }
}
</script>

<template>
  <div class="latest-articles ml-auto mr-auto mt-16 mb-16 xs:p-5 2xlg:p-0" id="latest-articles">
    <div class="latest-article-header">
      {{ $t('latestArticles.header') }}
    </div>
    <div class="mt-10 xs:grid xs:grid-cols-1 mb:grid-cols-3 lg:grid-cols-3 xs:gap-4 mb:items-center w-full">
      <router-link class="article-preview" v-for="(article, index) in articles" :key="index"
                   :to="{name : 'article',params : {id : article.id}}">
        <div class="w-full overflow-hidden">
          <img class="w-full" :src="article.img_store" alt="Article Img"></div>
        <div class="p-5">
          <div class="article-categories mb-5">{{ article.theme }}</div>
          <div class="article-header mb-5">
            {{ article.header }}
          </div>
          <div class="article-preview-text mb-5">
            {{ article.description }}
          </div>
          <div class="h-[20px]"><span class="reading-time"> Reading Time: </span> <span
              class="time">{{
              article.reading_time
            }} minutes</span></div>
        </div>
      </router-link>
    </div>
    <div>
      <router-link class="all-articles flex justify-start items-center mt-5" :to="{name:'blog'}">
        <div class="mr-2 font-caps">{{ $t('latestArticles.allArticles') }}</div>
        <div class="flex items-center">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1015_7940)">
              <path
                    d="M13.4763 9.66689L9.00634 5.19689L10.1847 4.01855L16.6663 10.5002L10.1847 16.9819L9.00634 15.8036L13.4763 11.3336H3.33301V9.66689H13.4763Z"
                    fill="#256DFF"></path>
            </g>
            <defs >
              <clipPath id="clip0_1015_7940">
                <rect width="20" height="20" fill="white" transform="translate(0 0.5)"></rect>
              </clipPath>
            </defs>
          </svg>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.article-preview {
  border-radius: 4px;
  border: 1px solid #ede2ff;
  background: #fff;
  overflow: hidden;
  cursor: pointer
}

.article-preview img {
  transition: .5s;
  overflow: hidden
}

.article-preview:hover img {
  transform: scale(1.2);
  transition: .5s
}

.latest-articles {
  max-width: 1600px
}

.article-categories {
  color: var(--text-gray,#616161);
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px
}

.article-preview-text {
  font-family: TBCContractica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px
}

.article-header,.article-preview-text {
  color: #000;
  font-style: normal;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.article-header {
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-family: TBCContracticaCaps;
  font-size: 28px;
  font-weight: 700;
  line-height: 40px
}

.time {
  color: #000;
  font-weight: 500;
  line-height: 26px
}

.reading-time,.time {
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal
}

.reading-time {
  color: var(--text-gray,#616161);
  font-weight: 400;
  line-height: 25px
}

@media screen and (min-width: 320px) {
  .latest-article-header {
    color:#000;
    font-family: TBCContracticaCaps;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px
  }
}

@media screen and (min-width: 1200px) {
  .article-preview img {
    height:276px
  }

  .latest-article-header {
    color: #000;
    font-family: TBCContracticaCaps;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 49px
  }

  .article-preview {
    min-height: 500px
  }
}

@media screen and (max-width: 1200px) {
  .article-preview {
    min-height:460px
  }

  .article-preview img {
    height: 160px;
    -o-object-fit: cover;
    object-fit: cover
  }

  .article-preview {
    margin-bottom: 15px
  }
}

.all-articles {
  color: var(--blue,#256dff);
  font-family: TBCContractica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px
}

</style>
