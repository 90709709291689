<script>
import ContactUsTitle from "@/components/contact-us-title.vue";
import DreamCarComponent from "@/components/dream-car.vue";
import {useMeta} from "vue-meta";

export default {
  name : "contact-us-page",
  setup() {
    useMeta({
      title: 'Contact Us Page',
      description: 'დაგვიკავშირდი',
      SITE_NAME: '4dealer',
    })
  },
  components: {DreamCarComponent, ContactUsTitle}
}
</script>

<template>
  <div class="page">
    <contact-us-title></contact-us-title>
    <dream-car-component></dream-car-component>
  </div>
</template>

<style scoped>

</style>
