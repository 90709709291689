<script>
import {defineComponent} from 'vue'
import {client} from "@/axios/client";
import CarBlock from "@/components/car-block.vue";

export default defineComponent({
  name: "FavoritesComponent",
  components: {CarBlock},
  data() {
    return {
      favorites: []
    }
  },
  mounted() {
    this.getFavorites();
  },
  methods: {
    async getFavorites() {
      const {data} = await client.post('api/favorite-cars', {
        lotIds: JSON.parse(window.localStorage.getItem('favorite') ?? '[]')
      });
      this.favorites = data;
      console.log(data)
    }
  }
})
</script>

<template>
  <div class="p-5">
    <div v-if="favorites.length > 0" class="grid xs:grid-cols-1 mb:grid-cols-2 xlg:grid-cols-3 gap-4">
      <car-block :car="car" v-for="(car,index) in favorites" :key="index"></car-block>
    </div>
    <div class="flex justify-center items-center h-[500px] favorite-text" v-else>
      Add something to your favorites to see it here!
    </div>
  </div>
</template>

<style scoped>
.favorite-text{
  color: #585151;
  font-weight: 500;
  font-size: 18px;
  font-family: 'TBCContractica';
}
.favorite-header{
  color: #000;
  font-family: TBCContracticaCaps;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px; /* 111.364% */
}
</style>