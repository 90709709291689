<script>
import requestCallComponent from "@/modals/request-call.vue";

export default {
  name: "title-component",
  components: {requestCallComponent},
  data() {
    return {
      requestCall: false
    }
  }
}
</script>

<template>
  <div  class="title-wrapper">
    <div  class="title-block">
      <div  class="title-header-wrapper xs:px-2">
        <div  class="title-header mb-8 xs:text-center mb:text-left">
          <h1 >{{ $t('mainBlock.header') }}</h1></div>
        <div  class=" mb:pr-0 lg:mt-10">
          <button @click="requestCall = true"  data-modal-target="request-call" data-modal-toggle="request-call" class="btn btn-font btn-blue xs:w-full mb:w-auto">{{ $t('mainBlock.btn') }}
          </button>
        </div>
      </div>
      <div  class="img-wrapper">
        <div  class="maps"><img  :src="require('../assets/maps.png')" :data-rjs="require('../assets/maps.png')" alt=""></div>
      </div>
    </div><!---->
    <div  class="images">
      <div  class="car" data-rjs="3">
        <img :src="require('../assets/car.png')" :data-rjs="require('../assets/car@4x.png')" alt=""></div>
      <div  class="lines">
        <img  :src="require('../assets/lines.png')" :data-rjs="require('../assets/lines@4x.png')" alt=""></div>
    </div>
  </div>
  <div class="modal" v-if="requestCall">
    <request-call-component :show="requestCall" :backEnd="'consultation'"
                            @close="requestCall = false"></request-call-component>
  </div>
</template>

<style>

.car {
  z-index: 3;
  top: 0;
  bottom: -150px
}

.car, .lines, .maps {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center
}

.maps {
  top: 350px;
  bottom: 0;
  z-index: 1
}

.title-wrapper {
  padding-top: 160px;
  background: radial-gradient(66.5% 49.16% at 86.12% 54.56%, rgba(69, 147, 255, .2) 0, rgba(65, 134, 255, 0) 100%), radial-gradient(42.45% 37.16% at 16.64% 74.47%, rgba(37, 109, 255, .04) 0, rgba(37, 109, 255, 0) 100%), #f4f2fe;
  width: 100%;
  position: relative
}

.title-wrapper > .title-block {
  max-width: 1600px;
  margin: 0 auto;
  position: relative
}

.title-header {
  max-width: 1100px;
  color: #000;
  font-family: TBCContracticaCaps;
  font-style: normal;
  font-weight: 500
}

@media (max-width: 900px) {
  .title-wrapper {
    height: 740px
  }

  .title-header-wrapper {
    top: 40px
  }

  .car {
    top: 20px
  }

  .maps {
    top: 70px
  }

  .img-wrapper {
    height: 500px
  }
}

@media (min-width: 300px) {
  .title-header-wrapper {
    position: relative;
    z-index: 99;
    top: -20px
  }

  .title-header h1 {
    font-family: TBCContracticaCaps;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 5px
  }

  .lines {
    z-index: 2;
    top: 330px
  }
}

@media (min-width: 901px) {
  .lines {
    z-index: 2;
    top: 0;
    bottom: -80px
  }

  .title-header-wrapper {
    position: absolute;
    z-index: 99;
    left: 20px
  }

  .title-header h1 {
    color: #000;
    font-family: TBCContracticaCaps;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 69px
  }

  .title-header-wrapper {
    top: 160px
  }

  .title-wrapper {
    height: 910px
  }
}

@media (min-width: 1400px) {
  .title-header h1 {
    font-size: 64px
  }
}




@media (max-width: 900px) {
  .title-wrapper {
    height:740px;
  }

  .title-header-wrapper {
    top: 40px
  }

  .car {
    top: 20px
  }

  .maps {
    top: 70px
  }

  .img-wrapper {
    height: 500px
  }
}

@media (min-width: 300px) {
  .title-header-wrapper {
    position:relative;
    z-index: 99;
    top: -20px
  }

  .title-header h1 {
    font-family: TBCContracticaCaps;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 5px
  }

  .lines {
    z-index: 2;
    top: 330px
  }
}

@media (min-width: 901px) {
  .lines {
    z-index:2;
    top: 0;
    bottom: -80px
  }

  .title-header-wrapper {
    position: absolute;
    z-index: 99;
    left: 20px
  }

  .title-header h1 {
    color: #000;
    font-family: TBCContracticaCaps;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 69px
  }

  .title-header-wrapper {
    top: 160px
  }

  .title-wrapper {
    height: 910px
  }
}

@media (min-width: 1400px) {
  .title-header h1 {
    font-size:64px
  }
}
</style>
