<script>
import {freezeScroll, isMobileResolution, unfreezeScroll} from "@/helpers/helpers";

export default {
  name: "drop-down-year",
  props: {
    max: {
      require: true
    },
    min: {
      require: true
    }
  },
  data() {
    return {
      open: false,
      isMobile: isMobileResolution(),
      years: [],
      selected: [],
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
      yearsFromList: [],
      yearsToList: [],
      startYear: this.min,
      endYear: this.max,
      selectedFrom: this.min,
      selectedTo: this.max
    }
  },
  async mounted() {
    this.yearsFromList = this.generateFrom(this.startYear,this.endYear);
    this.yearsToList = this.generateTo(this.startYear,this.endYear);
  },
  watch:{
    selectedFrom(){
      this.$emit('update',[this.selectedFrom,this.selectedTo]);
    },
    selectedTo(){
      this.$emit('update',[this.selectedFrom,this.selectedTo]);
    }
  },
  methods: {
    generateFrom(min, max) {
      const sequence = [];
      for (let i = min; i <= max; i++) {
        sequence.push(i);
      }
      return sequence;
    },
    generateTo(min, max) {
      const sequence = [];
      for (let i = max; i >= min; i--) {
        sequence.push(i);
      }
      return sequence;
    },
    startSwipe(event) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    }
    ,
    trackSwipe(event) {
      this.endX = event.touches[0].clientX;
      this.endY = event.touches[0].clientY;
    }
    ,
    endSwipe() {
      const deltaX = this.endX - this.startX;
      const deltaY = this.endY - this.startY;

      if (Math.abs(deltaY) > Math.abs(deltaX)) {
        if (deltaY > 0) {
          this.open = false;
          unfreezeScroll()
        } else {
          this.open = false;
          unfreezeScroll()
        }
      }

      // Сбрасываем координаты
      this.startX = 0;
      this.startY = 0;
      this.endX = 0;
      this.endY = 0;
    },
    clearYear(){
      this.selectedTo = this.max;
      this.selectedFrom = this.min;
      this.$emit('update',[this.min,this.max]);
    },
    selectByImage(key) {
      const index = this.selected.indexOf(key);
      if (index !== -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(key);
      }
    }
    ,
    openModal() {
      this.open = true;
      if (this.isMobile) {
        freezeScroll();
      }
    }
    ,
    closeModal() {
      if (this.isMobile) {
        unfreezeScroll();
      } else {
        this.open = false
      }
    }
  }
}
</script>

<template>
  <div class="year-dropdown lg:relative flex items-center xlg:w-3/12 xs:w-full" id="year-dropdown"
       v-click-outside="closeModal">
    <div @click="openModal" class="year-dropdown-header 2xlg:w-[350px] xs:w-full flex items-center cursor-pointer">
      <div class="flex mr-2 items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM20 11H4V19H20V11ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM6 13H8V15H6V13ZM11 13H13V15H11V13ZM16 13H18V15H16V13Z"
              fill="#707070"></path>
        </svg>
      </div>

      <p v-if="selectedFrom === min && selectedTo === max">{{ $t('mainFilter.year') }}</p>
      <div v-else class="w-full flex relative justify-between items-center">
        <div class="w-11/12 overflow-hidden">
          {{ selectedFrom }} - {{ selectedTo }}
        </div>
        <div class="absolute right-0 top-[6px]" @click="clearYear">
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.43236 5.08623L11.3824 0.13623L12.7964 1.55023L7.84636 6.50023L12.7964 11.4502L11.3824 12.8642L6.43236 7.91423L1.48236 12.8642L0.0683594 11.4502L5.01836 6.50023L0.0683594 1.55023L1.48236 0.13623L6.43236 5.08623Z" fill="black"/>
          </svg>
        </div>
      </div>
    </div>
    <teleport to="#modal" :disabled="!isMobile" v-if="open">
      <div class="maker-list-wrapper">
        <div @touchstart="startSwipe"
             @touchmove="trackSwipe"
             @touchend="endSwipe" id="swiper" v-if="isMobile" class="mobile flex justify-center items-center h-[40px]">
          <svg width="107" height="3" viewBox="0 0 107 3" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <rect width="107" height="3" rx="1.5" fill="#B6B6B6"></rect>
          </svg>
        </div>
        <div class="years-list-wrapper">
          <div class="flex justify-around pt-5">
            <div class="period-text">{{ $t('filters.from') }}</div>
            <div class="w-[1px]"></div>
            <div class="period-text">{{ $t('filters.to') }}</div>
          </div>
          <div class="years-list flex justify-around pt-2 overflow-hidden">
            <div class="from-list overflow-x-hidden">
              <div class="year-option mb-2 pt-1 pb-1 pl-2 pr-2 cursor-pointer" :class="{
                selected : selectedFrom === year
              }" @click="selectedFrom = year" v-for="(year,key) in yearsFromList" :key="key">{{ year }}</div>
            </div>
            <div class="line"></div>
            <div class="to-list overflow-x-hidden">
              <div class="year-option mb-2 pt-1 pb-1 pl-2 pr-2 cursor-pointer" :class="{
                selected : selectedTo === year
              }" @click="selectedTo = year" v-for="(year, key) in yearsToList" :key="key">{{ year }}</div>
            </div>
          </div>
        </div>
      </div>

    </teleport>
  </div>
</template>

<style scoped>
.year-option {
  transition: .5s
}

.year-option:hover {
  transition: .5s;
  color: var(--Blue, #256dff)
}

.from-list, .to-list {
  overflow-y: scroll;
  padding-right: 10px
}

.from-list {
  padding-left: 10px
}

.year-dropdown {
  height: 61px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9
}

.years-list-wrapper {
  border-radius: 4px;
  background: #fafafa;
  position: absolute;
  width: 100%;
  z-index: 9999;
  top: 70px
}

.year-option {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px
}

.year-dropdown-header {
  color: #383838;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  height: 50px;
  padding: 0 16px
}

.line {
  width: 1px;
  background: #d9d9d9
}

.selected.year-option {
}

.period-text {
  width: 80px;
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  text-align: center
}

@media screen and (min-width: 320px) {
  .from-list, .to-list {
    padding-bottom: 200px
  }

  .years-list-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    transform: matrix()
  }

  .years-list {
    height: 100vh
  }
}
.year-option.selected{
  color: var(--blue, #256dff)
}
@media screen and (min-width: 1000px) {
  .from-list, .to-list {
    padding-bottom: 0
  }

  .years-list-wrapper {
    position: absolute
  }

  .year-dropdown {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9
  }

  .year-dropdown-header {
    border-right: 1px solid #c1c1c1
  }

  .years-list-wrapper {
    top: 0px
  }

  .years-list {
    height: 300px
  }
}
</style>
