<script>

import ForDealerTitle from "@/components/for-dealer-title.vue";
import ServicesForDealer from "@/components/services-for-dealer.vue";
import StepsForDealer from "@/components/steps-for-dealer.vue";
import FaqComponent from "@/components/faq.vue";
import DreamCarComponent from "@/components/dream-car.vue";
import {useMeta} from "vue-meta";

export default {
  name : "for-dealer-page",
  components: {DreamCarComponent, FaqComponent, StepsForDealer, ServicesForDealer, ForDealerTitle},
  setup() {
    useMeta({
      title: 'For Dealer Page',
      description: 'შემოგვიერთდი და დაიწყე დამატებითი შემოსავლის მიღება',
      SITE_NAME: '4dealer',
    })
  },
}
</script>

<template>
<div>
  <for-dealer-title></for-dealer-title>
  <services-for-dealer></services-for-dealer>
  <steps-for-dealer></steps-for-dealer>
  <faq-component></faq-component>
  <dream-car-component></dream-car-component>
</div>
</template>

<style scoped>

</style>
