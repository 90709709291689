<script>
import {defineComponent} from 'vue'
import DreamCarComponent from "@/components/dream-car.vue";
import {client} from "@/axios/client";
import {useMeta} from "vue-meta";

export default defineComponent({
  name: "FavoriteCarPage",
  components: {DreamCarComponent},
  setup() {
    useMeta({
      title: 'Favorites Page',
      description: 'ჩემი ფავორიტები',
      SITE_NAME: '4dealer',
    })
  },
  data(){
    return {
      favorites : []
    }
  },mounted() {
    this.getFavorites();
  },
  methods:{
    async getFavorites(){
      const {data} = await client.post('api/favorite-cars',{
        lotIds : JSON.parse(window.localStorage.getItem('favorite') ?? '[]')
      });
      this.favorites = data;
      console.log(data)
    }
  }
})
</script>

<template>
  <div class="page">
    <div class="favorite-wrapper max-w-[1600px] mx-auto py-5 xs:px-2 mb:px-5">
      <div class="favorite-header">
        {{ $t('favorite.header') }}
      </div>
      <div class="min-h-[500px] my-5">
        <div v-if="favorites.length > 0" class="grid xs:grid-cols-1 mb:grid-cols-2 xlg:grid-cols-3 gap-4">
            <car-block :car="car" v-for="(car,index) in favorites" :key="index"></car-block>
        </div>
        <div class="flex justify-center items-center h-[500px] favorite-text" v-else>
          Add something to your favorites to see it here!
        </div>
      </div>
    </div>
    <dream-car-component></dream-car-component>
  </div>
</template>

<style scoped>
.favorite-text{
  color: #585151;
  font-weight: 500;
  font-size: 18px;
  font-family: 'TBCContractica';
}
.favorite-header{
  color: #000;
  font-family: TBCContracticaCaps;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px; /* 111.364% */
}
</style>