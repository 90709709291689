<script>
export default {
  name: "about-us-title"
}
</script>

<template>
  <div class="about-us-title xs:p-5 2xlg:px-0 mb:py-10" id="about-us-title">
    <div class="about-us-title-wrapper mr-auto ml-auto">
      <div>
        <div class="mb:flex justify-between items-center mb:px-24 mt-5">
          <div class="flex items-center xs:my-5">
            <div class="big-number mr-2">10</div>
            <div class="text-number">{{ $t('aboutUs.title1') }}</div>
          </div>
          <div class="line"></div>
          <div class="flex items-center xs:my-5">
            <div class="big-number mr-2">5000</div>
            <div class="text-number">{{ $t('aboutUs.title2') }}</div>
          </div>
          <div class="line"></div>
          <div class="flex items-center xs:my-5">
            <div class="big-number mr-2">8000</div>
            <div class="text-number">{{ $t('aboutUs.title3') }}</div>
          </div>
          <div class="line"></div>
          <div class="flex items-center xs:my-5">
            <div class="big-number mr-2">250</div>
            <div class="text-number">{{ $t('aboutUs.title4') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
