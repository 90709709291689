import axios from "axios";
import emitter from "@/eventBus/mitt";

const APP_BASE_URL = "https://back-end.4dealer.co/";

const client = axios.create({
    baseURL: APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

client.interceptors.request.use(
    (config) => {
        const token = window.sessionStorage?.token;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
client.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            emitter.emit('logout');
        }
        return Promise.reject(error);
    }
);
export { client };

