<script>
import {defineComponent, ref} from 'vue'
import {client} from "@/axios/client";
import "vue-select/dist/vue-select.css";

export default defineComponent({
  name: "MyCarsCreateComponent",
  data() {
    return {
      images: [],
      showModal: false,
      selectedImage: null,
      year: "",
      selectedMaker: "",
      selectedModel: "",
      selectedCategory: "",
      selectedTransmission: "",
      selectedDamage: "",
      selectedFuelType: "",
      selectedEngineType: "",
      location: "",
      errors: [],
      errorsKey: [],
      color: "",
      hasKeys: "No",
      description: "",
      mileage: "",
      price: "",
      fieldsSettings: {}
    }
  },
  async mounted() {
    await this.getFields()
  },
  methods: {
    async getFields() {
      const {data} = await client.get('api/get-fields');
      this.fieldsSettings = data;
    },
    ref,
    handleFileUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.images.push({
            file: files[i],
            previewUrl: e.target.result
          });
        };
        reader.readAsDataURL(files[i]);
      }
    },
    async createCar(){
      try{
        const {status} = await client.post('api/create-car',{
          images: this.images,
          year: this.year,
          selectedMaker: this.selectedMaker,
          selectedModel: this.selectedModel,
          selectedCategory: this.selectedCategory,
          selectedTransmission: this.selectedTransmission,
          selectedDamage: this.selectedDamage,
          selectedFuelType: this.selectedFuelType,
          hasKeys: this.hasKeys === "on" ,
          selectedEngineType: this.selectedEngineType,
          location: this.location,
          color: this.color,
          description: this.description,
          mileage: this.mileage,
          price: this.price,
        })
        if(status === 200){
          this.$swal({
            toast: true,
            icon: "success",
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            title : 'Car was added!'
          });
          this.$router.push({name : "profile.my-cars"})
        }
      }catch (e){
        this.errors = e?.response?.data?.errors
        this.errorsKey = Object.keys(e?.response?.data?.errors);
      }


    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    showImage(index) {
      this.selectedImage = this.images[index];
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedImage = null;
    },
    select() {

    }
  }
})
</script>

<template>
  <div class="p-5">
    <router-link :to="{name : 'profile.my-cars'}" class="btn-blue mb-5" >
      <div class="flex blue-text items-center">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1619_16873)">
            <path
                d="M10.828 12.5007L15.778 17.4507L14.364 18.8647L8 12.5007L14.364 6.13672L15.778 7.55072L10.828 12.5007Z"
                fill="#256DFF"/>
          </g>
          <defs>
            <clipPath id="clip0_1619_16873">
              <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
          </defs>
        </svg>
        <div>
          Back
        </div>
      </div>
    </router-link>
    <div class="max-w-[250px] my-5">
      <div class="flex">
        <label for="file-upload" class="photos flex justify-center mr-3 items-center" :class="{
        'red-border' : errorsKey.includes('selectedEngineType')
      }">
          <div class="mr-2">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1619_13751)">
                <path
                    d="M21 15.5V18.5H24V20.5H21V23.5H19V20.5H16V18.5H19V15.5H21ZM21.008 3.5C21.556 3.5 22 3.945 22 4.493V13.5H20V5.5H4V19.499L14 9.5L17 12.5V15.329L14 12.329L6.827 19.5H14V21.5H2.992C2.72881 21.4997 2.4765 21.395 2.29049 21.2088C2.10448 21.0226 2 20.7702 2 20.507V4.493C2.00183 4.23038 2.1069 3.97902 2.29251 3.79322C2.47813 3.60742 2.72938 3.50209 2.992 3.5H21.008ZM8 7.5C8.53043 7.5 9.03914 7.71071 9.41421 8.08579C9.78929 8.46086 10 8.96957 10 9.5C10 10.0304 9.78929 10.5391 9.41421 10.9142C9.03914 11.2893 8.53043 11.5 8 11.5C7.46957 11.5 6.96086 11.2893 6.58579 10.9142C6.21071 10.5391 6 10.0304 6 9.5C6 8.96957 6.21071 8.46086 6.58579 8.08579C6.96086 7.71071 7.46957 7.5 8 7.5Z"
                    fill="#256DFF"/>
              </g>
              <defs>
                <clipPath id="clip0_1619_13751">
                  <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>
            Add photo
          </div>
        </label>
        <div class="photos-extension flex items-center">
          JPG,PNG
        </div>
      </div>
      <input type="file" id="file-upload" ref="file-upload" accept=".png, .jpeg, .jpg" style="display: none" multiple
             @change="handleFileUpload">
    </div>
    <div class="grid grid-cols-4 gap-4">
      <div v-for="(image, index) in images" :key="index" class="image-item">
        <img :src="image.previewUrl" alt="Preview" class="profile-pic" @click="showImage(index)">
        <button class="remove-button" @click="removeImage(index)">X</button>
      </div>
    </div>
    <div v-if="Object.keys(fieldsSettings).length > 0">
      <div class="mb-5 max-w-[300px]" :class="{
        'red-border' : errorsKey.includes('selectedMaker')
      }">
        <div class="mb-2">
          <label for="">Maker</label>
        </div>
        <v-drop-down :options="fieldsSettings.makers"
                     :disabled="false"
                     v-model="selectedMaker"
                     label="name"
                     placeholder="Please select maker"></v-drop-down>
      </div>
      <div class="mb-5 max-w-[300px]" :class="{
        'red-border' : errorsKey.includes('selectedModel')
      }">
        <div class="mb-2">
          <label for="">Model</label>
        </div>
        <v-drop-down :key="selectedMaker" :options="fieldsSettings.models[selectedMaker] ?? []"
                     :disabled="false"
                     v-model="selectedModel"
                     label="name"
                     placeholder="Please select model"></v-drop-down>
      </div>
      <div class="mb-5 max-w-[300px]" :class="{
        'red-border' : errorsKey.includes('selectedCategory')
      }">
        <div class="mb-2">
          <label for="">Category</label>
        </div>
        <v-drop-down :options="fieldsSettings.categories"
                     :disabled="false"
                     v-model="selectedCategory"
                     label="name"
                     placeholder="Please select category"></v-drop-down>
      </div>
      <div class="group-input mb-5">
        <div class="mb-2">
          <label for="">Location</label>
        </div>
        <div>
          <input type="text" class="!h-[40px]" placeholder="Georgia, Tbilisi" v-model="location">
        </div>
      </div>
      <div class="group-input mb-5" :class="{
        'red-border' : errorsKey.includes('year')
      }">
        <div class="mb-2">
          <label for="">Year</label>
        </div>
        <div>
          <input type="number" placeholder="2013" class="!h-[40px]" v-model="year">
        </div>
      </div>
      <div class="group-input mb-5 " :class="{
        'red-border' : errorsKey.includes('price')
      }">
        <div class="mb-2">
          <label for="">Price</label>
        </div>
        <div>
          <input type="number" placeholder="1000" class="!h-[40px]" v-model="price">
        </div>
      </div>
      <div class="group-input mb-5 "
           :class="{
        'red-border' : errorsKey.includes('mileage')
      }">
        <div class="mb-2">
          <label for="">Mileage</label>
        </div>
        <div>
          <input type="number" placeholder="43629" class="!h-[40px]" v-model="mileage">
        </div>
      </div>
      <div class="mb-5 max-w-[300px]"
           :class="{
        'red-border' : errorsKey.includes('selectedTransmission')
      }">
        <div class="mb-2">
          <label for="">Transmission</label>
        </div>
        <v-drop-down :options="fieldsSettings.transmission"
                     :disabled="false"
                     v-model="selectedTransmission"
                     label="name"
                     placeholder="Please select transmission"></v-drop-down>
      </div>
      <div class="mb-5 max-w-[300px]" :class="{
        'red-border' : errorsKey.includes('selectedDamage')
      }">
        <div class="mb-2">
          <label for="">Damage</label>
        </div>
        <v-drop-down :options="fieldsSettings.secondaryDamage"
                     :disabled="false"
                     v-model="selectedDamage"
                     label="name"
                     placeholder="Please select damage"></v-drop-down>
      </div>
      <div class="mb-5 max-w-[300px]" :class="{
        'red-border' : errorsKey.includes('selectedFuelType')
      }">
        <div class="mb-2">
          <label for="">Fuel Type</label>
        </div>
        <v-drop-down :options="fieldsSettings.fuel_type"
                     :disabled="false"
                     v-model="selectedFuelType"
                     label="name"
                     placeholder="Please select fuel type"></v-drop-down>
      </div>
      <div class="mb-5 max-w-[300px]" :class="{
        'red-border' : errorsKey.includes('selectedEngineType')
      }">
        <div class="mb-2">
          <label for="">Engine Type</label>
        </div>
        <v-drop-down :options="fieldsSettings.engine"
                     :disabled="false"
                     v-model="selectedEngineType"
                     label="name"
                     placeholder="Please select engine"></v-drop-down>
      </div>
      <div class="group-input mb-5 ">
        <div class="mb-2">
          <label for="">Color</label>
        </div>
        <div>
          <input placeholder="Red" type="text" class="!h-[40px]" v-model="color">
        </div>
      </div>
      <div class="mb-5 max-w-[300px]">
        <div class="mb-2">
          <label for="">Keys</label>
        </div>
        <div class="flex items-center justify-start">
          <div class="flex items-center mr-5">
            <input id="default-radio-1" type="radio" :value="'on'" v-model="hasKeys" name="default-radio"
                   class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="default-radio-1" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
          </div>
          <div class="flex items-center">
            <input checked id="default-radio-2" :value="'no'" type="radio" v-model="hasKeys" name="default-radio"
                   class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
            <label for="default-radio-2" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
          </div>
        </div>
      </div>
      <div class="mb-5 max-w-[300px]">
        <label for="message" class="">Description</label>
        <textarea id="message" rows="4" v-model="description"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Description about Car..."></textarea>
      </div>
      <div class="mb-5 max-w-[300px]">
        <div v-for="(error,key) in errors" :key="key" class="text-red-400 font-bold">
          {{ error[0] }}
        </div>
      </div>
      <div class="max-w-[300px]">
        <button class="w-full btn btn-blue btn-font" @click="createCar">Publicate the car</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
label.photos {
  color: var(--Blue, #256DFF);
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  border-radius: 4px;
  border: 1px solid var(--Blue, #256DFF);
  padding: 12px 24px;
  transition: 0.5s;
}

label.photos:hover {
  transition: 0.5s;
  color: white;
  background: #256DFF;
}

label.photos:hover path {
  transition: 0.5s;
  fill: white;
}

.photos-extension {
  color: #636363;
  font-family: TBCContractica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

label {
  color: #3F3F3F;
  font-family: TBCContractica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
label.red-border{
  border-color: red;
  color: red;

}
</style>