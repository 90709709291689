<script >
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import {client} from "@/axios/client";
export default {
  name: "dream-car-component",
  components:{VueTelInput},
  data(){
    return {
      name : "",
      validate : false,
      phone : null,
      country : {},
      isSend : false
    }
  },
  methods: {
    countryChanged(country){
      this.country = country;
    },
    changeNumber(number){
      if(typeof number === 'string'){
        this.phone = number
      }
    },
    create(){
      if(this.phone.length <= 11 || this.name.length === 0){
        this.validate = true;
      }else{
        this.validate = false
      }

      if(this.validate){
        return
      }
      client.post('api/request-a-call/dream-car',{
        country : this.country,
        phone : this.phone,
        name : this.name,
        options : this.options
      }).then(({status})=>{
        if(status === 200){
          setTimeout(()=>{
            this.isSend = false;
          },5000)
          this.phone = ""
          this.name = ""
          this.isSend = true;
        }
      });
    }
  }
}
</script>

<template>
  <div class="dream-car w-full" id="dream-car">
    <div v-if="!isSend" class="dream-car-wrapper relative mr-auto ml-auto xlg:flex xlg:justify-between xlg:items-center xs:text-center">
      <div class="2xlg:w-6/12 xs:w-full xs:mb-5 2xlg:ml-40">
        <div class="dream-car-header mb-5 2xlg:w-7/12 xlg:w-9/12 text-left 2xlg:p-0 xs:pl-5 ">
          <h2>
            {{ $t('dreamCar.header') }}
          </h2>
        </div>
        <div class="dream-car-from mb-5 2xlg:w-8/12 xlg:w-8/12 xs:p-5 2xlg:p-0 ">
          <div class="mb-6 2xlg:w-12/12 xs:w-12/12 group-input">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="name" >
              {{ $t('dreamCar.name') }}
            </label>
            <input v-model="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" :placeholder="$t('dreamCar.name')" type="text" id="name">
            <!--            <p class="text-red-500 text-xs italic">Please choose a password.</p>-->
          </div>
          <div class="mb-6 2xlg:w-12/12 xs:w-12/12">
            <label class="label block text-gray-700 text-sm font-bold mb-2" for="phone">
              {{ $t('dreamCar.phoneNumber') }}
            </label>
            <vue-tel-input
                autoformat
                mode="national"
                :inputOptions="{placeholder:$t('dreamCar.phoneNumber')}"
                :disabledFormatting="false"
                :placeholder="'Enter a phone number'"
                @input="changeNumber"
                v-on:country-changed="countryChanged"
            >
            </vue-tel-input>
            <!--            <p class="text-red-500 text-xs italic">Please choose a password.</p>-->
          </div>
        </div>
        <div class="flex justify-start xs:pl-5 xs:pr-5 2xlg:pl-0">
          <button @click="create" class="btn btn-font btn-blue xs:w-full mb:w-auto">
            {{ $t('dreamCar.btn') }}
          </button>
        </div>
        <div v-if="validate" class=" p-4 mb-4 mt-4 text-sm text-red-800 rounded-lg bg-red-50">
          Enter all fields
        </div>
      </div>
      <div class="retro-left-img 2xlg:w-6/12 xs:w-full">
      </div>
    </div>
    <div class="relative success-request w-full dream-car-wrapper relative mr-auto ml-auto xlg:flex xlg:justify-between xlg:items-center xs:text-center" v-else>
      <div class="text-congrats flex justify-center items-center h-full" style="flex-direction: column">
        <div class="sent flex justify-center items-center mb-5">
          Request has been sent!
        </div>
        <div class="waiting flex justify-center items-center">
          <div class="flex mr-2">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_621_3372)">
                <path d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 20.5C14.1217 20.5 16.1566 19.6571 17.6569 18.1569C19.1571 16.6566 20 14.6217 20 12.5C20 10.3783 19.1571 8.34344 17.6569 6.84315C16.1566 5.34285 14.1217 4.5 12 4.5C9.87827 4.5 7.84344 5.34285 6.34315 6.84315C4.84285 8.34344 4 10.3783 4 12.5C4 14.6217 4.84285 16.6566 6.34315 18.1569C7.84344 19.6571 9.87827 20.5 12 20.5ZM11.003 16.5L6.76 12.257L8.174 10.843L11.003 13.672L16.659 8.015L18.074 9.429L11.003 16.5Z" fill="#5D9C59"/>
              </g>
              <defs>
                <clipPath id="clip0_621_3372">
                  <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>
            Wait for a call from our dealer
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.dream-car-header{
  color: #000;
  font-family: TBCContractica;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
}
.retro-left-img{
  background-repeat: no-repeat;
  background-image: url("../assets/dream/dream-car.png");
  background-position: right;
}
@media screen and (min-width: 1200px) {
  .dream-car-header {
    max-width:559px
  }

  .retro-left-img {
    height: 661px
  }

  .dream-car-header {
    color: #000;
    font-family: TBCContracticaCaps;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 49px
  }
}

@media screen and (max-width: 1199px) {
  .dream-car-header {
    color:#000;
    font-family: TBCContracticaCaps;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px
  }

  .retro-left-img {
    height: 317px;
    background-size: contain
  }
}

.dream-car {
  background: #fff
}

.dream-car-wrapper {
  max-width: 1600px
}

.label {
  color: #3f3f3f;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: left
}

.sent {
  font-size: 44px;
  font-weight: 700;
  line-height: 49px
}

.sent,.waiting {
  color: #000;
  font-family: TBCContractica;
  font-style: normal
}

.waiting {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px
}

.success-request {
  min-height: 661px
}

.success-request>.text-congrats {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
}
</style>
