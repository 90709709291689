<script>

export default {
}
</script>

<template>
  <div class="result max-w-[1600px] mx-auto xs:px-2 2xlg:px-0">
    <div class="py-5 grid grid-cols-4 gap-4">
      <div v-for="(index,key) in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]" class="car-block animate-pulse mb-5" :key="key">
        <div class="flex-shrink-0"><span
            class="w-full2 h-[200px] block bg-gray-200 dark:bg-gray-700 mb-5"></span></div>
        <div class="w-full xs:p-2 mt-5"><h3 class="h-4 bg-gray-200 rounded-md dark:bg-gray-700"
                                            style="width:40%;"></h3>
          <ul class="mt-5 space-y-3">
            <li class="w-full h-4 bg-gray-200 mt-5 rounded-md dark:bg-gray-700"></li>
            <li class="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navigation flex justify-center py-5">
    </div>
  </div>
</template>

<style scoped>

</style>