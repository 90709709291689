<script>
import {defineComponent} from 'vue'
import {client} from "@/axios/client";

export default defineComponent({
  name: "RegisterPage",
  data() {
    return {
      errorsKey: [],
      errors: [],
      email: "",
      first_name: "",
      last_name: "",
      location: "",
      password: "",
      password_repeat: "",
      howManyCars: "0",
      hasError: false,
      message: "",
    }
  },
  methods: {
    async register() {
      try {
        await client.post('api/register/dealer', {
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          location: this.location,
          password: this.password,
          password_repeat: this.password_repeat,
          counter_car: this.howManyCars,
        });
        this.hasError = false;
        this.$swal({
          toast: true,
          icon: "success",
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          title : 'Register success!'
        });
        this.$router.push({name: 'login'})
      } catch (e) {
        this.errorsKey = Object.keys(e.response.data.errors);
        this.errors = e.response.data.errors;
        this.hasError = true;
      }

    }
  }
})
</script>

<template>
  <div class="page">
    <div class="login w-[440px] mx-auto my-20 " id="login">
      <div class="px-5 py-10">
        <div class="header-login text-center">{{ $t('register.header') }}</div>
        <div class="text-gray-400 font-light">{{ $t('register.subHeader') }}</div>
        <div class="px-10">
          <div class="group-input mt-4" :class="{
        'red-border' : errorsKey.includes('first_name')
      }">
            <div>
              <label for="name">{{ $t('register.firstName') }}</label>
            </div>
            <div>
              <input class="w-full h-[50px]" type="text" name="name" :placeholder="$t('register.firstName')" id="name"
                     v-model="first_name">
            </div>
          </div>
          <div class="group-input mt-4" :class="{
        'red-border' : errorsKey.includes('last_name')
      }">
            <div>
              <label for="sirname">{{ $t('register.lastName') }}</label>
            </div>
            <div>
              <input :class="{
        'red-border' : errorsKey.includes('last_name')
      }" class="w-full h-[50px]" type="text" name="sirname" :placeholder="$t('register.lastName')" id="sirname"
                     v-model="last_name">
            </div>
          </div>
          <div class="group-input mt-4" :class="{
        'red-border' : errorsKey.includes('location')
      }">
            <div>
              <label for="location">{{ $t('register.location') }}</label>
            </div>
            <div>
              <input class="w-full h-[50px]" type="text" name="location" :placeholder="$t('register.locationPlaceholder')" id="location"
                     v-model="location">
            </div>
          </div>
          <div class="group-input mt-4" :class="{
        'red-border' : errorsKey.includes('email')
      }">
            <div>
              <label for="email">{{ $t('register.email') }}</label>
            </div>
            <div>
              <input class="w-full h-[50px]" type="text" name="email" placeholder="dealer@dealer.com" id="email"
                     v-model="email">
            </div>
          </div>
          <div class="password-selection mt-5">
            {{ $t('register.passwordHeader') }}
          </div>
          <div class="group-input mt-4">
            <div class="relative">
              <input class="w-full" type="password" name="password" :placeholder="$t('register.password')" id="password"
                     v-model="password">
              <div class="eye-password">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1413_5430)">
                    <path
                        d="M8.79412 14.9995L7.252 14.5858L7.88051 12.2387C6.93972 11.8917 6.06536 11.3861 5.29541 10.7437L3.576 12.4639L2.44596 11.3339L4.16617 9.61448C3.19512 8.45151 2.54266 7.05634 2.27266 5.56552L3.84433 5.27882C4.45047 8.63458 7.38617 11.1806 10.9168 11.1806C14.4467 11.1806 17.3832 8.63458 17.9893 5.27882L19.561 5.56472C19.2914 7.05574 18.6392 8.4512 17.6683 9.61448L19.3877 11.3339L18.2577 12.4639L16.5383 10.7437C15.7683 11.3861 14.8939 11.8917 13.9531 12.2387L14.5817 14.5866L13.0395 14.9995L12.4102 12.6516C11.4218 12.821 10.4118 12.821 9.42343 12.6516L8.79412 14.9995Z"
                        fill="#6B7280"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1413_5430">
                      <rect width="20" height="20" fill="white" transform="translate(20.5) rotate(90)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div class="group-input mt-4">
            <div class="relative">
              <input class="w-full" type="password" name="password_repeat" :placeholder="$t('register.repeatPassword')" id="password_repeat"
                     v-model="password_repeat">
              <div class="eye-password">
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1413_5430)">
                    <path
                        d="M8.79412 14.9995L7.252 14.5858L7.88051 12.2387C6.93972 11.8917 6.06536 11.3861 5.29541 10.7437L3.576 12.4639L2.44596 11.3339L4.16617 9.61448C3.19512 8.45151 2.54266 7.05634 2.27266 5.56552L3.84433 5.27882C4.45047 8.63458 7.38617 11.1806 10.9168 11.1806C14.4467 11.1806 17.3832 8.63458 17.9893 5.27882L19.561 5.56472C19.2914 7.05574 18.6392 8.4512 17.6683 9.61448L19.3877 11.3339L18.2577 12.4639L16.5383 10.7437C15.7683 11.3861 14.8939 11.8917 13.9531 12.2387L14.5817 14.5866L13.0395 14.9995L12.4102 12.6516C11.4218 12.821 10.4118 12.821 9.42343 12.6516L8.79412 14.9995Z"
                        fill="#6B7280"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1413_5430">
                      <rect width="20" height="20" fill="white" transform="translate(20.5) rotate(90)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-auto">
          <div class="password-selection text-center my-5">
            {{ $t('register.howMany') }}
          </div>
          <div class="flex items-center justify-between px-5">
            <div class="flex items-center mr-5">
              <input id="default-radio-1" type="radio" :value="'0'" v-model="howManyCars" name="default-radio"
                     class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="default-radio-1" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">0</label>
            </div>
            <div class="flex items-center">
              <input checked id="default-radio-2" :value="'<5'" type="radio" v-model="howManyCars" name="default-radio"
                     class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="default-radio-2" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                  '<5'
                }}</label>
            </div>
            <div class="flex items-center">
              <input checked id="default-radio-3" :value="'5-10'" type="radio" v-model="howManyCars"
                     name="default-radio"
                     class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="default-radio-3"
                     class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">5-10</label>
            </div>
            <div class="flex items-center">
              <input checked id="default-radio-4" :value="'10-20'" type="radio" v-model="howManyCars"
                     name="default-radio"
                     class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="default-radio-4"
                     class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">10-20</label>
            </div>
            <div class="flex items-center">
              <input checked id="default-radio-5" :value="'20+'" type="radio" v-model="howManyCars" name="default-radio"
                     class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
              <label for="default-radio-5" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">20+</label>
            </div>
          </div>
        </div>
        <div v-if="hasError" class="text-center mt-3">
          <div class="text-red-400" v-if="errors.length === 0">
            {{ message }}
          </div>
          <div class="text-red-400" v-for="(error,key) in errors" :key="key">{{ error[0] }}</div>
        </div>
        <div>
          <button class="btn btn-blue btn-font w-full mt-8" @click="register">{{ $t('register.btn') }}</button>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.password-selection {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
}
</style>