<script>
export default {
  name: "buy-process-component",
  data() {
    return {
      processing: [
        {
          name: "Payment for services and car selection",
          img: "svg/bank-card-line.svg",
          text: "Lorem ipsum dolor sit amet consectetur. Laoreet nulla pretium dignissim sed.",
        },
        {
          name: "Conclusion of the contract",
          img: "svg/bill-line.svg",
          text: "Lorem ipsum dolor sit amet consectetur. Laoreet nulla pretium dignissim sed. Morbi at sit adipiscing leo quis ut. ",
        },
        {
          name: "Winning and paying for a car",
          img: "svg/auction-line.svg",
          text: "Lorem ipsum dolor sit amet consectetur. Laoreet nulla pretium dignissim sed. Morbi at sit adipiscing leo quis ut. ",
        },
        {
          name: "Delivery to the warehouse and loading into the container at the port",
          img: "svg/ship-line.svg",
          text: "Lorem ipsum dolor sit amet consectetur. Laoreet nulla pretium dignissim sed. ",
        },
        {
          name: "Payment for delivery",
          img: "svg/wallet-3-line.svg",
          text: "Lorem ipsum dolor sit amet consectetur. Laoreet nulla pretium dignissim sed. ",
        },
        {
          name: "Opening a car from a container",
          img: "svg/Container.svg",
          text: "Lorem ipsum dolor sit amet consectetur. Laoreet nulla pretium dignissim sed. Morbi at sit adipiscing leo quis ut. ",
        },
        {
          name: "Customs clearance",
          img: "svg/newspaper-line.svg",
          text: "Lorem ipsum dolor sit amet consectetur. Laoreet nulla pretium dignissim sed.",
        },
        {
          name: "Delivery across the country to you",
          img: "svg/map-pin-user-line.svg",
          text: "Lorem ipsum dolor sit amet consectetur. Laoreet nulla pretium dignissim sed.",
        }
      ]
    }
  },
  methods: {
    isOdd(num) {
      return num % 2;
    }
  }
}
</script>

<template>
  <div class="buy-process xs:my-5 mb:mx-auto 2xlg:pr-0 2xlg:pl-0 xs:p-5" id="buy-procces ">
    <div class="buy-process-header lg:mt-20"><h2 data-v-0345412c="">{{ $t('processABuyCar.header') }}</h2>
    </div>
    <div class="mb:pt-10 mb:pb-10 2xlg:pl-0 2xlg:pr-0 xs:p-2">
      <div class="timeline-area">
        <div class="container">
          <div class="all-timelines">
            <div class="relative single-timeline d-flex-2">
              <div class="isOdd timeline-text d-flex-2">
                <div class="process pl-11 pr-11 pt-7 pb-7 isOdd">
                  <div class="flex justify-center items-center circle circle-font">1</div>
                  <div class="arrow-right">
                    <svg width="20" height="33" viewBox="0 0 20 33" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.500002 1.53646L18.7124 16.5L0.5 31.4635L0.500002 1.53646Z"
                            fill="white" stroke="#EDE2FF"></path>
                    </svg>
                  </div>
                  <h6 class="process-header flex relative"><span class="absolute"><img
                      src="/img/bank-card-line.042f162f.svg" alt=""></span> {{ $t('processABuyCar.firstStep') }}</h6></div>
              </div>
              <div class="timeline-blank"></div>
            </div>
            <div class="relative single-timeline d-flex-2">
              <div class="timeline-text d-flex-2">
                <div class="process pl-11 pr-11 pt-7 pb-7">
                  <div class="flex justify-center items-center circle circle-font">2</div>
                  <div class="arrow-left">
                    <svg width="20" height="34" viewBox="0 0 20 34" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.5 2.03646L1.28762 17L19.5 31.9635L19.5 2.03646Z" fill="white"
                            stroke="#EDE2FF"></path>
                    </svg>
                  </div>
                  <h6 class="process-header flex relative"><span class="absolute"><img
                      src="/img/bill-line.e5459b8c.svg" alt=""></span> {{ $t('processABuyCar.secondStep') }}
                  </h6></div>
              </div>
              <div class="timeline-blank"></div>
            </div>
            <div class="relative single-timeline d-flex-2">
              <div class="isOdd timeline-text d-flex-2">
                <div class="process pl-11 pr-11 pt-7 pb-7 isOdd">
                  <div class="flex justify-center items-center circle circle-font">3</div>
                  <div class="arrow-right">
                    <svg width="20" height="33" viewBox="0 0 20 33" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.500002 1.53646L18.7124 16.5L0.5 31.4635L0.500002 1.53646Z"
                            fill="white" stroke="#EDE2FF"></path>
                    </svg>
                  </div>
                  <h6 class="process-header flex relative"><span class="absolute"><img
                      src="/img/auction-line.b0371403.svg" alt=""></span> {{ $t('processABuyCar.thirdStep') }}</h6></div>
              </div>
              <div class="timeline-blank"></div>
            </div>
            <div class="relative single-timeline d-flex-2">
              <div class="timeline-text d-flex-2">
                <div class="process pl-11 pr-11 pt-7 pb-7">
                  <div class="flex justify-center items-center circle circle-font">4</div>
                  <div class="arrow-left">
                    <svg width="20" height="34" viewBox="0 0 20 34" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.5 2.03646L1.28762 17L19.5 31.9635L19.5 2.03646Z" fill="white"
                            stroke="#EDE2FF"></path>
                    </svg>
                  </div>
                  <h6 class="process-header flex relative"><span class="absolute"><img
                      src="/img/ship-line.d88b8200.svg" alt=""></span> {{ $t('processABuyCar.FoughtStep') }}</h6></div>
              </div>
              <div class="timeline-blank"></div>
            </div>
            <div class="relative single-timeline d-flex-2">
              <div class="isOdd timeline-text d-flex-2">
                <div class="process pl-11 pr-11 pt-7 pb-7 isOdd">
                  <div class="flex justify-center items-center circle circle-font">5</div>
                  <div class="arrow-right">
                    <svg width="20" height="33" viewBox="0 0 20 33" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.500002 1.53646L18.7124 16.5L0.5 31.4635L0.500002 1.53646Z"
                            fill="white" stroke="#EDE2FF"></path>
                    </svg>
                  </div>
                  <h6 class="process-header flex relative"><span class="absolute"><img
                      src="/img/wallet-3-line.0da8ca5c.svg" alt=""></span> {{ $t('processABuyCar.FiveStep') }}</h6></div>
              </div>
              <div class="timeline-blank"></div>
            </div>
            <div class="relative single-timeline d-flex-2">
              <div class="timeline-text d-flex-2">
                <div class="process pl-11 pr-11 pt-7 pb-7">
                  <div class="flex justify-center items-center circle circle-font">6</div>
                  <div class="arrow-left">
                    <svg width="20" height="34" viewBox="0 0 20 34" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.5 2.03646L1.28762 17L19.5 31.9635L19.5 2.03646Z" fill="white"
                            stroke="#EDE2FF"></path>
                    </svg>
                  </div>
                  <h6 class="process-header flex relative"><span class="absolute"><img
                      src="/img/Container.1ba66591.svg" alt=""></span> {{ $t('processABuyCar.sixStep') }}</h6></div>
              </div>
              <div class="timeline-blank"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.buy-process {
  max-width: 1600px
}

.process-header span {
  top: 0;
  left: -30px
}

.process-header {
  color: #000;
  font-family: TBCContracticaCaps;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px
}

.proccess-text {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px
}

.circle-font {
  color: #fff;
  text-align: center;
  font-family: TBCContractica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px
}

.process {
  border-radius: 4px;
  border: 1px solid #ede2ff;
  background: #fff;
  text-align: left;
  width: 100%
}

ul {
  list-style: none;
  margin: 0!important
}

.container {
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  margin: 0 auto
}

.timelines h2 {
  font-size: 32px
}

.d-flex-2 {
  display: flex;
  align-items: center
}

.all-timelines {
  position: relative
}

.timelines h2 {
  text-align: center;
  color: #fff;
  font-weight: 600;
  margin-bottom: 40px
}

.all-timelines:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 5px;
  border-color: #256dff;
  border-left-width: 5px
}

.single-timeline {
  margin-bottom: 22px
}

.timeline-blank {
  width: 50%
}

.timeline-text {
  width: 50%;
  box-sizing: border-box;
  position: relative
}

.single-timeline:nth-child(2n) .timeline-text span {
  text-align: right
}

.circle {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  right: -20px;
  border-radius: 50%;
  background: #256dff
}

.single-timeline:nth-child(2n) {
  flex-direction: row-reverse
}

.single-timeline:nth-child(2n) .circle {
  left: -20px;
  right: unset
}

@media (min-width: 769px) {
  .buy-process-header {
    color:#000;
    font-family: TBCContracticaCaps;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 49px
  }

  .process:not(.isOdd) {
    margin-left: 50px
  }

  .process.isOdd {
    margin-right: 50px
  }

  .arrow-right {
    position: absolute;
    right: 31px
  }

  .arrow-left {
    position: absolute;
    left: 31px
  }

  .all-timelines:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 91%;
    width: 5px;
    border-color: #256dff;
    border-left-width: 5px;
    top: 35px;
  //outline: 23px solid #f4f4f6;
  //border-radius: 20px
  }

  .isOdd .arrow-left {
    position: absolute;
    left: 26px
  }

  .single-timeline:nth-child(2n) .timeline-text {
    text-align: left;
    text-align: right
  }
}

@media (max-width: 768px) {
  .buy-process-header {
    color:#000;
    font-family: TBCContracticaCaps;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px
  }

  .timeline-text {
    padding-left: 45px
  }

  .all-timelines:before {
    content: "";
    position: absolute;
    left: 2px!important;
    right: 0;
    margin: auto;
    height: 90%;
    width: 5px;
    border-color: #256dff;
    border-left-width: 5px;
    top: 40px!important
  }

  .isOdd .arrow-left {
    position: absolute;
    left: 26px
  }

  .circle {
    left: -16px
  }

  .arrow-left,.arrow-right {
    position: absolute;
    left: 26px
  }

  .arrow-right {
    transform: rotate(180deg)
  }

  .all-timelines:before {
    right: unset;
    top: 0
  }

  .single-timeline:nth-child(2n) .circle {
    left: -16px;
    right: unset
  }

  .timeline-blank {
    display: none
  }

  .timeline-text {
    width: 100%
  }

  .single-timeline:nth-child(2n) .timeline-text span {
    text-align: left!important
  }
}

@media (max-width: 360px) {
  .all-timelines:before {
    top:32px
  }
}
</style>
