<script>
import emitter from "@/eventBus/mitt";

export default {
  name: "lang-component",
  data(){
    return {
      lang: {
        pl: "pl",
        ru: "ru",
        en: "gb",
        ge: "ge",
      },
      selectedLanguage: {},
      countries: [
        {value: 'pl', flag: 'pl', label: 'Poland'},
        {value: 'ru', flag: 'ru', label: 'Russian'},
        {value: 'en', flag: 'gb', label: 'English'},
        {value: 'ge', flag: 'ge', label: 'Georgia'},
      ],
    }
  },
  mounted() {
    this.selectedLanguage = {
      value: this.$i18n.locale,
      label: this.lang[this.$i18n.locale],
      flag:  this.lang[this.$i18n.locale],
    };

    // emitter.on('change-lang',()=>{
    //   this.selectedLanguage = {
    //     value: this.$i18n.locale,
    //     label: this.lang[this.$i18n.locale],
    //     flag:  this.lang[this.$i18n.locale],
    //   };
    // })
  },
  watch: {
    selectedLanguage() {
      window.localStorage.locale = this.selectedLanguage.value.toLowerCase()
      this.$i18n.locale = this.selectedLanguage.value.toLowerCase()
      emitter.emit('change-lang');
    }
  },
  methods:{

  }
}
</script>

<template>
  <div class="flex items-center">
    <v-drop-down
        :clearable="false"
        v-model="selectedLanguage"
        :options="countries"
        label="label"
        class="bg-white w-[75px]"
    >
      <template v-slot:option="option">
        <span class="flag-icon flag-icon-squared mr-2" :class="['flag-icon-' + option?.flag.toLowerCase()]"></span>
        <span class="font-light">{{ option?.label }}</span>
      </template>
      <template v-slot:selected-option="option">
        <span class="flag-icon flag-icon-squared" :class="['flag-icon-' + option?.flag]"></span>
      </template>
    </v-drop-down>
  </div>
</template>

<style scoped>

</style>