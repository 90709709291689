<script>
import {defineComponent} from 'vue'
import {client} from "@/axios/client";

export default defineComponent({
  name: "MyCarsComponent",
  data(){
    return {
      cars : []
    }
  },
  mounted() {
    this.getCars();
  },
  methods:{
    async getCars(){
      const {data} = await client.post('api/cars');
      this.cars = data.data;
    },
    deleteCar(id){
      client.delete('api/car/'+id).then(() => this.getCars()).catch(error => console.log(error))
    },
    openConfirmModal(id){
      this.$swal.fire({
        title: "Do you want to delete the car?",
        showDenyButton: true,
        showConfirmButton: false,
        showCancelButton: true,
        denyButtonText: `Delete`
      }).then((result) => {
        if(result.isDenied){
          this.deleteCar(id)
        }
      });
    }
  }
})
</script>

<template>
  <div id="my-cars" class="p-5">
    <div class="flex w-full justify-between items-center mb-5">
      <div class="header">
        {{ $t('profile.menu.auctions') }}
      </div>
      <div>
        <router-link :to="{name : 'profile.my-cars.create'}" class="flex btn-font btn btn-blue items-center">
          <div class="mr-2">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z" fill="white"/>
            </svg>
          </div>
          <div>
            Sell a car
          </div>
        </router-link>
      </div>
    </div>
    <div class="cars-block">
      <div class="flex justify-between mb-5" v-for="(car,key) in cars" :key="key">
        <div class="car-info flex">
          <div class="w-[320px] h-[150px]">
            <img class="object-contain w-full h-full" :src="'https://back-end.4dealer.co/'+car?.images[0]?.path" alt="">
          </div>
          <div class="car-title mr-5">
            {{ car.year }} {{ car.maker }} {{ car.model }}
          </div>
          <div>
            <div class="price-title">
              Current Bid
            </div>
            <div class="price">
              ${{ car.price }}
            </div>
          </div>
        </div>
        <div class="actions flex items-center">
          <div class="cursor-pointer mr-5" @click="$router.push({
          'name' : 'profile.my-cars.edit',
          'params' : { id : car.id}
          })">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1219_33964)">
                <path d="M5 19.502H6.414L15.728 10.188L14.314 8.77404L5 18.088V19.502ZM21 21.502H3V17.259L16.435 3.82404C16.6225 3.63657 16.8768 3.53125 17.142 3.53125C17.4072 3.53125 17.6615 3.63657 17.849 3.82404L20.678 6.65304C20.8655 6.84056 20.9708 7.09487 20.9708 7.36004C20.9708 7.6252 20.8655 7.87951 20.678 8.06704L9.243 19.502H21V21.502ZM15.728 7.36004L17.142 8.77404L18.556 7.36004L17.142 5.94604L15.728 7.36004Z" fill="#256DFF"/>
              </g>
              <defs>
                <clipPath id="clip0_1219_33964">
                  <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="cursor-pointer" @click="openConfirmModal(car.id)">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1219_33799)">
                <path d="M7 4.5V2.5H17V4.5H22V6.5H20V21.5C20 21.7652 19.8946 22.0196 19.7071 22.2071C19.5196 22.3946 19.2652 22.5 19 22.5H5C4.73478 22.5 4.48043 22.3946 4.29289 22.2071C4.10536 22.0196 4 21.7652 4 21.5V6.5H2V4.5H7ZM6 6.5V20.5H18V6.5H6ZM9 9.5H11V17.5H9V9.5ZM13 9.5H15V17.5H13V9.5Z" fill="#BC2929"/>
              </g>
              <defs>
                <clipPath id="clip0_1219_33799">
                  <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.price-title{
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
}
.car-title{
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
}
.price{
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
}
.header {
  color: #000;
  font-family: TBCContracticaCaps;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
</style>