<script>
import VideosModal from "@/modals/videos-modal.vue";

export default {
  components: {VideosModal},
  data(){
   return {
     videosSelected: "",
     showModal: false,
   }
  },
  methods: {

    openVideo(number){
      this.showModal = true;
      this.videosSelected = number;
    },
    close(){
      this.showModal = false;
    }
  }
}
</script>

<template>
  <div class="services-videos 2xlg:py-24 xs:py-10 xs:px-5 mb:mt-24 xs:mt-12" id="services-videos">
    <div class="services-videos-wrapper mx-auto max-w-[1600px]">
      <div class="services-videos-header mb:mb-5">{{ $t('services.howItsWork') }}</div>
      <div class="grid mb:grid-cols-2 xs:grid-cols-1 gap-4">
        <div>
          <div class="cursor-pointer" @click="openVideo(0)">
            <img :src="require('../assets/services/1.png')" alt=""></div>
          <div class="2xlg:mt-5 xs:mt-2">
            <div class="block-header">{{ $t('services.hTitle1') }}</div>
            <div class="block-text 2xlg:mt-2 xs:mt-1">{{ $t('services.sHTitle1') }}</div>
          </div>
        </div>
        <div>
          <div class="cursor-pointer" @click="openVideo(1)">
            <img :src="require('../assets/services/2.png')" alt=""></div>
          <div class="2xlg:mt-5 xs:mt-2">
            <div class="block-header">{{ $t('services.hTitle2') }}</div>
            <div class="block-text 2xlg:mt-2 xs:mt-1">{{ $t('services.sHTitle2') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <videos-modal :video-number="videosSelected" :show="showModal" v-if="showModal" @close="close"></videos-modal>
  </div>
</template>

<style scoped>

</style>
