<script>
import DropDown from "@/components/small-components/drop-down.vue";
import _ from 'lodash';

export default {
  components: {DropDown},
  props: {
    Prefilters: {
      required: true,
    },
    selectedOvj: {
      required: true,
      type: Object
    },
    lotIdSelected: {
      type: String
    }
  },
  data() {
    return {
      catalogueType:  JSON.parse(window.localStorage.getItem('catalogue-type')) ?? false,
      additionalFilters: false,
      settings: {},
      filters: [],
      modelFilterKey: 0,
      lotId: "",
    }
  },
  watch: {
    catalogueType(){
      window.localStorage.setItem('catalogue-type',this.catalogueType)
      this.$emit('catalogue-type');
    },
    lotId(value) {
      this.checkLotId(value);
    },
  },
  async mounted() {
    this.lotId = this.lotIdSelected ?? "";
    this.settings = {...this.Prefilters}
    if (this.Prefilters?.primaryDamage &&
        Object.keys(this.Prefilters?.primaryDamage).length > 0 &&
        this.Prefilters?.secondaryDamage &&
        Object.keys(this.Prefilters?.secondaryDamage).length > 0
    ) {
      this.settings.damage = [
        ...this.Prefilters?.primaryDamage,
        ...this.Prefilters?.secondaryDamage
      ].filter(a => a).filter((a, index, data) => data.indexOf(a) === index);
    }

  },
  methods: {
    checkLotId: _.debounce(function (value) {
      this.$router.push({
        name: 'catalogue',
        query: {
          ...this.$route?.query,
          lotId: value
        }
      })
    }, 500),
    search() {
      this.$router.push({
        name: 'catalogue',
        query: {
          makers: this.filters.makers ?? [],
          models: this.filters.models ?? [],
          categories: this.filters.categories ?? [],

          years: [this.filters.years.from, this.filters.years.to],
          transmission: this.filters.transmission ?? [],
          price: [this.filters.price.min, this.filters.price.max],

          fuelType: this.filters.fuelType ?? [],
          engCapacity: this.filters.engCapacity ?? [],
          locations: this.filters.locations ?? [],

          damage: this.filters.damage ?? [],
          lotId: this.lotId ?? "",
          localCatalogue : this.catalogueType,
          odometr: [this.filters.odometr.min, this.filters.odometr.max],
        }
      })
    },
    clearCatalogue() {
      this.$router.push({
        name: 'catalogue',
      })
    },
    select(data) {
      let key = Object.keys(data)[0];
      this.filters[key] = data[key];
      if (key === 'makers') {
        let tmpModel = [];
        for (let i = 0; i < data.makers.length; i++) {
          tmpModel[data.makers[i]] = this.Prefilters.model[data.makers[i]]
        }
        if (data.makers.length === 0) {
          this.settings.model = {...this.Prefilters.model}
        } else {
          this.settings.model = tmpModel;
        }
        this.modelFilterKey++;

      }
    },
    selectPrice(data) {
      this.filters.price = data;
    },
    selectYears(data) {
      this.filters.years = data;
    },
    selectOdometr(data) {
      this.filters.odometr = data;
    }
  }
}
</script>

<template>
  <div class="filters py-10">
    <div class="bg-white max-w-[1600px] mx-auto px-10 py-5">
      <div class="flex-inline">
        <div>
          <label class="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" :value="'local'" class="sr-only peer" v-model="catalogueType">
            <div
                class="mb:w-11 mb:h-6 xs:w-[84px] xs:h-11 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full mb:after:h-5 mb:after:w-5 xs:after:h-10 xs:after:w-10 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>
        <div>
          <span>Use locale catalogue</span>
        </div>
      </div>
      <div class="flex justify-between items-center my-5">
        <div class="filters-text flex items-center">
          <div class="mr-5">
            {{ $t('catalogue-filter.adjustSetting') }}
          </div>
          <div class="group-input" v-if="!this.catalogueType">
            <input type="text" placeholder="Insert lot ID" v-model="lotId">
          </div>
        </div>
        <div class="clear-text cursor-pointer" @click="clearCatalogue">
          {{ $t('catalogue-filter.ClearAllFilters') }}
        </div>
      </div>
      <div class="grid xs:grid-cols-1 mb:grid-cols-2 xlg:grid-cols-3 gap-4" v-if="Object.keys(settings).length > 0">
        <drop-down :preSelected="selectedOvj.makers" type="list" name="Maker" returnKey="makers"
                   :options="settings.makers" @select="select"></drop-down>
        <drop-down :preSelected="selectedOvj.models" :key="modelFilterKey" type="key-list" name="Model"
                   returnKey="models" :options="settings.model" @select="select"></drop-down>
        <drop-down :preSelected="selectedOvj.years" type="years" name="Year" returnKey="years" :min="settings.year.min"
                   :max="settings.year.max" @select-years="selectYears"></drop-down>
        <drop-down :preSelected="selectedOvj.typeBody" type="list" name="Category" returnKey="categories"
                   :options="settings.category" @select="select"></drop-down>
        <drop-down :preSelected="selectedOvj.price" type="price" name="Price" returnKey="price"
                   :min="settings.price.min" :max="settings.price.max" @select-price="selectPrice"></drop-down>
        <drop-down :preSelected="selectedOvj.transmission" type="list" name="Transmission" returnKey="transmission"
                   :options="settings.transmission" @select="select"></drop-down>
      </div>
      <div v-else>
        <div class="car-block animate-pulse mb-5 grid xs:grid-cols-1 mb:grid-cols-2 xlg:grid-cols-3 gap-4">
          <div class="w-full h-[50px] bg-gray-200 mt-5 rounded-md dark:bg-gray-700"></div>
          <div class="w-full h-[50px] bg-gray-200 mt-5 rounded-md dark:bg-gray-700"></div>
          <div class="w-full h-[50px] bg-gray-200 mt-5 rounded-md dark:bg-gray-700"></div>
          <div class="w-full h-[50px] bg-gray-200 mt-5 rounded-md dark:bg-gray-700"></div>
          <div class="w-full h-[50px] bg-gray-200 mt-5 rounded-md dark:bg-gray-700"></div>
          <div class="w-full h-[50px] bg-gray-200 mt-5 rounded-md dark:bg-gray-700"></div>
        </div>
      </div>
      <Transition>
        <div v-show="additionalFilters">
          <div class="additional-filters mt-4 mb-2">
            Additional Filters
          </div>
          <div class="grid xs:grid-cols-1 mb:grid-cols-2 xlg:grid-cols-3 gap-4" v-if="Object.keys(settings).length > 0">
            <drop-down :preSelected="selectedOvj.fuelType" type="list" name="Fuel Type" returnKey="fuelType"
                       :options="settings.fuel_type" @select="select"></drop-down>
            <drop-down :preSelected="selectedOvj.engCapacity" type="list" name="Eng. Capacity" returnKey="engCapacity"
                       :options="settings.engine" @select="select"></drop-down>
            <drop-down :preSelected="selectedOvj.locations" type="list" name="Location" returnKey="locations"
                       :options="settings.location" @select="select"></drop-down>
            <drop-down :preSelected="selectedOvj?.damage" type="list" name="Damage Type" returnKey="damage"
                       :options="settings.damage" @select="select"></drop-down>
            <drop-down :preSelected="selectedOvj.odometr" type="two-number" name="Odometr" returnKey="odometr"
                       :min="settings.odometr.min" :max="settings.odometr.max"
                       @select-period="selectOdometr"></drop-down>
          </div>
        </div>
      </Transition>
      <div class="lg:flex justify-center items-center my-5 relative">
        <button class="btn btn-font btn-blue lg:w-[322px] xs:w-full" @click="search">
          {{ $t('catalogue-filter.Apply') }}
        </button>
        <div class="lg:absolute xs:relative flex justify-center items-center top-[5px] right-0 cursor-pointer"
             @click="additionalFilters = !additionalFilters">
          <div>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 11.5V5.5H13V11.5H19V13.5H13V19.5H11V13.5H5V11.5H11Z" fill="#256DFF"/>
            </svg>
          </div>
          <div class="blue-font blue-text">
            {{ $t('catalogue-filter.MoreFilters') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.clear-text {
  color: var(--Blue, #256DFF);
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
}

.filters-text {
  color: #000;
  font-family: TBCContractica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.filters {
  background: radial-gradient(60.35% 35.63% at 18.49% 83.36%, rgba(241, 177, 255, 0.20) 0%, rgba(247, 209, 255, 0.00) 100%), radial-gradient(75.54% 71.44% at 86.95% 38.85%, rgba(37, 109, 255, 0.20) 0%, rgba(37, 109, 255, 0.00) 100%), #EDEEFF;
  width: 100%;
}


</style>