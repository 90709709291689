<script>
import {freezeScroll, unfreezeScroll} from "@/helpers/helpers";
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import {client} from "@/axios/client";
export default {
  components:{VueTelInput},
  props: {
    dealer : {
      required : true,
      default : {}
    },
    show: {
      required : true,
      default : false
    }
  },
  data(){
    return {
      modal : true,
      country: {},
      validate: false,
      success: false,
      phone: ""
    }
  },
  mounted() {
    this.modal = this.show;
    freezeScroll();
  },
  methods : {
    create(){
      if(this.phone.length <= 11 || this.name.length === 0){
        this.validate = true;
      }else{
        this.validate = false
      }

      if(this.validate){
        return
      }
      client.post('api/request-a-call/call-with-dealer',{
        country : this.country,
        phone : this.phone,
        name : this.name,
        options : { dealerName : this.dealer.name}
      }).then(()=>{
        this.success = true;
        this.name = "";
        this.phone = "";
      });
    },
    closeModal(){
      this.modal = false;
      unfreezeScroll();
      setTimeout(()=>{
        this.$emit('close')
      },500)
    },
    countryChanged(country) {
      this.country = country;
    },
    changeNumber(number) {
      if (typeof number === 'string') {
        this.phone = number
      }
    },
  }
}
</script>

<template>
  <div :class="{
    'opened' : modal,
    'closed' : !modal
  }" class="fixed top-0 left-0 right-0 z-999 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%)] max-h-full flex justify-center items-center"
       id="request-call" >
    <div class="absolute top-0 left-0 right-0 z-999 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%)] max-h-full" @click="closeModal">

    </div>
    <div class="absolute w-full max-w-[640px] max-h-full ">
      <div class="w-full relative bg-black rounded-lg shadow py-[20px] px-[40px]">
        <div class=" w-full space-y-6">
          <div class="text-white header-dealer-popup text-center">
            Let's tell you how it works
          </div>


          <div class="mb-6 2xlg:w-12/12 xs:w-12/12 group-input">
            <label class="label block text-gray-700 text-sm font-bold mb-2" for="name" >
              {{ $t('dreamCar.name') }}
            </label>
            <input v-model="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" :placeholder="$t('dreamCar.name')" type="text" id="name">
            <!--            <p class="text-red-500 text-xs italic">Please choose a password.</p>-->
          </div>
          <div class="w-full">
            <div class="mb:flex items-center ">
              <div class="mb:w-full xs:w-full">
                <label class="label block text-gray-700 text-sm font-bold mb-2" for="phone">
                  {{ $t('dreamCar.phoneNumber') }}
                </label>
                <vue-tel-input
                    autoformat
                    mode="national"
                    :value="phone"
                    :inputOptions="{placeholder:$t('dreamCar.phoneNumber')}"
                    :disabledFormatting="false"
                    :placeholder="'Enter a phone number'"
                    @input="changeNumber"
                    v-on:country-changed="countryChanged"
                >
                </vue-tel-input>
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-blue btn-font w-full" @click="create">{{ $t('requestACallPopUp.btn') }}</button>
          </div>
          <div v-if="validate" class=" p-4 mb-4 mt-4 text-sm text-red-800 rounded-lg bg-red-50">
            Enter all fields
          </div>
          <div v-if="success" class=" p-4 mb-4 mt-4 text-sm text-green-800 rounded-lg bg-green-50">
            Request was been send! Await on call!
          </div>
          <div>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
#request-call>div{
  min-width: 520px;
}
.header-dealer-popup{
  color: #FFF;
  text-align: center;
  font-family: TBCContractica;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
}
.label{
  color: rgba(255, 255, 255, 0.70);
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 156.25% */
}
</style>
