<script>
import emitter from "@/eventBus/mitt";
import LangComponent from "@/components/lang.vue";

export default {
  name: "header-component",
  components: {LangComponent},
  data() {
    return {
      showMenu: false,
      testMode: true,
      vin: '',
      user: JSON.parse(window.sessionStorage?.user ?? '{}'),
      links: [
        {
          rName: "catalogue",
          rText: this.$t('header.catalogue')
        },
        {
          rName: "services",
          rText: this.$t('header.services')
        },
        {
          rName: "calculator",
          rText: this.$t('header.calculator')
        },
        {
          rName: "about-us",
          rText: this.$t('header.aboutUs')
        },
        {
          rName: "contact-us",
          rText: this.$t('header.contactUs')
        },
        {
          rName: "for-dealers",
          rText: this.$t('header.forDealer')
        }
      ]
    }
  },
  mounted() {
    emitter.on('change-lang', () => {
      this.links = [
        {
          rName: "catalogue",
          rText: this.$t('header.catalogue')
        },
        {
          rName: "services",
          rText: this.$t('header.services')
        },
        {
          rName: "calculator",
          rText: this.$t('header.calculator')
        },
        {
          rName: "about-us",
          rText: this.$t('header.aboutUs')
        },
        {
          rName: "contact-us",
          rText: this.$t('header.contactUs')
        },
        {
          rName: "for-dealers",
          rText: this.$t('header.forDealer')
        }
      ]
    })
    const header = this.$refs.header;
    let lastScrollTop = 0;
    let isHidden = false;
    emitter.on('login', () => {
      this.user = JSON.parse(window.sessionStorage?.user ?? '{}');
    })
    emitter.on('logout', () => {
      window.sessionStorage.removeItem('token');
      window.sessionStorage.removeItem('user');
      this.user = JSON.parse(window.sessionStorage?.user ?? '{}');
      this.$router.push({name: 'main'})
    })
    window.addEventListener('scroll', function () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop && !isHidden) {
        header.style.transition = 'transform 0.5s';
        header.style.transform = 'translateY(-100%)';
        isHidden = true;
      } else if (scrollTop < lastScrollTop && isHidden) {
        header.style.transition = 'transform 0.5s';
        header.style.transform = 'translateY(0)';
        isHidden = false;
      }

      lastScrollTop = scrollTop;
    });
  },
  methods: {
    searchVin() {
      if (this.vin.length === 17) {
        this.$router.push({
          name: 'vin-search',
          query: {
            vin: this.vin
          }
        })
      }

    },
    closeMenu() {
      this.showMenu = false;
    }
  }
}
</script>

<template>
  <header ref="header" id="header">

    <nav class="bg-white border-gray-200 ">
      <div
          class="main-menu flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-2 mb:pt-5 mb:pb-3">
        <div class="flex">
          <router-link class="router-link-active router-link-exact-active flex items-center mr-2" :to="{name:'main'}">
            <img width="219" class="mb:w-[219px] xs:w-[150px]" height="25"
                 :src="require('@/assets/logo.png')" alt=""
                 style="height: 45px; object-fit: contain;">
          </router-link>
          <lang-component @changeLang="initLang"/>
        </div>
        <div class="flex items-center">
          <router-link :to="{name:'vin-search'}">
            <div class="flex justify-center items-center lg:hidden">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1101_4940)">
                  <path
                      d="M10.999 2.5C15.967 2.5 19.999 6.532 19.999 11.5C19.999 16.468 15.967 20.5 10.999 20.5C6.03102 20.5 1.99902 16.468 1.99902 11.5C1.99902 6.532 6.03102 2.5 10.999 2.5ZM10.999 18.5C14.866 18.5 17.999 15.367 17.999 11.5C17.999 7.632 14.866 4.5 10.999 4.5C7.13102 4.5 3.99902 7.632 3.99902 11.5C3.99902 15.367 7.13102 18.5 10.999 18.5ZM19.484 18.571L22.313 21.399L20.898 22.814L18.07 19.985L19.484 18.571Z"
                      fill="#256DFF"></path>
                </g>
                <defs>
                  <clipPath id="clip0_1101_4940">
                    <rect width="24" height="24" fill="white"
                          transform="translate(-0.000976562 0.5)"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </router-link>

          <button @click="showMenu = !showMenu" data-collapse-toggle="mobile-menu-2" type="button"
                  class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden focus:outline-none"
                  aria-controls="mobile-menu-2" aria-expanded="false"><span class="sr-only">Open main menu</span>
            <svg v-if="!showMenu" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1143_2890)">
                <path d="M3 4H21V6H3V4ZM9 11H21V13H9V11ZM3 18H21V20H3V18Z" fill="black"></path>
              </g>
              <defs>
                <clipPath id="clip0_1143_2890">
                  <rect width="24" height="24" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
            <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_549_7700)">
                <path
                    d="M11.9997 10.5862L16.9497 5.63623L18.3637 7.05023L13.4137 12.0002L18.3637 16.9502L16.9497 18.3642L11.9997 13.4142L7.04974 18.3642L5.63574 16.9502L10.5857 12.0002L5.63574 7.05023L7.04974 5.63623L11.9997 10.5862Z"
                    fill="black"/>
              </g>
              <defs>
                <clipPath id="clip0_549_7700">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>

          </button>
        </div>
        <div class="justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
             id="mobile-menu-2">
          <div class="flex justify-center items-center mr-5 xs:hidden lg:flex">
            <div class="input-search-vin">
              <input type="text" placeholder="შეიყვანე VIN კოდი" v-model="vin">
            </div>
            <div class="search-button flex justify-center items-center" @click="searchVin">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1101_4940)">
                  <path
                      d="M10.999 2.5C15.967 2.5 19.999 6.532 19.999 11.5C19.999 16.468 15.967 20.5 10.999 20.5C6.03102 20.5 1.99902 16.468 1.99902 11.5C1.99902 6.532 6.03102 2.5 10.999 2.5ZM10.999 18.5C14.866 18.5 17.999 15.367 17.999 11.5C17.999 7.632 14.866 4.5 10.999 4.5C7.13102 4.5 3.99902 7.632 3.99902 11.5C3.99902 15.367 7.13102 18.5 10.999 18.5ZM19.484 18.571L22.313 21.399L20.898 22.814L18.07 19.985L19.484 18.571Z"
                      fill="#256DFF"></path>
                </g>
                <defs>
                  <clipPath id="clip0_1101_4940">
                    <rect width="24" height="24" fill="white" transform="translate(-0.000976562 0.5)"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="dealer-account flex xs:hidden lg:flex">
            <div>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1101_4947)">
                  <path
                      d="M3.99902 22.5C3.99902 20.3783 4.84188 18.3434 6.34217 16.8431C7.84246 15.3429 9.87729 14.5 11.999 14.5C14.1208 14.5 16.1556 15.3429 17.6559 16.8431C19.1562 18.3434 19.999 20.3783 19.999 22.5H3.99902ZM12.999 16.583V20.5H17.658C17.3006 19.4914 16.6802 18.5966 15.8612 17.908C15.0421 17.2193 14.054 16.7619 12.999 16.583ZM10.999 20.5V16.583C9.94402 16.7619 8.95593 17.2193 8.13687 17.908C7.31781 18.5966 6.6975 19.4914 6.34002 20.5H10.999ZM11.999 13.5C8.68402 13.5 5.99902 10.815 5.99902 7.5C5.99902 4.185 8.68402 1.5 11.999 1.5C15.314 1.5 17.999 4.185 17.999 7.5C17.999 10.815 15.314 13.5 11.999 13.5ZM11.999 11.5C14.209 11.5 15.999 9.71 15.999 7.5C15.999 5.29 14.209 3.5 11.999 3.5C9.78902 3.5 7.99902 5.29 7.99902 7.5C7.99902 9.71 9.78902 11.5 11.999 11.5Z"
                      fill="#256DFF"></path>
                </g>
                <defs>
                  <clipPath id="clip0_1101_4947">
                    <rect width="24" height="24" fill="white" transform="translate(-0.000976562 0.5)"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>

            <router-link class="blue-font" :to="{name: 'login'}" v-if="Object.keys(user).length === 0">
              {{ $t('header.login') }}
            </router-link>
            <router-link class="blue-font" :to="{name: 'profile.main'}" v-else>
              {{ user.name }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="w-full h[1px] border-b border-custom"></div>
      <div
          class="p-2 xs:hidden lg:flex second-row-menu mr-auto ml-auto flex justify-around items-center">
        <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
          <li v-for="(link,key) in links" class="link" :key="key">
            <router-link
                class="block py-2 pr-4 pl-3 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0"
                :to="{ name : link.rName}">
              {{ link.rText }}
            </router-link>
          </li>
          <li>
            <router-link
                class="flex py-2 pr-4 pl-3 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0"
                :to="{ name : 'favorite-car'}">
              <div class="mr-2">
                <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12 2.53659L14.9377 7.74859L15.1624 8.14729L15.6111 8.23782L21.4758 9.42114L17.4267 13.8256L17.1169 14.1626L17.1695 14.6172L17.8563 20.5606L12.4162 18.0707L12 17.8802L11.5838 18.0707L6.14366 20.5606L6.83054 14.6172L6.88309 14.1626L6.57334 13.8256L2.52424 9.42114L8.38894 8.23782L8.83757 8.14729L9.0623 7.74859L12 2.53659Z"
                      stroke="black" stroke-width="2"/>
                </svg>
              </div>
              <div>
                {{ $t('header.favorites') }}
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <div v-if="showMenu" class="flex second-row-menu flex h-[100vh] lg:justify-around xs:justify-start items-start">
        <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 py-3 lg:mt-0 w-full">
          <div class="dealer-account flex mx-5">
            <div class="mr-2 ml-5">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1101_4947)">
                  <path
                      d="M3.99902 22.5C3.99902 20.3783 4.84188 18.3434 6.34217 16.8431C7.84246 15.3429 9.87729 14.5 11.999 14.5C14.1208 14.5 16.1556 15.3429 17.6559 16.8431C19.1562 18.3434 19.999 20.3783 19.999 22.5H3.99902ZM12.999 16.583V20.5H17.658C17.3006 19.4914 16.6802 18.5966 15.8612 17.908C15.0421 17.2193 14.054 16.7619 12.999 16.583ZM10.999 20.5V16.583C9.94402 16.7619 8.95593 17.2193 8.13687 17.908C7.31781 18.5966 6.6975 19.4914 6.34002 20.5H10.999ZM11.999 13.5C8.68402 13.5 5.99902 10.815 5.99902 7.5C5.99902 4.185 8.68402 1.5 11.999 1.5C15.314 1.5 17.999 4.185 17.999 7.5C17.999 10.815 15.314 13.5 11.999 13.5ZM11.999 11.5C14.209 11.5 15.999 9.71 15.999 7.5C15.999 5.29 14.209 3.5 11.999 3.5C9.78902 3.5 7.99902 5.29 7.99902 7.5C7.99902 9.71 9.78902 11.5 11.999 11.5Z"
                      fill="#256DFF"></path>
                </g>
                <defs>
                  <clipPath id="clip0_1101_4947">
                    <rect width="24" height="24" fill="white" transform="translate(-0.000976562 0.5)"
                    ></rect>
                  </clipPath>
                </defs>
              </svg>

            </div>
            <router-link class="blue-font" :to="{name: 'login'}" v-if="Object.keys(user).length === 0">
              {{ $t('header.login') }}
            </router-link>
            <router-link class="blue-font" :to="{name: 'profile.main'}" v-else>
              {{ user.name }}
            </router-link>
          </div>
          <li></li>
          <li v-for="(link,key) in links" class="link" :key="key">
            <router-link
                @click="showMenu = !showMenu"
                class="block py-2 pr-4 pl-3 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0"
                :to="{ name : link.rName}">
              {{ link.rText }}
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<style scoped>
#header .main-menu {
  max-width: 1600px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 25px auto;

  max-width: 1600px;
}

.logo-wrapper {
  display: flex;
}

.links-wrapper a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-right: 40px;
}

a {
  font-family: "TBCContracticaCAPS";

}

#header {
  position: fixed;
  width: 100%;
  z-index: 999;
}

#header > .main-menu {
  max-width: 1600px;
}

.test-mode {
  background: #000;

  color: #FFF;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.blue-font, .router-link-active {
  color: var(--blue, #256dff);
  font-family: TBCContracticaCaps;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.router-link-active path {
  stroke: var(--blue, #256dff);
}

.input-search-vin input {
  border-radius: 4px 0 0 4px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  background: #f4f4f6;
  height: 44px;
  padding: 0 16px;
  width: 280px;
}

.search-button {
  border-radius: 0 4px 4px 0;
  border: 1px solid var(--blue, #256dff);
  height: 44px;
  padding: 0 16px;
  cursor: pointer;
  transition: .3s;
}

.search-button:hover {
  transition: .3s;
  background: #256dff;
}

.search-button:hover path {
  fill: #fff;
}

.hideHeader {
  transform: translateY(-100%)
}

.showHeader {
  transform: translateY(0)
}

@keyframes hideHeader {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes showHeader {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

a {
  text-decoration: none;
  color: #000;
  font-family: TBCContracticaCaps;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-right: 40px;
}
</style>
