<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | 4dealer` : `4dealer` }}</template>
  </metainfo>

    <customHeader></customHeader>
    <router-view></router-view>
    <customFooter></customFooter>
  <div id="modal"></div>
</template>

<script>
import customHeader from "@/components/header.vue";
import customFooter from "@/components/footer.vue";
import retina from 'retinajs';
import { useMeta } from 'vue-meta'
window.addEventListener('load', retina);
export default {
  name: 'App',
  setup () {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  components:{
    customHeader,
    customFooter
  }
}
</script>

<style>

body{
  background: #FAFCFF;
  margin: 0;
}
</style>
