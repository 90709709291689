<script>
  import comingSoon from "@/components/coming-soon.vue";

  export default {
    name : "payment-page",
    components: {comingSoon}
  }
</script>

<template>
<div>
  <coming-soon></coming-soon>
</div>
</template>

<style scoped>

</style>