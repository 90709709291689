<script>
import titleComponent from "@/components/title.vue";
import feedbackComponent from "@/components/feedback.vue";
import ourDealersComponent from "@/components/our-dealers.vue";
import calculatorComponent from "@/components/calculator.vue";
import BuyProcessComponent from "@/components/buy-process.vue";
import retroCarComponent from "@/components/retro-car.vue";
import faqComponent from "@/components/faq.vue";
import dreamCarComponent from "@/components/dream-car.vue";
import latestArticlesComponent from "@/components/latest-articles.vue";
import FilterMain from "@/components/filter-main.vue";
import NowPopular from "@/components/nowPopular.vue";
import {useMeta} from 'vue-meta'

export default {
  name: "Main-Page",
  setup() {
    useMeta({
      title: 'Main Page',
      description: 'ავტოიმპორტის 10 წლიანი გამოცდილება ამერიკის ავტოაუქციონებიდან',
      SITE_NAME: '4dealer',
    })
  },
  components: {
    NowPopular,
    FilterMain,
    BuyProcessComponent,
    titleComponent,
    feedbackComponent,
    ourDealersComponent,
    retroCarComponent,
    faqComponent,
    dreamCarComponent,
    calculatorComponent,
    latestArticlesComponent,
  },
  data() {
    return {}
  }
}
</script>

<template>
  <div>
    <title-component></title-component>
    <filter-main></filter-main>
    <now-popular></now-popular>
    <our-dealers-component></our-dealers-component>
    <calculator-component></calculator-component>
    <buy-process-component></buy-process-component>
    <retro-car-component></retro-car-component>
    <faq-component></faq-component>
    <dream-car-component></dream-car-component>
    <latest-articles-component></latest-articles-component>
    <feedback-component></feedback-component>
  </div>
</template>

<style scoped>

</style>
