<script>
  import requestCallComponent from "@/modals/request-call.vue";

  export default {
    name : "coming-soon",
    components: {requestCallComponent},
    data(){
      return {
        requestCall : false
      }
    }
  }
</script>

<template>
  <div id="coming-soon" class="coming-soon page">
      <div class="flex justify-center xs:p-5 mb:p-10" style="flex-direction: column">
        <div class="back-to-main mb-5">
          <router-link class="flex justify-start items-center" :to="{name : 'main'}">
            <div>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_990_4951)">
                  <path d="M10.828 12.5002L15.778 17.4502L14.364 18.8642L8 12.5002L14.364 6.13623L15.778 7.55023L10.828 12.5002Z" fill="#256DFF"/>
                </g>
                <defs>
                  <clipPath id="clip0_990_4951">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>
              {{ $t('commingSoon.backToMain') }}
            </div>
          </router-link>
        </div>
        <div class="header-coming-soon mb-10">
          {{ $t('commingSoon.header') }}...
        </div>
        <div class="coming-soon-helper mb-10" v-html="$t('commingSoon.mainText')">
        </div>
        <div>
          <button class="btn btn-font btn-blue" @click="requestCall = true">{{ $t('commingSoon.btn') }}</button>
        </div>
      </div>
    <div class="modal" v-if="requestCall">
      <request-call-component :show="requestCall" :backEnd="'coming-soon-functional-'+$router.currentRoute.value.name" @close="requestCall = false"></request-call-component>
    </div>
  </div>
</template>

<style scoped>
.coming-soon-helper{
  max-width: 430px;
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
.blue-text{
  color: var(--blue, #256DFF);
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
.header-coming-soon{
  color: #000;
  font-family: TBCContracticaCAPS;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 69px;
}
.back-to-main{
  color: var(--blue, #256DFF);
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
.coming-soon{
  background: radial-gradient(66.50% 49.16% at 86.12% 54.56%, rgba(69, 147, 255, 0.20) 0%, rgba(65, 134, 255, 0.00) 100%), radial-gradient(42.45% 37.16% at 16.64% 74.47%, rgba(37, 109, 255, 0.19) 0%, rgba(37, 109, 255, 0.00) 100%), #F4F2FE;
  height: 100vh;
}
</style>
