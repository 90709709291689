<script >
import AboutUsTitle from "@/components/about-us-title.vue";
import CoreValue from "@/components/core-value.vue";
import OurDealers from "@/components/our-dealers.vue";
import {useMeta} from "vue-meta";

export default {
  components: {OurDealers, CoreValue, AboutUsTitle},
  setup() {
    useMeta({
      title: 'About Us Page',
      description: '4Dealer შეგარჩევინებს, გაყიდინებს და სახლის კართან ჩამოგიყვანს შენი ოცნების მანქანას',
      SITE_NAME: '4dealer',
    })
  },
}
</script>

<template>
<div class="page">
  <about-us-title></about-us-title>
  <core-value></core-value>
  <our-dealers></our-dealers>
</div>
</template>

<style scoped>

</style>
