<script>

import {client} from "@/axios/client";

export default {
  name: "blog-page",
  data() {
    return {
      articles: [],
    }
  },
  mounted() {
    this.getArticles();
  },
  methods: {
    async getArticles() {
      const {data} = await client.get('api/articles');
      this.articles = data
    }
  }
}
</script>

<template>
  <div class="blog page max-w-[1600px] mx-auto xs:px-5 mb:px-2 2xlg:px-0" id="blog">
    <div class="blog-header my-10"></div>
    <div class="grid mb:grid-cols-3 xs:grid-cols-1 gap-4 my-10">
      <router-link class="article-preview" v-for="(article, index) in articles.data" :key="index"
                   :to="{name : 'article',params : {id : article.id}}">
        <div class="w-full overflow-hidden">
          <img class="w-full" :src="article.img_store" alt="Article Img"></div>
        <div class="p-5">
          <div class="article-categories mb-5">{{ article.theme }}</div>
          <div class="article-header mb-5">
            {{ article.header }}
          </div>
          <div class="article-preview-text mb-5">
            {{ article.description }}
          </div>
          <div class="h-[20px]"><span class="reading-time"> Reading Time: </span> <span
              class="time">{{
              article.reading_time
            }} minutes</span></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style scoped>

</style>
