<script>

  import CalculatorComponent from "@/components/calculator.vue";
  import OurDealers from "@/components/our-dealers.vue";
  import {useMeta} from "vue-meta";

  export default {
    name : "calculator-page",
    components: {OurDealers, CalculatorComponent},
    setup() {
      useMeta({
        title: 'Calculator Page',
        description: 'გამოთვალე შენი სასურველი ავტომობილის დეტალური ღირებულება',
        SITE_NAME: '4dealer',
      })
    },
  }
</script>

<template>
<div class="page">
  <calculator-component></calculator-component>
  <our-dealers></our-dealers>
</div>
</template>

<style scoped>

</style>
