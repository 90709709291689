<script>
  import FaqComponent from "@/components/faq.vue";

export default {
  name : "faq-page",
  components: {FaqComponent}
}
</script>

<template>
<div class="page">
  <faq-component></faq-component>
</div>
</template>

<style scoped>

</style>
