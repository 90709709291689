<script>
import comingSoon from "@/components/coming-soon.vue";

export default {
  name : "privacy-policy-page",
  components: {comingSoon}
}
</script>

<template>
<div>
  <coming-soon></coming-soon>
</div>
</template>

<style scoped>

</style>