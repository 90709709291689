<script>
import {client} from "@/axios/client";
import {VueTelInput} from 'vue3-tel-input'
import {VueEasyLightbox} from 'vue-easy-lightbox'

// you need to import css yourself
import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'
import {ref} from "vue";
export default {
  components: {VueTelInput,VueEasyLightbox},
  data() {
    return {
      vin: '',
      phone: "",
      loader: false,
      searchInfo: {},
      photos: [],
      countShow: 11,
    }
  },
  mounted() {
    this.getVin();
    if (this.vin.length === 17) {
      this.searchByVin();
    }
  },
  setup() {
    const visibleRef = ref(false)
    const indexRef = ref(0)
    const showImg = (index) => {
      indexRef.value = index
      visibleRef.value = true
    }
    const onHide = () => visibleRef.value = false
    return {
      visibleRef,
      indexRef,
      showImg,
      onHide
    }
  },
  methods: {
    countryChanged(country) {
      this.country = country;
    },
    changeNumber(number) {
      if (typeof number === 'string') {
        this.phone = number
      }
    },
    getVin() {
      this.vin = this.$route?.query?.vin || "";
    },
    async searchByVin() {
      if (this.vin.length === 17) {
        this.loader = true;
        const {data} = await client.get('api/get-ocean/' + this.vin);
        this.searchInfo = data;
        this.loader = false;
        this.photos = data.photos.map((value) => 'https://cdn.liontrans.com/' + value.fileName);
      }
    },
    showOther() {
      if (this.countShow === 11) {
        this.countShow = this.photos.length;
      } else {
        this.countShow = 11
      }
    },
  }
}
</script>

<template>
  <div class="page vin-code-tracking page" id="vin-code-tracking">
    <div class="vin-code-tracking-wrapper  h-[calc(100%)]]">
      <div class="max-w-[1600px] mr-auto ml-auto mb:pt-20 mb:pb-5 xs:px-2">
        <div class="vin-code-tracking-header mb-8">შეიყვანე VIN კოდი</div>
        <div class="mb:grid grid-cols-2 gap-4 mb:w-7/12 xs:w-full">
          <div class="group-input mb:flex items-center">
            <input
                class="h-[65px] xs:mb-5 xs:w-full flex appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                type="text" placeholder="685436843684" v-model="vin"></div>
          <div class="h-full xs:mb-5">
            <button class="btn btn-blue btn-font h-full xs:w-full" @click="searchByVin">ძიება</button>
          </div>
        </div>
      </div>
      <div class=" mr-auto ml-auto mb:px-10 xs:px-2 mb:pb-20 xs:pb-10"
           v-if="Object.keys(searchInfo).length === 0 && !loader">
        <div class="not-have-vincode-header mb-10">შედეგი არ მოიძებნა</div>
        <div>
          <div class="not-have-vincode-subheader mb-4">როგორ მოვიქცე?</div>
          <div>
            <div class="not-have-vincode-simple-text mb-2"> - გადაამოწმე VIN კოდი</div>
            <div class="not-have-vincode-simple-text mb-2"> - ხელახლა სცადე VIN კოდის შეყვანა</div>
            <div class="not-have-vincode-simple-text"> - დაგვიკავშირდი</div>
          </div>
        </div>
      </div>
      <div v-if="Object.keys(searchInfo).length > 0 && !loader">

        <div
            class="max-w-[1600px] xs:px-5 mr-auto ml-auto xs:px-2">
          <div class="grid mr-auto ml-auto ">
            <div class="search-title mb-5">
              {{ searchInfo.manufacturer }} | {{ searchInfo.model }} | {{ searchInfo.year }}
            </div>
          </div>
          <div class="grid 2xlg:grid-cols-6 xlg:grid-cols-4 mb:grid-cols-2 xs:grid-cols-1 gap-4">
            <div class="h-[190px] w-full" v-for="(img,key) in photos" :class="{
            hidden : countShow < key
          }" :key="key" @click="() => showImg(key)">
              <img class="h-[190px] object-cover w-full" :src="img" alt="">
            </div>
          </div>
          <vueEasyLightbox :visible="visibleRef" :imgs="photos" :index="indexRef" @hide="onHide"></vueEasyLightbox>
          <div @click="showOther()" class="mt-5 show-origin mr-auto ml-auto mb:px-10 xs:px-2 flex justify-between py-5">
            <div class="originShow">
              {{ $t('vinSearch.origin') }}
            </div>
            <div :class="{
              'rotate-180' : countShow !== 11
            }">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_967_12151)">
                  <path
                      d="M11.9993 13.1717L16.9493 8.22168L18.3633 9.63568L11.9993 15.9997L5.63528 9.63568L7.04928 8.22168L11.9993 13.1717Z"
                      fill="black"/>
                </g>
                <defs>
                  <clipPath id="clip0_967_12151">
                    <rect width="24" height="24" fill="white" transform="translate(24) rotate(90)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <a v-if="searchInfo.zip" :href="searchInfo.zip" class="originShow mt-5 show-origin mr-auto ml-auto mb:px-10 xs:px-2 flex justify-between py-5">
            გადმოწერა
          </a>
        </div>


        <div class="w-full bg-white my-10">
          <div class="max-w-[1600px] mx-auto xs:px-5 grid xs:grid-cols-1 lg:grid-cols-2 py-10">
            <div>
              <h2 class="details-header mb-5">Car details</h2>
              <div class="grid mb:grid-cols-2 xs:grid-cols-1">
                <div>
                  <div class="space-x-2 mb-2">
                    <span class="title">VIN:</span><span>{{ searchInfo.vin }}</span>
                  </div>
                  <div class="space-x-2 mb-2">
                    <span class="title">Manufacture:</span><span>{{ searchInfo.manufacturer }}</span>
                  </div>
                  <div class="space-x-2 mb-2">
                    <span class="title">Model:</span><span>{{ searchInfo.model }}</span>
                  </div>
                  <div class="space-x-2 mb-2">
                    <span class="title">Release year:</span><span>{{ searchInfo.containerEntryDate }}</span>
                  </div>
                  <div class="space-x-2 mb-2">
                    <span class="title">Title:</span><span>{{ searchInfo.titleNumber }}</span>
                  </div>
                  <div class="space-x-2 mb-2">
                    <span class="title">Vehicle status:</span><span v-if="searchInfo.containerNumber.length > 0">In Container</span>
                  </div>
                </div>
                <div>
                  <div class="space-x-2 mb-2">
                    <span class="title">Point of delivery:</span><span>{{ searchInfo.whereComes }}</span>
                  </div>
                  <div class="space-x-2 mb-2">
                    <span class="title">Shipping line:</span><span>{{ searchInfo.containerLine }}</span>
                  </div>
                  <div class="space-x-2 mb-2">
                    <span class="title">Ship name:</span><span>{{ searchInfo.shipName }}</span>
                  </div>
                  <div class="space-x-2 mb-2">
                    <span class="title">Container Number:</span><span>{{ searchInfo.containerNumber }}</span>
                  </div>
                  <div class="space-x-2 mb-2">
                    <span class="title">Container Open Date:</span><span>{{ searchInfo.containerEntryDate }}</span>
                  </div>
                  <div class="space-x-2 mb-2">
                    <span class="title">Additional info:</span><span>{{ searchInfo.bookingNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h2 class="details-header mb-5">Subscribe SMS info</h2>
              <div class="mb:flex items-center ">
                <div class="mb:w-8/12 xs:w-full mr-5">
                  <vue-tel-input
                      autoformat
                      mode="national"
                      :inputOptions="{placeholder:$t('dreamCar.phoneNumber')}"
                      :disabledFormatting="false"
                      :placeholder="'Enter a phone number'"
                      @input="changeNumber"
                      v-on:country-changed="countryChanged"
                  >
                  </vue-tel-input>
                </div>
                <div class="mb:w-3/12 flex items-center">
                  <button class="btn btn-blue btn-font xs:w-full xs:mt-5 mb:mt-0">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center py-20 flex-wrap" v-if="loader">
        <div class="lds-ripple w-full">
          <div></div>
          <div></div>
        </div>
        <div class="w-full lt flex justify-center">
          loading...
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.lt {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.title {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 156.25% */
}

.details-header {
  color: #000;
  font-family: TBCContracticaCaps;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
}

.vin-code-tracking {
  background: #f4f4f6
}

.vin-code-tracking-header {
  color: #000;
  font-family: TBCContracticaCaps;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px
}

.car-name[data-v-15665b66], .header-mini {
  color: #000;
  font-family: TBCContractica;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px
}

.title-type {
  font-weight: 700
}

.title-text[data-v-15665b66], .title-type {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  line-height: 25px
}

.title-text {
  font-weight: 400
}

.not-have-vincode-header {
  color: #000;
  font-family: TBCContracticaCaps;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px
}

.not-have-vincode-subheader {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px
}

.not-have-vincode-simple-text {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px
}

.search-title {
  color: #000;
  font-family: TBCContractica;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 128.571% */
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.show-origin {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 17, 51, 0.15);
}
.originShow{
  color: #000;
  font-family: TBCContractica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}
</style>
