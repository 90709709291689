<script >
import ServiceTitle from "@/components/service-title.vue";
import ServicesVideos from "@/components/services-videos.vue";
import OurDealers from "@/components/our-dealers.vue";
import DreamCarComponent from "@/components/dream-car.vue";
import {useMeta} from "vue-meta";

export default {
  name : "services-page",
  setup() {
    useMeta({
      title: 'Services Page',
      description: 'ჩვენი სერვისები',
      SITE_NAME: '4dealer',
    })
  },
  components: {DreamCarComponent, OurDealers, ServicesVideos, ServiceTitle}
}
</script>

<template>
  <div class="page">
    <service-title></service-title>
    <services-videos></services-videos>
    <our-dealers></our-dealers>
    <dream-car-component></dream-car-component>
  </div>
</template>

<style scoped>

</style>
