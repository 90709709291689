<script>
export default {
  name: "steps-for-dealer"
}
</script>

<template>
  <div class="buy-process xs:my-5 mb:mx-auto 2xlg:pr-0 2xlg:pl-0 xs:p-5" id="buy-procces ">
    <div class="buy-process-header lg:mt-20"><h2 data-v-894425f0="">{{ $t('howStartWorking.header') }}</h2></div>
    <div class="mb:pt-10 mb:pb-10 2xlg:pl-0 2xlg:pr-0 xs:p-2">
      <div class="timeline-area">
        <div class="container">
          <div class="all-timelines">
            <div class="relative single-timeline d-flex-2">
              <div class="isOdd timeline-text d-flex-2">
                <div class="process pl-11 pr-11 pt-7 pb-7 isOdd">
                  <div class="flex justify-center items-center circle circle-font">1</div>
                  <div class="arrow-right">
                    <svg width="20" height="33" viewBox="0 0 20 33" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.500002 1.53646L18.7124 16.5L0.5 31.4635L0.500002 1.53646Z"
                            fill="white" stroke="#EDE2FF"></path>
                    </svg>
                  </div>
                  <h6 class="process-header flex relative"><span class="absolute"><img
                      src="/img/fi_phone.0bad0676.svg" alt=""></span> {{ $t('howStartWorking.title1') }}</h6></div>
              </div>
              <div class="timeline-blank"></div>
            </div>
            <div class="relative single-timeline d-flex-2">
              <div class="timeline-text d-flex-2">
                <div class="process pl-11 pr-11 pt-7 pb-7">
                  <div class="flex justify-center items-center circle circle-font">2</div>
                  <div class="arrow-left">
                    <svg width="20" height="34" viewBox="0 0 20 34" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.5 2.03646L1.28762 17L19.5 31.9635L19.5 2.03646Z" fill="white"
                            stroke="#EDE2FF"></path>
                    </svg>
                  </div>
                  <h6 class="process-header flex relative"><span class="absolute"><img
                      src="/img/people.82af941c.svg" alt=""></span> {{ $t('howStartWorking.title2') }}</h6></div>
              </div>
              <div class="timeline-blank"></div>
            </div>
            <div class="relative single-timeline d-flex-2">
              <div class="isOdd timeline-text d-flex-2">
                <div class="process pl-11 pr-11 pt-7 pb-7 isOdd">
                  <div class="flex justify-center items-center circle circle-font">3</div>
                  <div class="arrow-right">
                    <svg width="20" height="33" viewBox="0 0 20 33" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.500002 1.53646L18.7124 16.5L0.5 31.4635L0.500002 1.53646Z"
                            fill="white" stroke="#EDE2FF"></path>
                    </svg>
                  </div>
                  <h6 class="process-header flex relative"><span class="absolute"><img
                      src="/img/man-check.fe50d765.svg" alt=""></span> {{ $t('howStartWorking.title3') }}</h6></div>
              </div>
              <div class="timeline-blank"></div>
            </div>
            <div class="relative single-timeline d-flex-2">
              <div class="timeline-text d-flex-2">
                <div class="process pl-11 pr-11 pt-7 pb-7">
                  <div class="flex justify-center items-center circle circle-font">4</div>
                  <div class="arrow-left">
                    <svg width="20" height="34" viewBox="0 0 20 34" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.5 2.03646L1.28762 17L19.5 31.9635L19.5 2.03646Z" fill="white"
                            stroke="#EDE2FF"></path>
                    </svg>
                  </div>
                  <h6 class="process-header flex relative"><span class="absolute"><img
                      src="/img/achive.f4fd4dcc.svg" alt=""></span> {{ $t('howStartWorking.title4') }}</h6></div>
              </div>
              <div class="timeline-blank"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
