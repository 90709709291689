<script>
import {freezeScroll, isMobileResolution, unfreezeScroll} from "@/helpers/helpers";

export default {
  name: "drop-down-model",
  props: {
    options: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      search: "",
      open: false,
      isMobile: isMobileResolution(),
      models: [],
      selected: [],
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
    }
  },
  async mounted() {
    this.models = this.filterEmptyArrays(this.options);
  },
  watch: {
    selected() {
      this.$emit('update', this.selected);
    },
    search() {
      this.models = this.filterByKeyword(this.search, this.options)
    }
  },
  methods: {
    filterByKeyword(keyword, data) {
      const filteredData = {};
      for (const key in data) {
        const values = data[key].filter(value => value.toLowerCase().includes(keyword.toLowerCase()));
        if (values.length > 0) {
          filteredData[key] = values;
        }
      }
      return filteredData;
    },
    filterEmptyArrays(obj) {
      var result = {};
      for (var key in obj) {
        if (Array.isArray(obj[key]) && obj[key].length === 0) {
          continue;
        }
        if (typeof obj[key] === 'object') {
          result[key] = this.filterEmptyArrays(obj[key]);
        } else {
          result[key] = obj[key];
        }
      }
      return result;
    },
    startSwipe(event) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    }
    ,
    trackSwipe(event) {
      this.endX = event.touches[0].clientX;
      this.endY = event.touches[0].clientY;
    }
    ,
    endSwipe() {
      const deltaX = this.endX - this.startX;
      const deltaY = this.endY - this.startY;

      if (Math.abs(deltaY) > Math.abs(deltaX)) {
        if (deltaY > 0) {
          this.open = false;
          unfreezeScroll()
        } else {
          this.open = false;
          unfreezeScroll()
        }
      }

      // Сбрасываем координаты
      this.startX = 0;
      this.startY = 0;
      this.endX = 0;
      this.endY = 0;
    }
    ,
    selectByImage(key) {
      const index = this.selected.indexOf(key);
      if (index !== -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(key);
      }
    }
    ,
    openModal() {
      this.open = true;
      if (this.isMobile) {
        freezeScroll();
      }
    },
    clearModel(){
      this.selected = [];
      this.$emit('update', this.selected);
    },
    capitalCase(inputString) {
      let words = inputString.split(' ');
      let outputWords = words.map(word => word.charAt(0) + word.slice(1).toLowerCase());
      return outputWords.join(' ');
    },
    closeModal() {
      if (this.isMobile) {
        this.search = ""
        unfreezeScroll();
      } else {
        this.search = ""
        this.open = false
      }
    }
  }
}
</script>

<template>
  <div class="model-dropdown lg:relative flex items-center xlg:w-3/12 xs:w-full" id="model-dropdown"
       v-click-outside="closeModal">
    <div @click="openModal" class="model-dropdown-header flex items-center cursor-pointer">
      <div class="flex mr-2 items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_781_21126)">
            <path
                d="M19 20H5V21C5 21.2652 4.89464 21.5196 4.70711 21.7071C4.51957 21.8946 4.26522 22 4 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V13.5L0.757 13.19C0.540753 13.1358 0.348809 13.011 0.211655 12.8352C0.0745022 12.6595 5.86043e-06 12.4429 0 12.22L0 11.5C0 11.3674 0.0526784 11.2402 0.146447 11.1464C0.240215 11.0527 0.367392 11 0.5 11H2L4.48 5.212C4.63432 4.852 4.89096 4.54524 5.21805 4.32978C5.54515 4.11432 5.92832 3.99965 6.32 4H17.68C18.0713 4.00004 18.4541 4.1149 18.7808 4.33033C19.1075 4.54577 19.3638 4.85231 19.518 5.212L22 11H23.5C23.6326 11 23.7598 11.0527 23.8536 11.1464C23.9473 11.2402 24 11.3674 24 11.5V12.22C24 12.4429 23.9255 12.6595 23.7883 12.8352C23.6512 13.011 23.4592 13.1358 23.243 13.19L22 13.5V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H20C19.7348 22 19.4804 21.8946 19.2929 21.7071C19.1054 21.5196 19 21.2652 19 21V20ZM20 18V13H4V18H20ZM5.477 11H18.523C18.6863 10.9999 18.8471 10.9598 18.9914 10.8832C19.1357 10.8066 19.2589 10.6958 19.3505 10.5605C19.442 10.4253 19.4991 10.2697 19.5166 10.1073C19.5341 9.94488 19.5116 9.78067 19.451 9.629L18 6H6L4.549 9.629C4.4884 9.78067 4.46588 9.94488 4.4834 10.1073C4.50092 10.2697 4.55795 10.4253 4.64951 10.5605C4.74106 10.6958 4.86435 10.8066 5.0086 10.8832C5.15285 10.9598 5.31367 10.9999 5.477 11ZM5 14C7.317 14 8.879 14.755 9.686 16.264C9.72679 16.3402 9.74714 16.4257 9.74506 16.5121C9.74297 16.5985 9.71852 16.6829 9.6741 16.757C9.62968 16.8311 9.5668 16.8925 9.49159 16.9351C9.41639 16.9777 9.33143 17 9.245 17H6C5.73478 17 5.48043 16.8946 5.29289 16.7071C5.10536 16.5196 5 16.2652 5 16V14ZM19 14V16C19 16.2652 18.8946 16.5196 18.7071 16.7071C18.5196 16.8946 18.2652 17 18 17H14.755C14.6687 16.9999 14.5838 16.9774 14.5088 16.9347C14.4337 16.892 14.3709 16.8307 14.3266 16.7566C14.2823 16.6825 14.258 16.5981 14.2559 16.5118C14.2539 16.4255 14.2742 16.3401 14.315 16.264C15.12 14.754 16.682 14 19 14Z"
                fill="#707070"></path>
          </g>
          <defs>
            <clipPath id="clip0_781_21126">
              <rect width="24" height="24" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="flex items-center selectedOptions">
        <div v-if="selected.length>0" class="flex relative">
          <div class="w-11/12 overflow-hidden">
            {{ capitalCase(selected.join(',')) }}
          </div>
          <div class="absolute right-0 top-[6px]" @click="clearModel">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.43236 5.08623L11.3824 0.13623L12.7964 1.55023L7.84636 6.50023L12.7964 11.4502L11.3824 12.8642L6.43236 7.91423L1.48236 12.8642L0.0683594 11.4502L5.01836 6.50023L0.0683594 1.55023L1.48236 0.13623L6.43236 5.08623Z" fill="black"/>
            </svg>
          </div>
        </div>
        <p v-else>{{ $t('mainFilter.model') }}</p>
      </div>
    </div>
    <teleport to="#modal" :disabled="!isMobile" v-if="open">
      <div class="model-list-wrapper">
        <div @touchstart="startSwipe"
             @touchmove="trackSwipe"
             @touchend="endSwipe" id="swiper" v-if="isMobile" class="mobile flex justify-center items-center h-[40px]">
          <svg width="107" height="3" viewBox="0 0 107 3" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <rect width="107" height="3" rx="1.5" fill="#B6B6B6"></rect>
          </svg>
        </div>
        <div class=" pl-8 pr-8 pt-2">
          <div>
            <input type="text" id="first_name"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                   placeholder="Search" v-model="search">
          </div>
          <div class="model-list pt-2">
            <div class="items-center" v-for="(models,maker) in models" :key="maker">
              <div class="model-header [:not(:last-child)]:mt-5 mb-3">{{ maker }}</div>
              <div class="form-checkbox option mb-2"  v-for="(model,key) in models" :key="key">
                <input type="checkbox" class="mr-5" :id="model" :value="model" v-model="selected">
                <label class="model-option capitalize" :class="{
            selected : selected.includes(model)
          }" :for="model">{{ capitalCase(model.replaceAll('-', ' ')) }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

    </teleport>
  </div>

</template>

<style scoped>
.model-option {
  transition: .5s
}

.option:hover .model-option {
  transition: .5s;
  color: var(--Blue, #256dff)
}

.model-option.selected {
  color: var(--Blue, #256dff)
}

.model-dropdown {
  height: 61px
}

@media screen and (min-width: 1000px) {
  .model-dropdown {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9
  }

  .model-dropdown-header {
    border-right: 1px solid #c1c1c1
  }

  .model-list-wrapper {
    top: 70px
  }

  .model-list {
    height: 300px
  }
}

.apply-btn {
  border-radius: 0 0 4px 4px;
  background: #fff
}

.model-list-wrapper {
  border-radius: 4px;
  background: #fafafa;
  position: absolute;
  width: 100%;
  top: 70px;
  z-index: 9999
}

.model-option {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;

  line-height: 25px
}


.model-header {
  color: #000;
  font-weight: 700
}

.model-dropdown-header, .model-header {
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  line-height: 25px
}

.model-dropdown-header {
  color: #383838;
  font-weight: 500;
  width: -webkit-fill-available;
  height: 50px;
  padding: 0 16px
}

.model-option:first-letter {
  text-transform: uppercase
}

.apply-btn.mobile {
  position: absolute;
  bottom: 40px
}

.model-list-mobile.model-list {
  padding-bottom: 140px
}

@media screen and (min-width: 320px) {
  .model-list-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    transform: matrix()
  }

  .model-list {
    height: 100vh
  }
}

@media screen and (min-width: 1000px) {
  .model-dropdown-header {
    border-right: 1px solid #c1c1c1
  }

  .model-list-wrapper {
    position: absolute;
    top: 70px
  }

  .model-list {
    height: 300px
  }
}
</style>
