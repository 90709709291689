<script>
import {defineComponent} from 'vue'
import ComingSoon from "@/components/coming-soon.vue";

export default defineComponent({
  name: "AuctionComponent",
  components: {ComingSoon}
})
</script>

<template>
  <div>
    <coming-soon></coming-soon>
  </div>
</template>

<style scoped>

</style>