<script>
export default{
  name : 'car-block',
  props: {
    car : {
      required: true,
      type: Object
    }
  },
  data(){
    return {
      favorites : []
    }
  },
  mounted() {
    this.favorites = JSON.parse(window.localStorage.getItem('favorite')) ?? [];
  },
  methods: {
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    addToFavorite(lotId){
      let favorites = JSON.parse(window.localStorage.getItem('favorite')) ?? [];

      const index = favorites.indexOf(lotId);
      if (index === -1) {
        favorites.push(lotId);
      } else {
        favorites.splice(index, 1);
      }
      this.favorites = favorites
      console.log(favorites)
      window.localStorage.setItem('favorite', JSON.stringify(favorites))
    }
  }
}
</script>

<template>
  <div class="car-block relative mb-4 w-full">
    <div class="absolute z-50 top-[15px] right-0 pl-5 pr-5 flex justify-center items-center" @click="addToFavorite(car?.lotId ?? car?.id)">
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!favorites.includes(car?.lotId  ?? car?.id)">
        <rect width="44" height="44" rx="22" fill="white"/>
        <path d="M22 10L25.8794 16.6605L33.4127 18.2918L28.277 24.0395L29.0534 31.7082L22 28.6L14.9466 31.7082L15.723 24.0395L10.5873 18.2918L18.1206 16.6605L22 10Z" fill="#BDBDBD"/>
      </svg>

      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
        <rect width="44" height="44" rx="22" fill="white"/>
        <path d="M22 10L25.8794 16.6605L33.4127 18.2918L28.277 24.0395L29.0534 31.7082L22 28.6L14.9466 31.7082L15.723 24.0395L10.5873 18.2918L18.1206 16.6605L22 10Z" fill="#FDB827"/>
      </svg>
    </div>
  <router-link v-if="car?.lotId" :to="{
        'name' : 'lotById',
        'params' : {
          'lotId' :  car?.lotId
        }
      }" class="w-full">
    <div class="flex-shrink-0 preview-popular overflow-hidden relative h-[273px]">
      <img
          class="w-full h-[100%] block bg-gray-200 dark:bg-gray-700 object-cover"
          :src="(car.lotId ?JSON.parse(car.images)[0] : 'https://back-end.4dealer.co/'+car.images[0].path) ">
      <div class="ready-to-buy pl-5 pr-5 flex justify-center items-center" v-if="car.buyNowPrice && (car.buyNowPrice) > 0">
        <div class="mr-2">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.95 14.1246C16.9289 13.1333 17.5955 11.8704 17.8656 10.4956C18.1356 9.12075 17.9969 7.69569 17.4671 6.40064C16.9373 5.10558 16.04 3.99868 14.8889 3.21991C13.7378 2.44114 12.3844 2.02548 11 2.02548C9.61557 2.02548 8.26222 2.44114 7.11109 3.21991C5.95996 3.99868 5.06275 5.10558 4.53292 6.40064C4.00308 7.69569 3.86442 9.12075 4.13445 10.4956C4.40449 11.8704 5.07111 13.1333 6.05 14.1246L11 19.1366L15.95 14.1246ZM11 22L4.636 15.5563C3.37734 14.2819 2.52019 12.6581 2.17293 10.8905C1.82567 9.12277 2.00391 7.29051 2.6851 5.62539C3.36629 3.96027 4.51984 2.53707 5.99988 1.53576C7.47992 0.534448 9.21998 0 11 0C12.78 0 14.5201 0.534448 16.0001 1.53576C17.4802 2.53707 18.6337 3.96027 19.3149 5.62539C19.9961 7.29051 20.1743 9.12277 19.8271 10.8905C19.4798 12.6581 18.6227 14.2819 17.364 15.5563L11 22ZM12 9.11262H16V11.1377H10V4.05001H12V9.11262Z"
                fill="#5D9C59"></path>
          </svg>
        </div>
        <div>Buy Now ( $ {{ car.buyNowPrice }} )</div>
      </div>
    </div>
    <div class="w-full xs:p-4"><h3 class="car-title h-[60px]">
      {{ car.maker }} {{ car.model }} {{ car.year }} </h3>
      <div>
        <div class="h-[20px]">
          <hr>
        </div>
        <div class="flex justify-between car-price items-center">
          <div class="flex items-center justify-center">
            <div class="mr-2">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1297_5677)">
                  <path
                      d="M11.9997 3C17.3917 3 21.8777 6.88 22.8187 12C21.8787 17.12 17.3917 21 11.9997 21C6.60766 21 2.12166 17.12 1.18066 12C2.12066 6.88 6.60766 3 11.9997 3ZM11.9997 19C14.0391 18.9996 16.0181 18.3068 17.6125 17.0352C19.207 15.7635 20.3226 13.9883 20.7767 12C20.3209 10.0133 19.2046 8.24 17.6103 6.97003C16.016 5.70005 14.038 5.00853 11.9997 5.00853C9.96136 5.00853 7.98335 5.70005 6.38904 6.97003C4.79473 8.24 3.67843 10.0133 3.22266 12C3.67676 13.9883 4.79234 15.7635 6.38681 17.0352C7.98128 18.3068 9.9602 18.9996 11.9997 19ZM11.9997 16.5C10.8062 16.5 9.6616 16.0259 8.81768 15.182C7.97377 14.3381 7.49966 13.1935 7.49966 12C7.49966 10.8065 7.97377 9.66193 8.81768 8.81802C9.6616 7.97411 10.8062 7.5 11.9997 7.5C13.1931 7.5 14.3377 7.97411 15.1816 8.81802C16.0256 9.66193 16.4997 10.8065 16.4997 12C16.4997 13.1935 16.0256 14.3381 15.1816 15.182C14.3377 16.0259 13.1931 16.5 11.9997 16.5ZM11.9997 14.5C12.6627 14.5 13.2986 14.2366 13.7674 13.7678C14.2363 13.2989 14.4997 12.663 14.4997 12C14.4997 11.337 14.2363 10.7011 13.7674 10.2322C13.2986 9.76339 12.6627 9.5 11.9997 9.5C11.3366 9.5 10.7007 9.76339 10.2319 10.2322C9.76306 10.7011 9.49966 11.337 9.49966 12C9.49966 12.663 9.76306 13.2989 10.2319 13.7678C10.7007 14.2366 11.3366 14.5 11.9997 14.5Z"
                      fill="#616161"></path>
                </g>
                <defs>
                  <clipPath id="clip0_1297_5677">
                    <rect width="24" height="24" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="now-see">{{ getRandomNumber(0,10) }}</div>
          </div>
          <div v-if="car.bidNow">
            <span class="car-ttx mr-1">bid ამჟამად</span><span
              class="car-price">${{ car.bidNow }}</span>
          </div>
          <div v-else>
            <span class="car-ttx mr-1">price</span><span
              class="car-price">${{ car.price }}</span>
          </div>
        </div>
      </div>
    </div>
  </router-link>
    <router-link v-else :to="{
      'name' : 'locatelotById',
        'params' : {
          'lotId' :  car?.id
        }
    }">
      <div class="flex-shrink-0 preview-popular overflow-hidden relative h-[273px]">
        <img
            class="w-full h-[100%] block bg-gray-200 dark:bg-gray-700 object-cover"
            :src="(car.lotId ?JSON.parse(car.images)[0] : 'https://back-end.4dealer.co/'+car.images[0].path) ">
        <div class="ready-to-buy pl-5 pr-5 flex justify-center items-center" v-if="car.buyNowPrice && (car.buyNowPrice) > 0">
          <div class="mr-2">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M15.95 14.1246C16.9289 13.1333 17.5955 11.8704 17.8656 10.4956C18.1356 9.12075 17.9969 7.69569 17.4671 6.40064C16.9373 5.10558 16.04 3.99868 14.8889 3.21991C13.7378 2.44114 12.3844 2.02548 11 2.02548C9.61557 2.02548 8.26222 2.44114 7.11109 3.21991C5.95996 3.99868 5.06275 5.10558 4.53292 6.40064C4.00308 7.69569 3.86442 9.12075 4.13445 10.4956C4.40449 11.8704 5.07111 13.1333 6.05 14.1246L11 19.1366L15.95 14.1246ZM11 22L4.636 15.5563C3.37734 14.2819 2.52019 12.6581 2.17293 10.8905C1.82567 9.12277 2.00391 7.29051 2.6851 5.62539C3.36629 3.96027 4.51984 2.53707 5.99988 1.53576C7.47992 0.534448 9.21998 0 11 0C12.78 0 14.5201 0.534448 16.0001 1.53576C17.4802 2.53707 18.6337 3.96027 19.3149 5.62539C19.9961 7.29051 20.1743 9.12277 19.8271 10.8905C19.4798 12.6581 18.6227 14.2819 17.364 15.5563L11 22ZM12 9.11262H16V11.1377H10V4.05001H12V9.11262Z"
                  fill="#5D9C59"></path>
            </svg>
          </div>
          <div>Buy Now ( $ {{ car.buyNowPrice }} )</div>
        </div>
      </div>
      <div class="w-full xs:p-4"><h3 class="car-title h-[60px]">
        {{ car.maker }} {{ car.model }} {{ car.year }} </h3>
        <div>
          <div class="h-[20px]">
            <hr>
          </div>
          <div class="flex justify-between car-price items-center">
            <div class="flex items-center justify-center">
              <div class="mr-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1297_5677)">
                    <path
                        d="M11.9997 3C17.3917 3 21.8777 6.88 22.8187 12C21.8787 17.12 17.3917 21 11.9997 21C6.60766 21 2.12166 17.12 1.18066 12C2.12066 6.88 6.60766 3 11.9997 3ZM11.9997 19C14.0391 18.9996 16.0181 18.3068 17.6125 17.0352C19.207 15.7635 20.3226 13.9883 20.7767 12C20.3209 10.0133 19.2046 8.24 17.6103 6.97003C16.016 5.70005 14.038 5.00853 11.9997 5.00853C9.96136 5.00853 7.98335 5.70005 6.38904 6.97003C4.79473 8.24 3.67843 10.0133 3.22266 12C3.67676 13.9883 4.79234 15.7635 6.38681 17.0352C7.98128 18.3068 9.9602 18.9996 11.9997 19ZM11.9997 16.5C10.8062 16.5 9.6616 16.0259 8.81768 15.182C7.97377 14.3381 7.49966 13.1935 7.49966 12C7.49966 10.8065 7.97377 9.66193 8.81768 8.81802C9.6616 7.97411 10.8062 7.5 11.9997 7.5C13.1931 7.5 14.3377 7.97411 15.1816 8.81802C16.0256 9.66193 16.4997 10.8065 16.4997 12C16.4997 13.1935 16.0256 14.3381 15.1816 15.182C14.3377 16.0259 13.1931 16.5 11.9997 16.5ZM11.9997 14.5C12.6627 14.5 13.2986 14.2366 13.7674 13.7678C14.2363 13.2989 14.4997 12.663 14.4997 12C14.4997 11.337 14.2363 10.7011 13.7674 10.2322C13.2986 9.76339 12.6627 9.5 11.9997 9.5C11.3366 9.5 10.7007 9.76339 10.2319 10.2322C9.76306 10.7011 9.49966 11.337 9.49966 12C9.49966 12.663 9.76306 13.2989 10.2319 13.7678C10.7007 14.2366 11.3366 14.5 11.9997 14.5Z"
                        fill="#616161"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1297_5677">
                      <rect width="24" height="24" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="now-see">{{ getRandomNumber(0,10) }}</div>
            </div>
            <div v-if="car.bidNow">
              <span class="car-ttx mr-1">bid ამჟამად</span><span
                class="car-price">${{ car.bidNow }}</span>
            </div>
            <div v-else>
              <span class="car-ttx mr-1">price</span><span
                class="car-price">${{ car.price }}</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<style scoped>

</style>