<script>
export default {}
</script>

<template>
  <div class="we-offer xs:p-5 2xlg:p-0" id="we-offer">
    <div class="we-offer-wrapper max-w-[1600px] mx-auto xlg:py-20">
      <div class="we-offer-header mb-5">{{ $t('weOffer.header') }}</div>
      <div class="grid xlg:grid-cols-11 mb:grid-cols-2 gap-4">
        <div class="flex items-center justify-start col-span-2"><!---->
          <div class="mr-5"><img :src="require('../assets/forDealers/secure.svg')" alt=""></div>
          <div class="block-text">{{ $t('weOffer.title1') }}</div>
        </div>
        <div class="flex items-center justify-center col-span-1">
          <div class="line flex justify-center w-full"></div><!----><!----></div>
        <div class="flex items-center justify-start col-span-2"><!---->
          <div class="mr-5"><img :src="require('../assets/forDealers/money-bill-1.svg')" alt="">
          </div>
          <div class="block-text">{{ $t('weOffer.title2') }}</div>
        </div>
        <div class="flex items-center justify-center col-span-1">
          <div class="line flex justify-center w-full"></div><!----><!----></div>
        <div class="flex items-center justify-start col-span-2"><!---->
          <div class="mr-5"><img  :src="require('../assets/forDealers/blob-discount.svg')" alt="">
          </div>
          <div class="block-text">{{ $t('weOffer.title3') }}</div>
        </div>
        <div class="flex items-center justify-center col-span-1">
          <div class="line flex justify-center w-full"></div><!----><!----></div>
        <div class="flex items-center justify-start col-span-2"><!---->
          <div class="mr-5"><img :src="require('../assets/forDealers/comments.svg')" alt=""></div>
          <div class="block-text">{{ $t('weOffer.title4') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.line {
  width: 1px;
  height: 98%;
  background: #d9d9d9
}

.block-text {
  color: #000;
  font-family: TBCContracticaCAPS;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px
}

.we-offer {
  background: #fff
}

.we-offer-header {
  color: #000;
  font-family: TBCContracticaCaps;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px
}
</style>
