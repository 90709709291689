<script>
export default {
  props: {
    videoNumber : {
      required : true,
      default : 0
    },
    show : {
      required : true,
      default: false
    }
  },
  data(){
    return {
      modal: false,
      videos : [require('../assets/video/1.mp4'),require('../assets/video/2.mp4')]
    }
  },
  mounted() {
    this.modal = this.show;
  },
  methods : {
    closeModal(){
      this.modal = !this.modal;

      setTimeout(()=>{
        this.$emit('close')
      },500)
    },
  }
}
</script>

<template>
  <div :class="{
    'opened' : modal,
    'closed' : !modal
  }" class="fixed top-0 left-0 right-0 z-999 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%)] max-h-full flex justify-center items-center"
       id="request-call" >
    <div class="absolute top-0 left-0 right-0 z-999 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%)] max-h-full" @click="closeModal">

    </div>
    <div class="relative w-full max-w-2xl max-h-full">
      <div class="w-full relative bg-white rounded-lg shadow">
        <div class="flex items-start justify-between p-4 rounded-t dark:border-gray-600">
          <button type="button" @click="closeModal"
                  class="bg-transparent text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                  data-modal-hide="defaultModal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
            </svg>
          </button>
        </div>
        <div class="w-full space-y-6 mb:flex mb:justify-between mb:items-center">
          <video controls="" autoplay="">
            <source :src="videos[videoNumber]" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
