<script>
import StarRating from "vue-star-rating";

export default {
  name: "feedback-component",
  components: {StarRating},
  data() {
    return {
      feedbacks: [
        {
          text: "\"“ის ავტომობილი, რომელიც მომწონდა საქართველოში საკმაოდ ძვირი ღირდა, მაგრამ 4Dealer-ის გუნდი დამეხმარა და ძალიან იაფად შევძელი იგივე ავტომობილის ჩამოყვანა. ამ ხალხთან კომუნიკაცია არის ნამდვილად დიდი სიამოვნება და რაც მთავარია ისინი დამეხმარნენ საჭიროებების განსაზღვრაში. 4Dealer-მა დამარწმუნა იმაში, რომ მთავარია სურვილი და ისინი მართლაც ნებისმიერ ავტომობილს ჩამოგიყვანენ",
          name: "Mariam Bakradze",
          city: "Armenia, Vanadzor",
          flag: "/flags/ar-flag.png",
          hasTestimonials: true,
          rating: 5
        },
        {
          text: "\"“ჩემი ახალი ავტომობილი ჩამომიყვანა 4Dealer-მა და ძალიან დიდი რეკომენდაცია ამ კომპანიას. აქ მუშაობენ ნამდვილად საკუთარი საქმის პროფესიონალები, რომელთათვისაც მომხმარებელი არის ნომერ პირველ ადგილზე. ჩემი უამრავი კითხვიდან არცერთი არ დარჩენილა უპასუხოდ. ტრანსპორტირების სრული დაზღვევით კი საერთოდ არ მიფიქრია არცერთ შესაძლო რისკზე.”\"",
          name: "Ana Kipiani",
          city: "Kazakhstan, Shymkent",
          flag: "/flags/kz-flag.png",
          hasTestimonials: true,
          rating: 5
        },
        {
          text: "\"“დღესდღეობით საკმაოდ იშვიათია ისეთი კომპანია, რომელსაც ავტომობილის ყიდვისთვის გადადებულ თანხას ანდობ. 4Dealer-ის წარმომადგენლები გამოირჩევიან უშუალობით, მეგობრულობით და გულწრფელობით. არ დამრჩენია არცერთი შეკითხვა, რომელზეც პასუხი ვერ მივიღე და ჩემი ავტომობილიც ძალიან სწრაფად ჩამოვიდა ფოთამდე.“",
          name: "Luka Ivanov",
          city: "Georgia, Tbilisi",
          flag: "/flags/ge-flag.png",
          hasTestimonials: true,
          rating: 5
        },
        {
          text: "\"“ჩემი სრული რეკომენდაცია 4Dealer-ს. საუკეთესო პირობები, მაღალი ხარისხის მომსახურება, კონსულტანტი მუდმივად ხაზზე ნებისმიერ დროს. აქ დაგეხმარებიან ავტომობილის შერჩევაში და არ იფიქრებთ იმაზე, რომ გუნდისთვის მხოლოდ ავტომობილის გაყიდვაა პრიორიტეტი.”",
          name: "Giorgi Javakhishvili",
          flag: "/flags/az-flag.png",
          city: "Azerbaijan, Ganja",
          hasTestimonials: true,
          rating: 5
        }
      ],
      mainFeedback: {
        text: "\"4Dealer-ის შესახებ ჯერ კიდევ მაშინ გავიგე, როდესაც მანქანის ჩამოყვანას საერთოდ არ ვგეგმავდი, თუმცა როდესაც გადავწყვიტე რეალობად მექცია ჩემი ფიქრები, პირველად სწორედ ეს კომპანია გამახსენდა. თამამად შემიძლია ვთქვა, რომ როდესაც საქართველოში არსებობს ისეთი კომპანია, რომელიც ხალხის ინტერესებს ითვალისწინებს, ძალიან დასაფასებელია. ამერიკიდან მანქანის ჩამოყვანა ჩემთვის ყოველთვის სირთულეებთან იყო დაკავშირებული, თუმცა 4დილერის აღმოჩენის შემდეგ, ეს მითები სრულიად დაიმსხვრა. ვერც ვიფიქრებდი, თუ გუნდის ყველა წევრისგან ამხელა მხარდაჭერას მივიღებდი მანქანის შეძენის თითოეულ ეტაპზე. მოკლედ, ვისაც ძალიან მცირე დროში და საოცარი პირობებით მანქანის შეძენა სურს, ჩემგან უდიდესი რეკომენდაცია 4Dealer Auto Import-ს\"",
        name: "Nika Gogoladze",
        city: "Georgia, Batumi",
        flag: "/flags/ge-flag.png",
        hasTestimonials: true,
        rating: 5
      },
    }
  }
}
</script>

<template>
  <div id="feedback" class="feedback-wrapper xs:p-5 mb:mx-auto">
    <div class="feedback-header mb-5 ">
      {{ $t('feedback.header') }}
    </div>
    <div class="2xlg:flex">
      <div class="main-wrapper 2xlg:basis-4/12 xs:mb-5 2xlg:mr-5 2xlg:m-px mb:basis-full xs:p-3">
        <div v-if="mainFeedback.hasTestimonials">
          <div class="see-testimonials flex w-full justify-end mb-4 ">
            <div>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1297_935)">
                  <path
                      d="M15.4331 12.4998L9.03906 8.23682V16.7628L15.4331 12.4998ZM18.4151 12.9158L7.81606 19.9818C7.74077 20.0319 7.65329 20.0607 7.56295 20.065C7.47262 20.0693 7.3828 20.0491 7.30306 20.0064C7.22333 19.9637 7.15667 19.9002 7.11018 19.8226C7.06369 19.745 7.03911 19.6563 7.03906 19.5658V5.43382C7.03911 5.34338 7.06369 5.25465 7.11018 5.17707C7.15667 5.09949 7.22333 5.03597 7.30306 4.99328C7.3828 4.95059 7.47262 4.93033 7.56295 4.93465C7.65329 4.93897 7.74077 4.96772 7.81606 5.01782L18.4151 12.0838C18.4835 12.1295 18.5397 12.1913 18.5785 12.2639C18.6174 12.3365 18.6377 12.4175 18.6377 12.4998C18.6377 12.5821 18.6174 12.6632 18.5785 12.7357C18.5397 12.8083 18.4835 12.8702 18.4151 12.9158Z"
                      fill="#256DFF"></path>
                </g>
                <defs>
                  <clipPath id="clip0_1297_935">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>
              <router-link :to="{name : 'reviews'}">
                {{ $t('feedback.seeTestimonials') }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="testimonial-text">
          {{ mainFeedback.text }}
        </div>
        <div class="testimonial-info xs:mt-5 mb:mt-10 mb:flex justify-between" style="position: relative;bottom: -35px;">
          <div class="testimonial-block-name">
            <div>
              <div class="feedback-name">
                {{ mainFeedback.name }}
              </div>
              <div class="feedback-location flex">
                <div class="mr-2">
                  <img :src="require('../assets'+mainFeedback.flag)" alt="">
                </div>
                <div>
                  {{ mainFeedback.city }}
                </div>
              </div>
            </div>
          </div>
          <div class="testimonial-rating" >
            <div>
              Rating:
            </div>
            <StarRating
                v-model:rating="mainFeedback.rating"
                :read-only="true"
                :show-rating="false"
                :star-size="25"
            />
          </div>
        </div>
      </div>
      <div class="additional-wrapper mb:basis-full 2xlg:basis-8/12">
        <div class="xs:mb-5 xs:p-3" v-for="(feedback,index) in feedbacks" :key="index">
          <div class="testimonial-text">
            {{ feedback.text }}
          </div>
          <div class="testimonial-info xs:mt-5 mb:mt-10 mb:flex justify-between">
            <div class="testimonial-block-name">
              <div>
                <div class="feedback-name">
                  {{ feedback.name }}
                </div>
                <div class="feedback-location flex items-center">
                  <div class="mr-2">
                    <img :src="require('../assets'+feedback.flag)" alt="">
                  </div>
                  <div>
                    {{ feedback.city }}
                  </div>
                </div>
              </div>
            </div>
            <div class="testimonial-rating">
              <div>
                Rating:
              </div>
              <StarRating
                  v-model:rating="feedback.rating"
                  :read-only="true"
                  :show-rating="false"
                  :star-size="25"
              />
            </div>
          </div>

        </div>
      </div>
    </div>
    <router-link class="all-testimonials flex justify-start items-center mt-5" :to="{ name: 'reviews' }">
      <div class="mr-2 font-caps"> {{ $t('feedback.allReview') }} </div>
      <div class="flex items-center">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1015_7940)">
            <path data-v-799fcbbf=""
                  d="M13.4763 9.66689L9.00634 5.19689L10.1847 4.01855L16.6663 10.5002L10.1847 16.9819L9.00634 15.8036L13.4763 11.3336H3.33301V9.66689H13.4763Z"
                  fill="#256DFF"></path>
          </g>
          <defs data-v-799fcbbf="">
            <clipPath id="clip0_1015_7940">
              <rect width="20" height="20" fill="white" transform="translate(0 0.5)"></rect>
            </clipPath>
          </defs>
        </svg>
      </div>
    </router-link>
  </div>
</template>

<style scoped>
.see-testimonials {
  color: var(--Blue,#256dff);
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px
}

.testimonial-info.big {
  padding-top: 85px
}

.testimonial-info {
  justify-content: space-between;
  align-items: center;
  width: 100%
}

.testimonial-block-name {
  display: flex;
  align-items: center
}

.testimonial-block-name>div:first-child {
  margin-right: 15px
}

.testimonial-text {
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px
}

.feedback-wrapper {
  max-width: 1600px
}

@media screen and (max-width: 1023px) {
  .feedback-header {
    color:#000;
    font-family: TBCContracticaCaps;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px
  }
}

@media screen and (min-width: 1024px) {
  .testimonial-text {
    min-height:148px
  }

  .feedback-header {
    font-family: TBCContracticaCaps;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 49px
  }

  .additional-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    flex-direction: row;
    justify-content: space-between
  }

  .additional-wrapper>div {
    width: calc(50% - 10px);
    height: calc(50% - 10px)
  }
}

.additional-wrapper .testimonial-text {
  max-height: 148px;
  overflow: hidden
}

.additional-wrapper>div {
  border-radius: 4px;
  border: 1px solid #ede2ff;
  background: #fff;
  flex-wrap: wrap;
  align-content: space-between
}

.additional-wrapper>div,.feedback-block {
  display: flex;
  justify-content: space-between
}

.feedback-name {
  color: #000;
  font-family: TBCContractica;
  font-size: calc(12px + .3125vw);
  font-style: normal;
  font-weight: 500;
  line-height: 25px
}

.testimonial-rating {
  color: var(--text-gray,#616161);
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px
}

.main-wrapper {
  border-radius: 4px;
  border: 1px solid #ede2ff;
  background: #fff;
}

.feedback-location {
  color: #000;
  font-weight: 400
}

.all-testimonials,.feedback-location {
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  line-height: 25px
}

.all-testimonials {
  color: var(--blue,#256dff);
  font-weight: 700
}
</style>
