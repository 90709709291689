<script>
import DropDownModel from "@/components/small-components/drop-down-model.vue";
import DropDownYear from "@/components/small-components/drop-down-year.vue";
import DropDownMaker from "@/components/small-components/drop-down-maker.vue";
import {client} from "@/axios/client";

export default {
  name: "filter-main",
  components: {DropDownMaker, DropDownYear, DropDownModel},
  data() {
    return {
      settings: {},
      makers: [],
      years: [],
      currentModel: [],
      currentMakers: [],
      models: [],
      keyModel: 0,
      specialFilter : "",
    }
  },
  async mounted() {
    await this.getFilters();
  },
  methods: {
    async getFilters() {
      const {data} = await client.get('api/search-settings');
      this.settings = data
      this.currentModel = data.model;
      this.currentMakers = data.makers;
    },
    selectSpecialFilter(special){
      this.specialFilter = special
    },
    filterDataMakers(value) {
      this.makers = value;
      this.keyModel = ++this.keyModel;
      const manufacture = Object.values(value)
      if(manufacture.length > 0){
        this.currentModel = manufacture.reduce((result, manufacturer) => {
          result[manufacturer] = this.settings.model[manufacturer] || [];
          return result
        }, []);
      }else{
        this.currentModel = this.settings.model
      }

    },
    filterDataYears(value) {
      this.years = value;
    },
    filterDataModels(value) {
      this.models = value;

    },
    search() {
      this.$router.push({
        name: 'catalogue',
        query: {
          special: this.specialFilter,
          makers: this.makers,
          models: this.models,
          years: this.years,
        }
      })
    }
  }
}
</script>

<template>
  <div class="catalogue-filter-main mr-auto ml-auto mb:p-10 xs:pl-5 xs:pr-5 xs:pt-10 xs:pb-10 2xlg:pr-24 2xlg:pl-24"
       id="catalogue-filter-main">
    <div class="filter-fields xlg:flex justify-start items-center" v-if="Object.keys(settings).length > 0">
      <drop-down-maker @update="filterDataMakers" :options="currentMakers"></drop-down-maker>
      <drop-down-model @update="filterDataModels" :options="currentModel" :key="keyModel"></drop-down-model>
      <drop-down-year @update="filterDataYears" :min="settings.year.min" :max="settings.year.max"></drop-down-year>
      <button class="btn btn-font btn-blue xs:w-full xlg:w-3/12 custom-border-radius" @click="search">{{ $t('mainFilter.searchBtn') }}</button>
    </div>
    <div class="quick-filter mt-5 xlg:flex justify-between items-center">
      <div @click="selectSpecialFilter(3000)" class="flex justify-center items-center" :class="{active:specialFilter === 3000}">
        <div class="grey-circle w-12 h-12 flex items-center justify-center mr-2">3K</div>
        <div><b>$3 000</b> - {{ $t('mainFilter.under') }}</div>
      </div>
      <div @click="selectSpecialFilter(5000)" class="flex justify-center items-center" :class="{active:specialFilter === 5000}">
        <div class="green-circle w-12 h-12 flex items-center justify-center mr-2">5K</div>
        <div><b>$5 000</b> - {{ $t('mainFilter.under') }}</div>
      </div>
      <div @click="selectSpecialFilter(10000)" class="flex justify-center items-center" :class="{active:specialFilter === 10000}">
        <div class="pure-circle w-12 h-12 flex items-center justify-center mr-2">10K</div>
        <div><b>$10 000</b> - {{ $t('mainFilter.under') }}</div>
      </div>
      <div class="flex justify-center items-center hidden">
        <div class="blue-circle w-12 h-12 flex items-center justify-center mr-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 20H5V21C5 21.2652 4.89464 21.5196 4.70711 21.7071C4.51957 21.8946 4.26522 22 4 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V13.5L0.757 13.19C0.540753 13.1358 0.348809 13.011 0.211655 12.8352C0.0745022 12.6595 5.86043e-06 12.4429 0 12.22L0 11.5C0 11.3674 0.0526784 11.2402 0.146447 11.1464C0.240215 11.0527 0.367392 11 0.5 11H2L4.48 5.212C4.63432 4.852 4.89096 4.54524 5.21805 4.32978C5.54515 4.11432 5.92832 3.99965 6.32 4H17.68C18.0713 4.00004 18.4541 4.1149 18.7808 4.33033C19.1075 4.54577 19.3638 4.85231 19.518 5.212L22 11H23.5C23.6326 11 23.7598 11.0527 23.8536 11.1464C23.9473 11.2402 24 11.3674 24 11.5V12.22C24 12.4429 23.9255 12.6595 23.7883 12.8352C23.6512 13.011 23.4592 13.1358 23.243 13.19L22 13.5V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H20C19.7348 22 19.4804 21.8946 19.2929 21.7071C19.1054 21.5196 19 21.2652 19 21V20ZM20 18V13H4V18H20ZM5.477 11H18.523C18.6863 10.9999 18.8471 10.9598 18.9914 10.8832C19.1357 10.8066 19.2589 10.6958 19.3505 10.5605C19.442 10.4253 19.4991 10.2697 19.5166 10.1073C19.5341 9.94488 19.5116 9.78067 19.451 9.629L18 6H6L4.549 9.629C4.4884 9.78067 4.46588 9.94488 4.4834 10.1073C4.50092 10.2697 4.55795 10.4253 4.64951 10.5605C4.74106 10.6958 4.86435 10.8066 5.0086 10.8832C5.15285 10.9598 5.31367 10.9999 5.477 11ZM5 14C7.317 14 8.879 14.755 9.686 16.264C9.72679 16.3402 9.74714 16.4257 9.74506 16.5121C9.74297 16.5985 9.71852 16.6829 9.6741 16.757C9.62968 16.8311 9.5668 16.8925 9.49159 16.9351C9.41639 16.9777 9.33143 17 9.245 17H6C5.73478 17 5.48043 16.8946 5.29289 16.7071C5.10536 16.5196 5 16.2652 5 16V14ZM19 14V16C19 16.2652 18.8946 16.5196 18.7071 16.7071C18.5196 16.8946 18.2652 17 18 17H14.755C14.6687 16.9999 14.5838 16.9774 14.5088 16.9347C14.4337 16.892 14.3709 16.8307 14.3266 16.7566C14.2823 16.6825 14.258 16.5981 14.2559 16.5118C14.2539 16.4255 14.2742 16.3401 14.315 16.264C15.12 14.754 16.682 14 19 14Z"
                fill="#35558C"></path>
          </svg>
        </div>
        <div>შერჩეული <br>ავტომობილები</div>
      </div>
      <div @click="selectSpecialFilter('BuyNow')" class="flex justify-center items-center" :class="{active:specialFilter === 'BuyNow'}">
        <div class="orange-circle w-12 h-12 flex items-center justify-center mr-2">
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.8998 15.4087C18.9677 14.3273 19.6949 12.9496 19.9895 11.4497C20.2841 9.9499 20.1328 8.3953 19.5548 6.98251C18.9768 5.56972 17.9981 4.3622 16.7423 3.51263C15.4865 2.66306 14.0101 2.20961 12.4998 2.20961C10.9895 2.20961 9.51315 2.66306 8.25737 3.51263C7.0016 4.3622 6.02282 5.56972 5.44482 6.98251C4.86682 8.3953 4.71555 9.9499 5.01014 11.4497C5.30472 12.9496 6.03194 14.3273 7.09983 15.4087L12.4998 20.8763L17.8998 15.4087ZM12.4998 24L5.55728 16.9705C4.1842 15.5802 3.24912 13.8089 2.87029 11.8805C2.49146 9.95211 2.6859 7.95329 3.42902 6.13679C4.17214 4.3203 5.43056 2.76771 7.04515 1.67537C8.65974 0.583034 10.558 0 12.4998 0C14.4417 0 16.3399 0.583034 17.9545 1.67537C19.5691 2.76771 20.8275 4.3203 21.5706 6.13679C22.3138 7.95329 22.5082 9.95211 22.1294 11.8805C21.7505 13.8089 20.8155 15.5802 19.4424 16.9705L12.4998 24ZM13.5907 9.94104H17.9544V12.1502H11.4089V4.41819H13.5907V9.94104Z"
                fill="white"></path>
          </svg>
        </div>
        <div>Buy Now</div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
