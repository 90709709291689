<script>
import {client} from "@/axios/client";

export default {
  name: "nowPopular",
  data() {
    return {
      cars: [],
      loading: true,
    }
  },
  async mounted() {
    await this.getCars()
  },
  methods: {

    async getCars() {
      const {data, status} = await client.get('/api/popular-car');
      if (status === 200) {
        this.cars = data.slice(0, data.length - 2);
        this.loading = false;
      }
    }
  }
}
</script>

<template>
  <div class="now-popular mb:my-24 xs:my-5 ml-auto mr-auto xs:p-5" id="now-popular">
    <div class="now-popular-header">{{ $t('nowPopular.header') }}</div>
    <div class="cars-block grid xlg:grid-cols-4 lg:grid-cols-3 mb:grid-cols-2 xs:grid-cols-1 gap-4 mt-16"
         v-if="!loading">
      <car-block :car="car" v-for="(car,key) in cars" :key="key"></car-block>
    </div>
    <div class="cars-block lg:flex mt-16" v-else>
      <div v-for="(index,key) in [1,2,3,4,5,6,7,8]" class="car-block animate-pulse mb-5" :key="key">
        <div class="flex-shrink-0"><span
            class="w-full2 h-[200px] block bg-gray-200 dark:bg-gray-700 mb-5"></span></div>
        <div class="w-full xs:p-2 mt-5"><h3 class="h-4 bg-gray-200 rounded-md dark:bg-gray-700"
                                            style="width:40%;"></h3>
          <ul class="mt-5 space-y-3">
            <li class="w-full h-4 bg-gray-200 mt-5 rounded-md dark:bg-gray-700"></li>
            <li class="w-full h-4 bg-gray-200 rounded-md dark:bg-gray-700"></li>
          </ul>
        </div>
      </div>
    </div>
    <router-link class="all-cars flex" :to="{name:'catalogue'}">
      <div class="mr-2 font-caps">{{ $t('nowPopular.seeAll') }}</div>
      <div class="flex items-center">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1015_7940)">
            <path
                d="M13.4763 9.66689L9.00634 5.19689L10.1847 4.01855L16.6663 10.5002L10.1847 16.9819L9.00634 15.8036L13.4763 11.3336H3.33301V9.66689H13.4763Z"
                fill="#256DFF"></path>
          </g>
          <defs>
            <clipPath id="clip0_1015_7940">
              <rect width="20" height="20" fill="white" transform="translate(0 0.5)"></rect>
            </clipPath>
          </defs>
        </svg>
      </div>
    </router-link>
  </div>
</template>

<style scoped>
.all-cars {
  color: var(--blue, #256dff);
  font-weight: 700;
}
</style>
