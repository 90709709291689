import MainPage from "@/pages/MainPage.vue";
import CataloguePage from "@/pages/CataloguePage.vue";

import {createRouter, createWebHistory} from 'vue-router'
import ServicesPage from "@/pages/ServicesPage.vue";
import CalculatorPage from "@/pages/CalculatorPage.vue";
import ReviewsPage from "@/pages/ReviewsPage.vue";
import ContactUsPage from "@/pages/ContactUsPage.vue";
import ForDealerPage from "@/pages/ForDealerPage.vue";
import BlogPage from "@/pages/BlogPage.vue";
import FaqPage from "@/pages/FaqPage.vue";
import PaymentPage from "@/pages/PaymentPage.vue";
import PrivacyPolicyPage from "@/pages/PrivacyPolicyPage.vue";
import TermOfServicePage from "@/pages/TermOfServicePage.vue";
import VinSearchPage from "@/pages/VinSearchPage.vue";
import AboutUsPage from "@/pages/AboutUsPage.vue";
import ArticlePage from "@/pages/ArticlePage.vue";
import LotPage from "@/pages/LotPage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import RegisterPage from "@/pages/RegisterPage.vue";
import FavoriteCarPage from "@/pages/FavoriteCarPage.vue";
import ProfilePage from "@/pages/ProfilePage.vue";
import ProfileComponent from "@/components/ProfileComponent.vue";
import FavoritesComponent from "@/components/FavoritesComponent.vue";
import AuctionComponent from "@/components/AuctionComponent.vue";
import MyCarsComponent from "@/components/MyCarsComponent.vue";
import MyCarsCreateComponent from "@/components/MyCarsCreateComponent.vue";
import MyCarsEditComponent from "@/components/MyCarsEditComponent.vue";
import LocaleLotPage from "@/pages/LocaleLotPage.vue";

const routes = [
    {path: '/', component: MainPage, name: 'main'},
    {path: '/catalogue', component: CataloguePage, name: 'catalogue'},
    {path: '/services', component: ServicesPage, name: 'services'},
    {path: '/calculator', component: CalculatorPage, name: 'calculator'},
    {path: '/reviews', component: ReviewsPage, name: 'reviews'},
    {path: '/contact-us', component: ContactUsPage, name: 'contact-us'},
    {path: '/about-us', component: AboutUsPage, name: 'about-us'},
    {path: '/for-dealers', component: ForDealerPage, name: 'for-dealers'},
    {path: '/blog', component: BlogPage, name: 'blog'},
    {path: '/faq', component: FaqPage, name: 'faq'},
    {path: '/payment', component: PaymentPage, name: 'payment'},
    {path: '/privacy-policy', component: PrivacyPolicyPage, name: 'privacy-policy'},
    {path: '/term-of-service', component: TermOfServicePage, name: 'term-of-service'},
    {path: '/vin-search', component: VinSearchPage, name: 'vin-search'},
    {path: '/article/:id', component: ArticlePage, name: 'article'},
    {path: '/lot/:lotId', component: LotPage, name: 'lotById'},
    {path: '/car/:lotId', component: LocaleLotPage, name: 'locatelotById'},
    {path: '/login', component: LoginPage, name: 'login'},
    {path: '/register', component: RegisterPage, name: 'register'},
    {
        path: '/profile', component: ProfilePage, name: 'profile', meta:{
            isPrivate : true
        }, children: [
            {
                path: '/profile/info',
                name: 'profile.main',
                meta:{
                    isPrivate : true
                },
                component: ProfileComponent
            },
            {
                path: 'favorites',
                name: 'profile.favorites',
                meta:{
                    isPrivate : true
                },
                component: FavoritesComponent
            },
            {
                path: 'auctions',
                name: 'profile.auctions',
                meta:{
                    isPrivate : true
                },
                component: AuctionComponent
            },
            {
                path: 'my-cars',
                name: 'profile.my-cars',
                meta:{
                    isPrivate : true
                },
                component: MyCarsComponent
            },
            {
                path: 'my-cars/create',
                name: 'profile.my-cars.create',
                meta:{
                    isPrivate : true
                },
                component: MyCarsCreateComponent
            },{
                path: 'my-cars/edit/:id',
                name: 'profile.my-cars.edit',
                meta:{
                    isPrivate : true
                },
                component: MyCarsEditComponent
            },
        ]
    },
    {path: '/favorite-car', component: FavoriteCarPage, name: 'favorite-car'},
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior() {
        return {
            top: 0,
            behavior: 'smooth'
        };
    },
});

router.beforeEach((to, from, next) => {
    const token = window.sessionStorage?.token;
    const user = window.sessionStorage?.user;

    // Проверка наличия токена и пользователя в sessionStorage
    if (to.matched.some(record => record.meta.isPrivate) && (!token || !user)) {
        // Пользователь не аутентифицирован, перенаправляем на страницу входа
        next({ name: 'login' });
    } else {
        // Пользователь аутентифицирован или маршрут не требует аутентификации
        next();
    }
});

export default router;

