<script >
import requestCallComponent from "@/modals/request-call.vue";

export default {
  name: "retro-car-component",
  components: {requestCallComponent},
  data(){
    return {
      requestCall : false
    }
  }
}
</script>

<template>
  <div class="retro-car w-full" id="retro-car">
    <div class="retro-car-wrapper relative mr-auto ml-auto xlg:flex xlg:justify-between xlg:items-center">
        <div class="retro-left-img xlg:w-8/12 2xlg:w-6/12 xs:w-full xlg:retro-left-img lg:w-full">
        </div>
        <div class="xlg:w-4/12 2xlg:w-6/12 xs:w-full xs:text-center xs:p-5">
          <div class="retro-car-header mb-5 lg:text-left">
            <h2>
              {{ $t('timelessClassic.header') }}
            </h2>
          </div>
          <div class="retro-car-text mb-5 lg:text-left xs:text-left">
            {{ $t('timelessClassic.text') }}
          </div>
          <div class="lg:text-left">
            <button class="btn btn-font btn-blue xs:w-full mb:w-auto" @click="requestCall = true">
              {{ $t('timelessClassic.btn') }}
            </button>
          </div>
        </div>
    </div>
    <div class="modal" v-if="requestCall">
      <request-call-component :show="requestCall" :backEnd="'consultation-about-retro-car'" @close="requestCall = false"></request-call-component>
    </div>
  </div>
</template>

<style scoped>
.retro-left-img{
  background-repeat: no-repeat;
  background-image: url("../assets/retro-car/retro-car.png");
}
.retro-car {
  background: #fff
}

.retro-car-text {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px
}

@media screen and (min-width: 1200px) {
  .retro-car-header {
    color:#000;
    font-family: TBCContracticaCaps;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 49px;
    max-width: 559px
  }

  .retro-left-img {
    height: 661px
  }

  .retro-car-text {
    max-width: 510px
  }
}

@media screen and (max-width: 1199px) {
  .retro-car-header {
    color:#000;
    font-family: TBCContracticaCaps;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px
  }

  .retro-left-img {
    height: 267px;
    background-size: contain
  }
}

.retro-car-wrapper {
  max-width: 1600px
}
</style>
