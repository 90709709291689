<script>
export default {
  name: "for-dealer-title"
}
</script>

<template>
  <div class="title-wrapper">
    <div class="title-block">
      <div class="title-header-wrapper">
        <div class="title-header mb-8"><h1 >{{ $t('forDealers.header') }}</h1></div>
        <div class="xs:pr-5 mb:pr-0">
          <button data-modal-target="request-call" data-modal-toggle="request-call"
                  class="btn btn-font btn-blue xs:w-full mb:w-auto">{{ $t('forDealers.btn') }}
          </button>
        </div>
      </div>
      <div class="img-wrapper">
        <div class="maps lg:w-[1109px]">
          <img :src="require('../assets/Earth.png')" :data-rjs="require('../assets/Earth@4x.png')" alt=""></div>
      </div>
    </div><!---->
    <div class="images">
      <div class="car lg:w-[876px]" data-rjs="3">
        <img :src="require('../assets/forDealers/car.png')"  :data-rjs="require('../assets/forDealers/car.png')" alt="">
      </div>
      <div class="lines lg:w-[876px]">
        <img :src="require('../assets/lines.png')" :data-rjs="require('../assets/lines@4x.png')" alt=""></div>
    </div>
  </div>
</template>

<style scoped>

</style>
