<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {client} from "@/axios/client";
import DealersModal from "@/modals/dealers-modal.vue";

export default {
  name: "our-dealers",
  components: {DealersModal, Swiper, SwiperSlide},
  mounted() {
    this.getDealers();
    if (window.innerWidth > 300 && window.innerWidth <= 900) {
      this.options.slidePerView = 1;
    } else if (window.innerWidth > 900 && window.innerWidth <= 1200) {
      this.options.slidePerView = 2;
    } else if (window.innerWidth > 1200 && window.innerWidth <= 1399) {
      this.options.slidePerView = 3;
    } else {
      this.options.slidePerView = 4;
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth > 300 && window.innerWidth <= 900) {
        this.options.slidePerView = 1;
      } else if (window.innerWidth > 900 && window.innerWidth <= 1200) {
        this.options.slidePerView = 2;
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1399) {
        this.options.slidePerView = 3;
      } else {
        this.options.slidePerView = 4;
      }
      this.sliderController += 1;
    }, true);
  },
  setup() {
    const onSlideChange = () => {
    };
    return {
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      selectedDealer: {},
      sliderController: 0,
      carouselDivs: [],
      showModal: false,
      options: {
        slidePerView: 4,
        currentPage: 0
      },
      data: []
    };
  },
  methods:{
    close(){
      this.showModal = false;
    },
    selectDealer(dealer){
      this.selectedDealer = dealer;
      this.showModal = true;
      console.log(dealer);
    },
    replaceByDefault(e) {
      e.target.src = require('../assets/dealers/default.png')
    },
    async getDealers(){
      const {data} = await client.get('/api/dealers');
      this.data = data;
    }
  }
}
</script>

<template>
  <div id="our-dealers" class="our-dealers-wrapper mb:my-24 xs:my-5 ml-auto mr-auto xs:py-5 xs:px-1 relative z-50">
    <div class="our-dealers-header">
      {{ $t('ourDealers.header') }}
    </div>
    <div class="our-dealers-block xlg:py-5">
      <swiper
          :modules="modules"
          :slides-per-view="options.slidePerView"
          :space-between="50"
          navigation
          :pagination="{ clickable: true }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(value,index) in data" :key="index">
          <div class="carousel-block">
            <div class="photo-dealer">
              <img @error="replaceByDefault" :src="value.img" alt="Photo Dealer"></div>
            <div class="dealer-info mb:relative pt-2 pl-4 xs:pb-2 mb:pb-0">
              <div class="dealer-name">{{ value.name }}</div>
              <div class="dealer-location flex-inline">
                <div >
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_736_662)">
                      <path
                            d="M10 19.7736L4.69667 14.4703C3.64779 13.4214 2.93349 12.085 2.64411 10.6301C2.35473 9.17528 2.50326 7.66728 3.07092 6.29684C3.63858 4.9264 4.59987 3.75507 5.83324 2.93096C7.0666 2.10686 8.51665 1.66699 10 1.66699C11.4834 1.66699 12.9334 2.10686 14.1668 2.93096C15.4001 3.75507 16.3614 4.9264 16.9291 6.29684C17.4968 7.66728 17.6453 9.17528 17.3559 10.6301C17.0665 12.085 16.3522 13.4214 15.3033 14.4703L10 19.7736ZM14.125 13.2919C14.9408 12.4761 15.4963 11.4367 15.7213 10.3052C15.9463 9.17362 15.8308 8.00076 15.3892 6.93489C14.9477 5.86902 14.2 4.95802 13.2408 4.31707C12.2815 3.67612 11.1537 3.33401 10 3.33401C8.8463 3.33401 7.71851 3.67612 6.75924 4.31707C5.79997 4.95802 5.05229 5.86902 4.61076 6.93489C4.16923 8.00076 4.05368 9.17362 4.27871 10.3052C4.50374 11.4367 5.05926 12.4761 5.875 13.2919L10 17.4169L14.125 13.2919ZM10 10.8336C9.55798 10.8336 9.13405 10.658 8.82149 10.3454C8.50893 10.0329 8.33334 9.60895 8.33334 9.16693C8.33334 8.7249 8.50893 8.30098 8.82149 7.98842C9.13405 7.67585 9.55798 7.50026 10 7.50026C10.442 7.50026 10.866 7.67585 11.1785 7.98842C11.4911 8.30098 11.6667 8.7249 11.6667 9.16693C11.6667 9.60895 11.4911 10.0329 11.1785 10.3454C10.866 10.658 10.442 10.8336 10 10.8336Z"
                            fill="black"></path>
                    </g>
                    <defs >
                      <clipPath id="clip0_736_662">
                        <rect width="20" height="20" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div >{{ value.location }}</div>
              </div>
              <div class="link flex-inline">
                <div >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25"
                       fill="none">
                    <path
                          d="M22.0004 17.4201V20.4201C22.0016 20.6986 21.9445 20.9743 21.8329 21.2294C21.7214 21.4846 21.5577 21.7137 21.3525 21.902C21.1473 22.0902 20.905 22.2336 20.6412 22.3228C20.3773 22.412 20.0978 22.4452 19.8204 22.4201C16.7433 22.0857 13.7874 21.0342 11.1904 19.3501C8.77425 17.8148 6.72576 15.7663 5.19042 13.3501C3.5004 10.7413 2.44866 7.77109 2.12042 4.6801C2.09543 4.40356 2.1283 4.12486 2.21692 3.86172C2.30555 3.59859 2.44799 3.35679 2.63519 3.15172C2.82238 2.94665 3.05023 2.78281 3.30421 2.67062C3.5582 2.55843 3.83276 2.50036 4.11042 2.5001H7.11042C7.59573 2.49532 8.06621 2.66718 8.43418 2.98363C8.80215 3.30008 9.0425 3.73954 9.11042 4.2201C9.23704 5.18016 9.47187 6.12282 9.81042 7.0301C9.94497 7.38802 9.97408 7.77701 9.89433 8.15098C9.81457 8.52494 9.62928 8.86821 9.36042 9.1401L8.09042 10.4101C9.51398 12.9136 11.5869 14.9865 14.0904 16.4101L15.3604 15.1401C15.6323 14.8712 15.9756 14.6859 16.3495 14.6062C16.7235 14.5264 17.1125 14.5556 17.4704 14.6901C18.3777 15.0286 19.3204 15.2635 20.2804 15.3901C20.7662 15.4586 21.2098 15.7033 21.527 16.0776C21.8441 16.4519 22.0126 16.9297 22.0004 17.4201Z"
                          stroke="#256DFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div class="blue-font cursor-pointer" @click="selectDealer(value)">დაუკავშირდი</div>
              </div>
            </div>
          </div>

        </swiper-slide>
      </swiper>
    </div>
    <dealers-modal @close="close" v-if="showModal" :show="showModal" :dealer="selectedDealer"></dealers-modal>

  </div>

</template>

<style scoped>
.carousel-block {
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-around;
  border-radius: 4px;
  border: 1px solid #ede2ff;
  background: #fff
}

.our-dealers-wrapper {
  max-width: 1600px
}

.our-dealers-header {
  font-family: TBCContracticaCaps;
  font-size: calc(24px + 1.25vw);
  font-style: normal;
  font-weight: 700;
  line-height: 49px
}

.dealer-location {
  font-size: 16px;
  margin-bottom: 5px
}

.blue-font {
  color: var(--blue,#256dff);
  font-size: 16px;
  line-height: 25px
}

.blue-font,.dealer-name {
  font-family: TBCContractica;
  font-style: normal;
  font-weight: 700
}

.dealer-name {
  color: #000;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 7px
}

.photo-dealer img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

@media screen and (min-width: 1101px) {
  .photo-dealer {
    max-width:152px;
    height: 172px
  }

  .dealer-info .link {
    position: absolute;
    bottom: 10px
  }

  .dealer-info .dealer-location {
    position: absolute
  }

  .our-dealers-header {
    margin-bottom: 44px
  }
}

@media screen and (min-width: 300px) and (max-width:1100px) {
  .our-dealers-header {
    margin-bottom:20px
  }

  .carousel-block {
    display: block
  }

  .photo-dealer {
    width: 100%
  }

  .button-next,.button-prev {
    display: none
  }
}
</style>
