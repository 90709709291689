<script>
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import {client} from "@/axios/client";
  export default {
    name : "request-call-component",
    components:{VueTelInput},
    props: {
      show : {
        require : true,
      },
      backEnd : {
        require : true
      },
      options : {
        require : false,
        default : {},
      }
    },
    data(){
      return {
        name : "",
        validate : false,
        phone : null,
        country : {},
        modal : false,
        isSend : false
      }
    },
    mounted() {
      this.modal = this.show;
    },
    methods: {
      closeModal(){
        this.modal = !this.modal;

        setTimeout(()=>{
          this.$emit('close')
        },500)
      },
      countryChanged(country){
        this.country = country;
      },
      changeNumber(number){
        if(typeof number === 'string'){
          this.phone = number
        }
      },
      create(){
        if(this.phone.length <= 11 || this.name.length === 0){
          this.validate = true;
        }else{
          this.validate = false
        }

        if(this.validate){
          return
        }
        client.post('api/request-a-call/'+this.backEnd,{
          country : this.country,
          phone : this.phone,
          name : this.name,
          options : this.options
        }).then(()=>{
          this.isSend = true;
        });
      }
    }
  }
</script>

<template>
  <div :class="{
            'opened' : modal,
            'closed' : !modal
        }" class="fixed top-0 left-0 right-0 z-999 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%)] max-h-full flex justify-center items-center"
       id="request-call" >
    <div class="absolute top-0 left-0 right-0 z-999 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%)] max-h-full" @click="closeModal">

    </div>
    <div class="relative w-full max-w-2xl max-h-full" >
      <!-- Modal content -->
      <div class="w-full relative bg-white rounded-lg shadow" v-if="!isSend">
        <!-- Modal header -->
        <div class="flex items-start justify-between p-4 relative z-10">
          <button type="button" class="text-gray-400 text-sm w-8 h-8 ml-auto inline-flex justify-center items-center" data-modal-hide="defaultModal" @click="closeModal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>
        </div>
        <div class="flex lg:items-start xs:text-center justify-center w-full">
          <h2>{{ $t('requestACallPopUp.header') }}</h2>
        </div>
        <!-- Modal body -->
        <div class="w-full max-lg:p-5 lg:pl-6 lg:pt-6 lg:pb-6 space-y-6 lg:flex lg:justify-between lg:items-center">
          <div class="lg:w-6/12 xs:w-full">
            <div class="mb-6 2xlg:w-12/12 xs:w-12/12 group-input">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
                {{ $t('requestACallPopUp.name') }}
              </label>
              <input :placeholder="$t('requestACallPopUp.name')" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" type="text" id="name" v-model="name">
              <!--            <p class="text-red-500 text-xs italic">Please choose a password.</p>-->
            </div>
            <div class="mb-6 2xlg:w-12/12 xs:w-12/12 ">
              <label class="group-label block text-gray-700 text-sm font-bold mb-2">
                {{ $t('requestACallPopUp.phone') }}
              </label>
              <vue-tel-input
                  autoformat
                  mode="national"
                  :disabledFormatting="false"
                  :inputOptions="{placeholder:$t('requestACallPopUp.phone')}"
                  @input="changeNumber"
                  v-on:country-changed="countryChanged"
              >
              </vue-tel-input>
              <!--            <p class="text-red-500 text-xs italic">Please choose a password.</p>-->
            </div>
            <div>
              <button class="btn btn-blue btn-font w-full" @click="create">{{ $t('requestACallPopUp.btn') }}</button>
            </div>
            <div v-if="validate" class=" p-4 mb-4 mt-4 text-sm text-red-800 rounded-lg bg-red-50">
              Enter all fields
            </div>
          </div>
          <div class="car w-6/12 xs:hidden lg:flex">

          </div>
        </div>

      </div>
      <div class="relative success-request w-full relative bg-white rounded-lg shadow" v-else>
        <div class="cross flex items-start justify-between p-4  rounded-t dark:border-gray-600">
          <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal" @click="closeModal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>
        </div>

        <div class="text-congrats flex justify-center items-center h-full" style="flex-direction: column">
          <div class="sent flex justify-center items-center mb-5">
            Request has been sent!
          </div>
          <div class="waiting flex justify-center items-center">
            <div class="flex mr-2">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_621_3372)">
                  <path d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 20.5C14.1217 20.5 16.1566 19.6571 17.6569 18.1569C19.1571 16.6566 20 14.6217 20 12.5C20 10.3783 19.1571 8.34344 17.6569 6.84315C16.1566 5.34285 14.1217 4.5 12 4.5C9.87827 4.5 7.84344 5.34285 6.34315 6.84315C4.84285 8.34344 4 10.3783 4 12.5C4 14.6217 4.84285 16.6566 6.34315 18.1569C7.84344 19.6571 9.87827 20.5 12 20.5ZM11.003 16.5L6.76 12.257L8.174 10.843L11.003 13.672L16.659 8.015L18.074 9.429L11.003 16.5Z" fill="#5D9C59"/>
                </g>
                <defs>
                  <clipPath id="clip0_621_3372">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>
              Wait for a call from our dealer
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.car{
  background-image: url("../assets/modal/car.png");
  background-repeat: no-repeat;
  height: 100%;
  background-position: right bottom;
  bottom: 0;
  z-index: 1;
}
h2{
  color: #000;
  /* H2 */
  font-family: TBCContractica;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
}
#request-call{
  position: fixed;
  z-index: 9999;
  background: rgba(0,0,0,0.5);
}
#request-call > div{
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
}
#request-call.opened{
  animation-duration: 0.5s;
  animation-name: open;
}
#request-call.closed{
  animation-duration: 0.5s;
  animation-name: close;
}
@media all and (min-width: 1000px) {
  #request-call > div{
    min-width: 880px;
  }
}
@keyframes open {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
@keyframes close {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}
.sent{
  color: #000;
  font-family: TBCContractica;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
}
.waiting{
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}
.success-request{
  min-height: 550px;
}
.success-request > .text-congrats{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.cross {
  z-index: 9999;
  position: relative;
}
</style>
