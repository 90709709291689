<script>
export default {}
</script>

<template>
  <div class="services-title mx-auto xs:px-5" id="services-title">
    <div class="services-title-header mb:mb-10 mb:mt-24 xs:mt-5 xs:mb-5">{{ $t('services.header') }}</div>
    <div class="grid 2xlg:grid-cols-3 mb:grid-cols-2 xs:grid-cols-1 gap-4 block-info">
      <div
          class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">

          <img :src="require('../assets/services/search-2-line.svg')" alt="">
        </div>
        <div class="title-font">{{ $t('services.title1') }}</div>
      </div>
      <div
          class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">

          <img :src="require('../assets/services/map-pin-5-line.svg')" alt="">
        </div>
        <div class="title-font">{{ $t('services.title2') }}</div>
      </div>
      <div
          class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">

          <img :src="require('../assets/services/ship-line.svg')" alt="">
        </div>
        <div class="title-font">{{ $t('services.title3') }}</div>
      </div>
      <div
          class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">

          <img :src="require('../assets/services/article-line.svg')" alt="">
        </div>
        <div class="title-font">{{ $t('services.title4') }}</div>
      </div>
      <div
          class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">

          <img :src="require('../assets/services/passport-line.svg')" alt="">
        </div>
        <div class="title-font">{{ $t('services.title5') }}</div>
      </div>
      <div class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">
          <img :src="require('../assets/services/building.svg')" alt=""></div>
        <div class="title-font">{{ $t('services.title6') }}</div>
      </div>
      <div class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">
          <img :src="require('../assets/services/people.svg')" alt=""></div>
        <div class="title-font">{{ $t('services.title7') }}</div>
      </div>
      <div class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">
          <img :src="require('../assets/services/hand.svg')" alt=""></div>
        <div class="title-font">{{ $t('services.title8') }}</div>
      </div>
      <div class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">
          <img :src="require('../assets/services/search-loop.svg')" alt="">
        </div>
        <div class="title-font">{{ $t('services.title9') }}</div>
      </div>
      <div class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">
          <img :src="require('../assets/services/shield.svg')" alt=""></div>
        <div class="title-font">{{ $t('services.title10') }}</div>
      </div>
      <div class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">
          <img :src="require('../assets/services/circle.svg')" alt=""></div>
        <div class="title-font">{{ $t('services.title11') }}</div>
      </div>
      <div class="px-10 xs:h-[64px] mb:h-[120px] flex justify-start items-center 2xlg:mr-5 2xlg:w-1/3 mb:w-2/4 xs:w-full">
        <div class="mr-[10px]">
          <img :src="require('../assets/services/circle.svg')" alt=""></div>
        <div class="title-font">{{ $t('services.title12') }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
