import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router/main";
import './css/index.css'
import ge from "@/locales/ge/ge.json";
import en from "@/locales/en/en.json";
import { createI18n } from 'vue-i18n'
import VueEasyLightbox from "vue-easy-lightbox";
import vSelect from "vue-select";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { createMetaManager } from 'vue-meta'

const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: window.localStorage?.locale ?? 'ge', // set locale
    fallbackLocale: window.localStorage?.locale ?? 'ge', // set fallback locale
    messages : {
        ge : ge,
        en : en
    }, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})
const app = createApp(App)
const requireComponent = require.context('./components', true, /\.vue$/);
requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    const componentName = fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '');

    app.component(componentName, componentConfig.default || componentConfig);
});
app.use(VueEasyLightbox)
app.use(createMetaManager(), {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
})
app.use(i18n)
app.use(router)
app.use(VueSweetalert2)
app.component('v-drop-down',vSelect)
app.directive('click-outside', {
    // Устанавливаем директиву
    mounted(el, binding) {
        const clickHandler = (e) => {
            if (!(el === e.target || el.contains(e.target))) {
                binding.value(); // Вызываем переданную функцию
            }
        };

        document.addEventListener('click', clickHandler);

        el._clickOutsideHandler = clickHandler;
    },
    unmounted(el) {
        document.removeEventListener('click', el._clickOutsideHandler);
        delete el._clickOutsideHandler;
    },
});
app.mount('#app');
