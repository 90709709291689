<script >
import accordion from "@/components/small-components/accordion.vue";
import emitter from "@/eventBus/mitt";
 export default {
   name : "faq-component",
   components:{
     accordion
   },
   mounted() {
     emitter.on('change-lang', () => {
       this.faqs = [
         {
           name : this.$t('faq.1Header'),
           text : this.$t('faq.1Text')
         },
         {
           name : this.$t('faq.2Header'),
           text : this.$t('faq.2Text')
         },
         {
           name : this.$t('faq.3Header'),
           text : this.$t('faq.3Text')
         },
         {
           name : this.$t('faq.4Header'),
           text : this.$t('faq.4Text')
         },
         {
           name : this.$t('faq.5Header'),
           text : this.$t('faq.5Text')
         },
         {
           name : this.$t('faq.6Header'),
           text : this.$t('faq.6Text')
         },
         {
           name : this.$t('faq.7Header'),
           text : this.$t('faq.7Text')
         },
         {
           name : this.$t('faq.8Header'),
           text : this.$t('faq.8Text')
         },
         {
           name : this.$t('faq.9Header'),
           text : this.$t('faq.9Text')
         },

         {
           name : this.$t('faq.10Header'),
           text : this.$t('faq.10Text')
         },
         {
           name : this.$t('faq.11Header'),
           text : this.$t('faq.11Text')
         },
         {
           name : this.$t('faq.12Header'),
           text : this.$t('faq.12Text')
         },
         {
           name : this.$t('faq.13Header'),
           text : this.$t('faq.13Text')
         },
         {
           name : this.$t('faq.14Header'),
           text : this.$t('faq.14Text')
         }
       ]
     })
   },
   data(){
     return {
       faqs: [
         {
           name : this.$t('faq.1Header'),
           text : this.$t('faq.1Text')
         },
         {
           name : this.$t('faq.2Header'),
           text : this.$t('faq.2Text')
         },
         {
           name : this.$t('faq.3Header'),
           text : this.$t('faq.3Text')
         },
         {
           name : this.$t('faq.4Header'),
           text : this.$t('faq.4Text')
         },
         {
           name : this.$t('faq.5Header'),
           text : this.$t('faq.5Text')
         },
         {
           name : this.$t('faq.6Header'),
           text : this.$t('faq.6Text')
         },
         {
           name : this.$t('faq.7Header'),
           text : this.$t('faq.7Text')
         },
         {
           name : this.$t('faq.8Header'),
           text : this.$t('faq.8Text')
         },
         {
           name : this.$t('faq.9Header'),
           text : this.$t('faq.9Text')
         },

         {
           name : this.$t('faq.10Header'),
           text : this.$t('faq.10Text')
         },
         {
           name : this.$t('faq.11Header'),
           text : this.$t('faq.11Text')
         },
         {
           name : this.$t('faq.12Header'),
           text : this.$t('faq.12Text')
         },
         {
           name : this.$t('faq.13Header'),
           text : this.$t('faq.13Text')
         },
         {
           name : this.$t('faq.14Header'),
           text : this.$t('faq.14Text')
         }
       ]
     }
   }
 }
</script>

<template>
  <div class="faq w-full mb:my-20 xs:my-5 xs:p-5" id="faq">
    <div class="faq-wrapper ml-auto mr-auto">
      <div class="faq-header mb-10">
      </div>

      <div>
        <accordion
            v-for="(faq,index) in faqs"
            :key="index"
            :index="index"
            :title="faq.name"
            :info="faq.text"
        />
      </div>

    </div>
  </div>
</template>

<style scoped>
@media screen and (min-width: 1023px) {
  .faq-header{
    color: #000;
    font-family: TBCContracticaCaps;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 49px;
  }
}
@media screen and (max-width: 1023px) {
  .faq-header {
    color: #000;
    font-family: TBCContracticaCaps;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 127.273% */
  }
}

.faq-wrapper{
  max-width: 1060px;
}
</style>
