<script>
export default {
  props: {
    name: {
      required: true,
      type: String
    },
    returnKey: {
      required: true,
      type: String
    },
    preSelected: {
      required: true
    },
    type: {
      required: true,
      type: String
    },
    options: {
      type: Object || Array
    },
    min: {
      type: String
    },
    max: {
      type: String
    }
  },
  watch: {
    search() {
      if (this.type === 'list') {
        this.listData = this.options.filter((value) => {
          return value.toLowerCase().includes(this.search.toLowerCase());
        })
      }
      if (this.type === 'key-list') {
        this.listData = this.filterByKeyword(this.search, this.options)
      }
    },
    minPrice() {
      this.$emit('select-price',{
        min : this.minPrice,
        max : this.maxPrice,
      })
    },
    maxPrice(){
      this.$emit('select-price',{
        min : this.minPrice,
        max : this.maxPrice,
      })
    },
    minValue() {
      this.$emit('select-period',{
        min : this.minValue,
        max : this.maxValue,
      })
    },
    maxValue(){
      this.$emit('select-period',{
        min : this.minValue,
        max : this.maxValue,
      })
    },
    selectedFrom() {
      this.$emit('select-years',{
        from : this.selectedFrom,
        to : this.selectedTo,
      })
    },
    selectedTo(){
      this.$emit('select-years',{
        from : this.selectedFrom,
        to : this.selectedTo,
      })
    },

    selected() {
      let tmp = {};
      tmp[this.returnKey] = this.selected;
      this.$emit('select',tmp)
    }
  },
  data() {
    return {
      key: 0,
      selected: [],
      listData: [],
      show: false,
      search: "",
      minPrice: "",
      maxPrice: "",
      minValue: "",
      maxValue: "",
      selectedFrom: "",
      selectedTo: "",
      yearsFromList: [],
      yearsToList: [],
    }
  },
  async mounted() {
    if (this.type === 'key-list') {
      if(typeof this.preSelected === "string"){
        this.selected.push(this.preSelected)
      }else{
        this.selected = this.preSelected ?? []
      }
      this.listData = this.filterEmptyArrays(this.options)
      this.key = ++this.key;
    }
    if(this.type === 'price') {
      if(this.preSelected.length > 0){
        this.minPrice = this.preSelected[0] ?? 0;
        this.maxPrice = this.preSelected[1] ?? 1;
      }else{
        this.minPrice = this.min;
        this.maxPrice = this.max;
      }

    }
    if(this.type === 'two-number') {
      if(this.preSelected.length > 0){
        this.minValue = this.preSelected[0] ?? 0;
        this.maxValue = this.preSelected[1] ?? 1;
      }else{
        this.minValue = this.min;
        this.maxValue = this.max;
      }

    }
    if(this.type === 'years') {
      if(this.preSelected.length > 0){
        this.selectedFrom = this.preSelected[0];
        this.selectedTo = this.preSelected[1];
      }else{
        this.selectedFrom = this.min;
        this.selectedTo = this.max;
      }

      this.yearsFromList = this.generateFrom(this.min, this.max);
      this.yearsToList = this.generateTo(this.min, this.max);
    }

    if(this.type === 'list') {
      if(typeof this.preSelected === "string"){
        this.selected.push(this.preSelected)
      }else{
        this.selected = this.preSelected ?? []
      }
      console.log(this.name,'this.selected',this.selected);
      this.listData = this.options
    }
  },
  methods: {
    generateFrom(min, max) {
      const sequence = [];
      for (let i = min; i <= max; i++) {
        sequence.push(i);
      }
      return sequence;
    },
    generateTo(min, max) {
      const sequence = [];
      for (let i = max; i >= min; i--) {
        sequence.push(i);
      }
      return sequence;
    },
    filterByKeyword(keyword, data) {
      const filteredData = {};
      for (const key in data) {

        const values = data[key].filter(value => value.toLowerCase().includes(keyword.toLowerCase()));
        if (values.length > 0) {
          filteredData[key] = values;
        }
      }
      return filteredData;
    },
    filterEmptyArrays(obj) {
      let result = {};
      for (var key in obj) {
        if (Array.isArray(obj[key]) && obj[key].length === 0) {
          continue;
        }
        if (typeof obj[key] === 'object') {
          result[key] = this.filterEmptyArrays(obj[key]);
        } else {
          result[key] = obj[key];
        }
      }
      return result;
    },
    capitalCase(inputString) {
      let words = inputString.split(' ');
      let outputWords = words.map(word => word.charAt(0) + word.slice(1).toLowerCase());
      return outputWords.join(' ');
    },
  }
}
</script>

<template>
  <div class="relative dropdown" v-click-outside="() => show = false" :key="key">
    <div class="flex justify-between items-center cursor-pointer p-5" @click="show = !show">
      <div class="w-11/12 overflow-hidden max-h-[25px]" v-if="type==='list' || type === 'key-list'">
        <span v-if="selected.length === 0">
        {{ name }}
        </span>
        <span v-else>
          {{ capitalCase(selected.join(', ')) }}
        </span>
      </div>
      <div v-else-if="type==='price'">
        <span v-if="maxPrice === '' || minPrice === ''">
        {{ name }}
        </span>
        <span v-else>
          ${{ minPrice }} - ${{ maxPrice }}
        </span>
      </div>
      <div v-else-if="type==='two-number'">
        <span v-if="maxValue === '' || minValue === ''">
        {{ name }}
        </span>
        <span v-else>
          {{ minValue }} - {{ maxValue }}
        </span>
      </div>
      <div v-else-if="type==='years'">
        <span v-if="selectedFrom === '' || selectedTo === ''">
        {{ name }}
        </span>
        <span v-else>
          {{ selectedFrom }} - {{ selectedTo }}
        </span>
      </div>
      <div>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1526_7429)">
            <path
                d="M10.0006 10.9766L14.1256 6.85156L15.3039 8.0299L10.0006 13.3332L4.69727 8.0299L5.8756 6.85156L10.0006 10.9766Z"
                fill="black"/>
          </g>
          <defs>
            <clipPath id="clip0_1526_7429">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <Transition name="fade">
      <div class="absolute z-[999] w-full mt-1" v-show="show">
        <div class="list-wrapper p-2" v-if="type === 'price'">
          <div class="max-h-[300px] overflow-y-auto flex justify-between items-center">
            <div class="w-5/12">
              <input type="number"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                     placeholder="Min Price" v-model="minPrice">
            </div>
            <div class="w-2/12 text-center">
              -
            </div>
            <div class="w-5/12">
              <input type="number"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                     placeholder="Max Price" v-model="maxPrice">
            </div>
          </div>
        </div>
        <div class="list-wrapper p-2" v-if="type === 'two-number'">
          <div class="max-h-[300px] overflow-y-auto flex justify-between items-center">
            <div class="w-5/12">
              <input type="number"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                     placeholder="Min" v-model="minValue">
            </div>
            <div class="w-2/12 text-center">
              -
            </div>
            <div class="w-5/12">
              <input type="number"
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                     placeholder="Max" v-model="maxValue">
            </div>
          </div>
        </div>
        <div class="list-wrapper p-5" v-if="type === 'list'">
          <div class="mb-3">
            <input type="text" id="first_name"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                   placeholder="Search" v-model="search">
          </div>
          <div class="max-h-[300px] overflow-y-auto">
            <div class="flex items-center" v-for="(option, key) in listData" :key="key">
              <div class="form-checkbox mb-2 option" :class="{
            selected : selected.includes(option)
          }">
                <input type="checkbox" class="mr-5" :id="option" :value="option" v-model="selected">
                <label class="option capitalize" :for="option">{{ capitalCase(option.replaceAll('-', ' ')) }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="list-wrapper p-5" v-if="type === 'key-list'">
          <div class="mb-3">
            <input type="text" id="first_name"
                   class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                   placeholder="Search" v-model="search">
          </div>
          <div class="max-h-[300px] overflow-y-auto">
            <div class="items-center" v-for="(models,maker) in listData" :key="maker">
              <div class="model-header [:not(:last-child)]:mt-5 mb-3">{{ maker }}</div>
              <div class="form-checkbox option mb-2" v-for="(model,key) in models" :key="key">
                <input type="checkbox" class="mr-5" :id="model" :value="model" v-model="selected">
                <label class="option capitalize" :class="{
            selected : selected.includes(model)
          }" :for="model">{{ capitalCase(model.replaceAll('-', ' ')) }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="list-wrapper" v-if="type === 'years'">
          <div class="wrapper-years-list flex justify-around pt-2 overflow-hidden">
            <div class="w-5/12 overflow-x-hidden">
              <div class="year-option mb-2 pt-1 pb-1 pl-2 pr-2 cursor-pointer" :class="{
                selected : selectedFrom === year
              }" @click="selectedFrom = year" v-for="(year,key) in yearsFromList" :key="key">{{ year }}</div>
            </div>
            <div class="w-5/12 overflow-x-hidden">
              <div class="year-option mb-2 pt-1 pb-1 pl-2 pr-2 cursor-pointer" :class="{
                selected : selectedTo === year
              }" @click="selectedTo = year" v-for="(year, key) in yearsToList" :key="key">{{ year }}</div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.wrapper-years-list{
  height: 400px;
}
.list-wrapper {
  background: white;
  box-shadow: 2px 8px 26px 2px rgba(0, 0, 0, 0.43);
  -webkit-box-shadow: 2px 8px 26px 2px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 2px 8px 26px 2px rgba(0, 0, 0, 0.43);
  border-radius: 4px;
}

.dropdown {
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background: #F4F4F6;
}

.selected {
  color: var(--blue, #256dff)
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  transition: opacity 0.2s ease;
  opacity: 0;
}

.form-checkbox input:checked + label:after {
  top: 5px;
}
</style>