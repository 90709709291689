<script>
import {client} from "@/axios/client";

export default {
  data(){
    return {
      article : {},
    }
  },
  beforeMount() {
    this.getArticle();
  },
  methods:{
    async getArticle(){
      const id = this.$route.params.id;
      const {data} = await client.get('api/articles/'+id);
      this.article = data;
    }
  }
}
</script>

<template>
  <div class="article page" id="article">
    <router-link class="back-blog blue-text flex justify-center items-center mt-5 mb-10" :to="{name : 'blog'}">
      <div>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M11.328 12.5002L16.278 17.4502L14.864 18.8642L8.5 12.5002L14.864 6.13623L16.278 7.55023L11.328 12.5002Z"
              fill="#256DFF"></path>
        </svg>
      </div>
      <div >დაბრუნება - უკან</div>
    </router-link>
    <div class="article-header flex justify-center max-w-[1200px] mx-auto">
      {{ article.header }}
    </div>
    <div class="max-w-[950px] mx-auto text-center my-10 flex justify-center items-center">
      <div class="mr-5"><span class="grey-text">თარიღი:</span> 15 July 2023</div>
      <div class="mr-5">
        <svg width="1" height="19" viewBox="0 0 1 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.5" width="1" height="18" fill="black"></rect>
        </svg>
      </div>
      <div >Reading Time: {{ article.reading_time }} minutes</div>
    </div>
    <div class="text-left max-w-[950px] mx-auto article-body xs:px-2 2xlg:px-0" v-html="article.body">

    </div>
  </div>
</template>

<style scoped>
.article-body, .article-body>h1 {
  color: #000;
  font-family: TBCContractica;
  font-style: normal;
}
.article-body {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}
.article-header{
  color: #000;
  text-align: center;
  font-family: TBCContracticaCaps;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
}
</style>
