<template>
    <article class="question mb-10 cursor-pointer" @click="expanded = !expanded" v-if="lang === 'ge' || index !== 6">
      <header >
        <h4 class="question-title">
          {{ title }}
        </h4>
        <button class="btn" >
          <svg v-show="!expanded" width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_621_7497)">
              <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" fill="black"></path>
            </g>
            <defs>
              <clipPath id="clip0_621_7497">
                <rect width="24" height="24" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
          <svg v-show="expanded" width="24" height="24" viewBox="0 0 24 24" fill="none"
               xmlns="http://www.w3.org/2000/svg" style="display: none;">
            <g clip-path="url(#clip0_621_7506)">
              <path
                  d="M12 10.5859L16.2426 6.34323L17.6569 7.75744L13.4142 12.0001L17.6569 16.2427L16.2426 17.6569L12 13.4143L7.75736 17.6569L6.34315 16.2427L10.5858 12.0001L6.34315 7.75744L7.75736 6.34323L12 10.5859Z"
                  fill="black"></path>
            </g>
            <defs>
              <clipPath id="clip0_621_7506">
                <rect width="24" height="24" fill="white"></rect>
              </clipPath>
            </defs>
          </svg>
        </button>
      </header>
      <div :style="contentStyle" class="content">
        <p :style="infoStyle" class="info">{{ info }}</p>
      </div>
      <hr>
    </article>
</template>

<script>
import {ref, computed} from "vue";
import emitter from "@/eventBus/mitt";

export default {
  name: "accordion-component",
  props: {
    title: String,
    info: String,
    index: Number
  },
  data(){
    return {
      lang : this.$i18n.locale
    }
  },
  mounted() {
    emitter.on('change-lang', () => {
      this.lang = this.$i18n.locale
    })

  },
  setup() {
    const expanded = ref(false);
    const contentStyle = computed(() => {
      return {"max-height": expanded.value ? "200px" : 0};
    });

    const infoStyle = computed(() => {
      return {opacity: expanded.value ? 1 : 0};
    });

    return {expanded, contentStyle, infoStyle};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-purple: #4b145b;
  --clr-red-special: #b4345c;
  --clr-grey-special: #eae6eb;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 920px;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: var(--clr-purple);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1rem;
  }

  body {
    font-size: 1rem;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/*  global classes */

main {
  min-height: 100vh;
  /* using flex because of better browser support */
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 90vw;
  margin: 5rem auto;
  background: var(--clr-white);
  border-radius: var(--radius);
  padding: 2.5rem 2rem;
  max-width: var(--fixed-width);
  display: grid;
  gap: 1rem 2rem;
}

.container h3 {
  line-height: 1.2;
  font-weight: 500;
}

@media screen and (min-width: 992px) {
  .container {
    display: grid;
    grid-template-columns: 250px 1fr;
  }
}

.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

.question {
  border: 2px solid var(--clr-grey-special);
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
}

.question h4 {
  text-transform: none;
  line-height: 1.5;
}

.question p {
  color: var(--clr-grey-3);
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.question header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question header h4 {
  margin-bottom: 0;
}

.question-title {
  cursor: pointer;
  color: #000;
  font-family: TBCContracticaCaps;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.content {
  color: #000;
  font-family: TBCContractica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.info {
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

</style>
