<script>
import LangComponent from "@/components/lang.vue";

export default {
  name: "footer-component",
  components: {LangComponent},
  data() {
    return {}
  }
}
</script>

<template>


  <footer class="footer ml-auto mr-auto mb:px-10 mb:py-10 xs:py-5 xs:px-2">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
      <div class="xs md:flex md:justify-between">
        <div class="mb:flex mb:justify-between grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
          <div class="xs:mb-5">
            <div class="mb-6 md:mb-0">
              <router-link :to="{name:'main'}">
                <img
                    :src="require('../assets/white-logo.png')" width="219" class="h-8 mr-3" alt="4dealer Logo"
                    style="height: 45px; object-fit: contain;">
              </router-link>
            </div>
            <ul class="text-gray-500 dark:text-gray-400 font-medium links">
              <li class="mb-4"><span class="grey-text">კონტაქტი</span><br
              ><a class="white-text" href="tel:0322197999">(032) 2 19 79 99</a>
              </li>
              <li><a href="/privacy-policy" class="mr-5 grey-text">Privacy
                Policy</a><a href="/term-of-service" class="grey-text">Terms of Service</a></li>
            </ul>
          </div>
          <div class="xs:flex 2xlg:mr-64">
            <div class="link-header mb:mr-32 xs:mr-16">
              <ul class="font-medium links">
                <li class="mb-4">
                  <router-link :to="{name : 'blog'}">
                    {{ $t('footer.blog') }}
                  </router-link>
                </li>
                <li class="mb-4">
                  <router-link :to="{name : 'services'}">
                    {{ $t('footer.services') }}
                  </router-link>
                </li>
                <li class="mb-4">
                  <router-link :to="{name : 'about-us'}">
                    {{ $t('footer.aboutUs') }}
                  </router-link>
                </li>
                <li class="mb-4">
                  <router-link :to="{name : 'catalogue'}">
                    {{ $t('footer.catalogue') }}
                  </router-link>
                </li>
                <li class="mb-4">
                  <router-link :to="{name : 'reviews'}">
                    {{ $t('footer.reviews') }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="link-header">
              <ul class="font-medium links">
                <li class="mb-4">
                  <router-link :to="{name : 'for-dealers'}">
                    {{ $t('footer.forDealers') }}
                  </router-link>
                </li>
                <li class="mb-4">
                  <router-link :to="{name : 'contact-us'}">
                    {{ $t('footer.contactUs') }}
                  </router-link>
                </li>
                <li class="mb-4">
                  <router-link :to="{name : 'calculator'}">
                    {{ $t('footer.calculator') }}
                  </router-link>
                </li>
                <li class="mb-4">
                  <router-link :to="{name : 'faq'}">
                    {{ $t('footer.faq') }}
                  </router-link>
                </li>
                <li class="mb-4">
                  <router-link :to="{name : 'payment'}">
                    {{ $t('footer.payment') }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <ul class="text-gray-500 dark:text-gray-400 font-medium links-icons">
              <li class="flex mb-4 mb:justify-end xs:justify-center"><a
                  href="https://www.facebook.com/4dealer" target="_blank" class="mr-4">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M12 2C6.477 2 2 6.477 2 12C2 16.991 5.657 21.128 10.438 21.879V14.89H7.898V12H10.438V9.797C10.438 7.291 11.93 5.907 14.215 5.907C15.309 5.907 16.453 6.102 16.453 6.102V8.562H15.193C13.95 8.562 13.563 9.333 13.563 10.124V12H16.336L15.893 14.89H13.563V21.879C18.343 21.129 22 16.99 22 12C22 6.477 17.523 2 12 2Z"
                      fill="white"></path>
                </svg>
              </a><a href="https://www.linkedin.com/company/4dealer4/" target="_blank" class="mr-4">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M6.94043 5.00002C6.94017 5.53046 6.7292 6.03906 6.35394 6.41394C5.97868 6.78883 5.46986 6.99929 4.93943 6.99902C4.409 6.99876 3.90039 6.78779 3.52551 6.41253C3.15062 6.03727 2.94016 5.52846 2.94043 4.99802C2.9407 4.46759 3.15166 3.95899 3.52692 3.5841C3.90218 3.20922 4.411 2.99876 4.94143 2.99902C5.47186 2.99929 5.98047 3.21026 6.35535 3.58552C6.73024 3.96078 6.9407 4.46959 6.94043 5.00002ZM7.00043 8.48002H3.00043V21H7.00043V8.48002ZM13.3204 8.48002H9.34043V21H13.2804V14.43C13.2804 10.77 18.0504 10.43 18.0504 14.43V21H22.0004V13.07C22.0004 6.90002 14.9404 7.13002 13.2804 10.16L13.3204 8.48002Z"
                      fill="white"></path>
                </svg>
              </a><a href="https://www.instagram.com/4dealer.ge/" target="_blank" class="mr-4">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M12 2C14.717 2 15.056 2.01 16.122 2.06C17.187 2.11 17.912 2.277 18.55 2.525C19.21 2.779 19.766 3.123 20.322 3.678C20.8305 4.1779 21.224 4.78259 21.475 5.45C21.722 6.087 21.89 6.813 21.94 7.878C21.987 8.944 22 9.283 22 12C22 14.717 21.99 15.056 21.94 16.122C21.89 17.187 21.722 17.912 21.475 18.55C21.2247 19.2178 20.8311 19.8226 20.322 20.322C19.822 20.8303 19.2173 21.2238 18.55 21.475C17.913 21.722 17.187 21.89 16.122 21.94C15.056 21.987 14.717 22 12 22C9.283 22 8.944 21.99 7.878 21.94C6.813 21.89 6.088 21.722 5.45 21.475C4.78233 21.2245 4.17753 20.8309 3.678 20.322C3.16941 19.8222 2.77593 19.2175 2.525 18.55C2.277 17.913 2.11 17.187 2.06 16.122C2.013 15.056 2 14.717 2 12C2 9.283 2.01 8.944 2.06 7.878C2.11 6.812 2.277 6.088 2.525 5.45C2.77524 4.78218 3.1688 4.17732 3.678 3.678C4.17767 3.16923 4.78243 2.77573 5.45 2.525C6.088 2.277 6.812 2.11 7.878 2.06C8.944 2.013 9.283 2 12 2ZM12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7ZM18.5 6.75C18.5 6.41848 18.3683 6.10054 18.1339 5.86612C17.8995 5.6317 17.5815 5.5 17.25 5.5C16.9185 5.5 16.6005 5.6317 16.3661 5.86612C16.1317 6.10054 16 6.41848 16 6.75C16 7.08152 16.1317 7.39946 16.3661 7.63388C16.6005 7.8683 16.9185 8 17.25 8C17.5815 8 17.8995 7.8683 18.1339 7.63388C18.3683 7.39946 18.5 7.08152 18.5 6.75ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9Z"
                      fill="white"></path>
                </svg>
              </a><a href="https://www.tiktok.com/@4dealer?lang=en" target="_blank">
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision"
                     text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd"
                     clip-rule="evenodd" viewBox="0 0 512 512">
                  <path fill="#fff"
                        d="M256 0c141.384 0 256 114.616 256 256 0 141.384-114.616 256-256 256C114.616 512 0 397.384 0 256 0 114.616 114.616 0 256 0zm82.937 174.75c-14.614-9.524-25.152-24.771-28.445-42.535a65.235 65.235 0 01-1.102-11.831h-46.631l-.075 186.877c-.783 20.928-18.009 37.724-39.119 37.724a38.912 38.912 0 01-18.186-4.503c-12.478-6.565-21.016-19.641-21.016-34.691 0-21.614 17.588-39.201 39.194-39.201 4.035 0 7.907.667 11.566 1.809v-47.603c-3.789-.517-7.64-.836-11.566-.836-47.323-.001-85.824 38.499-85.824 85.831 0 29.037 14.504 54.733 36.643 70.272 13.94 9.791 30.901 15.553 49.189 15.553 47.324 0 85.825-38.5 85.825-85.825v-94.765c18.288 13.124 40.698 20.859 64.877 20.859v-46.631c-13.022 0-25.152-3.87-35.33-10.504z"
                  ></path>
                </svg>
              </a></li>
              <li class="grey-text mb:text-right xs:text-center"> 4Dealer.co</li>
              <li class="mb-5 grey-text mb:text-right xs:text-center"> ©All rights reserved</li>
              <li class="mb-5 flex justify-end">
                <lang-component/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>

<style scoped>
.footer {
  background: #000;
}

.footer > div {
  max-width: 1600px;
}

.link-header {
  color: #d2d2d2;
  font-family: TBCContracticaCaps;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}
</style>
